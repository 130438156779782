import DataTable from "components/common/DataTable";
import { Data, PaginationModel } from "components/common/DataTable/types";
import React, { Dispatch, FC, SetStateAction } from "react";
import { Columns } from "./Controls/Columns";

export interface ProductAndServiceByRegionDataTableProps {
  data: Data;
  paginationModel: PaginationModel;
  setPaginationModel: Dispatch<SetStateAction<PaginationModel>>;
}

const ProductAndServiceByRegionDataTable: FC<ProductAndServiceByRegionDataTableProps> = ({ data, paginationModel, setPaginationModel }) => {

  return (
    <DataTable
      data={data}
      columns={Columns}
      isPending={false}
      onNoDataTitle='No Rates found'
      onNoDataSubTitle="Try adjusting your filters"
      dataTableName='product-and-services'
      checkboxSelection={false}
      isError={false}
      onNoData={null}
      paginationMode='server'
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
    />
  );
};

export default ProductAndServiceByRegionDataTable;