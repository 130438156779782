import React, { FC, useCallback, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import styled from 'styled-components';
import DataTable from 'components/common/DataTable';
import SearchInput from 'components/common/InputFields/SearchInput';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { closeModalAtom } from 'atoms/modalAtom';
import { Controller, useForm } from 'react-hook-form';
import variables from 'styles/variables';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { PaginationModel } from 'components/common/DataTable/types';
import useInvalidateData from 'hooks/useInvalidateData';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';
import DownloadAttachmentsIcon from 'components/common/DownloadAttachmentsIcon';
import { placeholderEmployeeNotes } from 'utils/helpers/getDBData';
import { formatISODate } from 'utils/helpers';
import { EmployeeNote } from 'types/dbSchema/serviceTeam';
import { ServiceTeamNoteType, Tag, tagCategories } from 'types/dbSchema/serviceTeamSettings';
import DragAndDropFileUpload from 'components/common/InputFields/DragAndDropFileUpload';
import { UploadedFile } from 'components/common/InputFields/DragAndDropFileUpload/types';
import Tooltip from 'components/common/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ServiceTeamNoteTypeLookupField from 'components/common/LookupInputField/ServiceTeamNoteTypeLookupField';
import TextInput from 'components/common/InputFields/TextInput';
import TextareaInput from 'components/common/InputFields/TextareaInput';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import { paginationModelDefault } from 'utils/helpers';

const EmployeeNotes = () => {
  const [searchValue, setSearchValue] = useState('');
  const [, setModalState] = useAtom(modalStateAtom);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>(paginationModelDefault);
  const [organisations] = useUserOrganisations();
  useInvalidateData('employee-notes');

  const { isError, data, isPending, isFetching, isLoading } = useQuery({
    queryKey: ['employee-notes', paginationModel],
    queryFn: () => placeholderEmployeeNotes,
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.employeeNotes,
  });

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'right',
      component: EmployeeNotesForm,
      props: {
        type: 'create',
      }
    }));
  }, [setModalState]);


  const columns: GridColDef[] = [
    {
      field: 'edit',
      headerName: '',
      width: 40,
      renderCell: (params) => (
        <Edit employeeNote={params.row} />
      ),
      sortable: false,
    },
    {
      field: 'download',
      headerName: '',
      width: 75,
      sortable: false,
      renderCell: (params) => (
        <DownloadAttachmentsIcon attachments={params.row.attachments} />
      ),
    },
    {
      field: 'subject',
      headerName: 'Subject',
      width: 200,
      renderCell: (params) => (
        <Typography variant='subtitle2' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {params.row.subject}
        </Typography>
      ),
    },
    {
      field: 'noteType',
      headerName: 'Note Type',
      width: 200,
      renderCell: (params) => (
        <Typography variant='subtitle2' color={variables.colors.text.secondary} fontWeight='500'>
          {(params.row.noteType as ServiceTeamNoteType).name}
        </Typography>
      ),
    },
    {
      field: 'note',
      headerName: 'Note',
      flex: 1,
      minWidth: 300,
      renderCell: (params) => (
        <Typography variant='body2' color={variables.colors.text.secondary} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {params.row.note}
        </Typography>
      ),
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      width: 150,
      renderCell: (params) => (
        <Typography variant='subtitle2' color={variables.colors.text.secondary} fontWeight='500'>
          {params.row.createdBy}
        </Typography>
      ),
    },
    {
      field: 'createdDate',
      headerName: 'Created Date',
      width: 150,
      renderCell: (params) => (
        <Typography variant='subtitle2' color={variables.colors.text.secondary} fontWeight='500'>
          {params.row.createdDate && formatISODate(params.row.createdDate)}
        </Typography>
      ),
    },
  ];

  return (
    <StyledBox>
      <SearchBox>
        <Stack>
          <Typography fontSize='18px' fontWeight='600'>
            Employee Notes
          </Typography>
        </Stack>
        <Stack flexDirection='row' gap='16px'>
          <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholderText='Search' />
          <Button variant='contained' sx={{ padding: '8px 16px' }} onClick={handleModalOpen}>
            <AddOutlinedIcon sx={{ marginRight: '8px' }} />
            Add
          </Button>
        </Stack>
      </SearchBox>
      <Box height='calc(100vh - 135px)'>
        <DataTable
          data={{ items: data!, totalCount: 1, pageNumber: paginationModel.page, pageSize: paginationModel.pageSize }}
          columns={columns}
          checkboxSelection={false}
          dataTableName='employee-notes'
          isPending={isFetching || isPending || isLoading}
          isError={isError}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          onNoDataTitle='No Employee Notes found'
          paginationMode='server'
          onNoData={handleModalOpen}
        />
      </Box>
    </StyledBox>
  );
};

export interface EmployeeNotesFormProps {
  type: 'create' | 'edit';
  employeeNote: EmployeeNote;
}

// Add/Edit form
const EmployeeNotesForm: FC<EmployeeNotesFormProps> = ({ type, employeeNote }) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const showError = useErrorMessage();
  const [currentFiles, setCurrentFiles] = useState<UploadedFile[]>([]);
  const validFileTypes = ['text/csv', 'application/pdf', 'image/jpeg', 'image/png', 'image/jpg', 'image/gif'];
  const maxTotalSize = 104857600;
  const maxFileSize = 104857600;
  const { control, handleSubmit, formState: { errors, isDirty } } = useForm<EmployeeNote>({
    mode: 'onChange',
    defaultValues: employeeNote && {
      ...employeeNote,
      noteType: (employeeNote.noteType as ServiceTeamNoteType).id,
    }
  });

  // Function to create a new Skill Qualification
  // Invalidates the query to fetch the latest data, and closes the modal
  const handleCreate = async (data: EmployeeNote) => {
    console.log('🚀 ~ handleCreate ~ data:', data);
    try {
      setInvalidateData((prev) => ({ ...prev, 'employee-notes': true }));
      closeModal();
    } catch (error) {
      showError((error as Error).message);
    }
  };

  // Function to update a Skill Qualification.
  // Invalidates the query to fetch the latest data, and closes the modal
  const handleEdit = async (data: EmployeeNote) => {
    console.log('🚀 ~ handleEdit ~ data:', data);
    try {
      setInvalidateData((prev) => ({ ...prev, 'employee-notes': true }));
      closeModal();
    } catch (error) {
      showError((error as Error).message);
    }
  };

  // Function to handle form submission
  const handleSubmitForm = async (data: any) => {
    if (type === 'create') {
      await handleCreate(data);
    }
    else {
      await handleEdit(data);
    }
  };

  return (
    <form style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }} onSubmit={handleSubmit(handleSubmitForm)}>
      <Stack height='fit-content' padding='32px' gap='24px' sx={{ height: '100%', overflowY: 'auto' }}>
        <Typography variant='h6' fontWeight='600'>
          {type === 'create' ? 'Add Skill Qualification' : 'Edit Skill Qualification'}
        </Typography>
        <Controller
          name="noteType"
          control={control}
          rules={{ required: 'Tag Name is required' }}
          render={({ field }) =>
            <ServiceTeamNoteTypeLookupField
              {...field}
              id='noteType'
              label='Note Type'
              validationError={errors.noteType ? true : false}
              errorText={errors.noteType?.message}
              isMandatory
              placeholder='Select'
            />
          }
        />
        <Controller
          name="subject"
          control={control}
          rules={{
            required: 'Leave Type is required',
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='subject'
              label='Subject'
              error={errors.subject ? true : false}
              errorText={errors?.subject?.message}
              isMandatory
            />
          }
        />
        <Controller
          name="note"
          control={control}
          rules={{
            required: 'Leave Type is required',
            maxLength: { value: 200, message: 'Note cannot exceed 200 characters' }
          }}
          render={({ field }) =>
            <TextareaInput
              {...field}
              id='note'
              label='Note'
              error={errors.note ? true : false}
              errorText={errors?.note?.message}
              isMandatory
              helperText='Max. 200 characters'
            />
          }
        />
        <Stack gap='8px'>
          <Stack flexDirection='row' gap='4px' alignItems='center'>
            <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary}>
              Documents
            </Typography>
            <Tooltip
              toolTipTitle='Supported documents'
              toolTipText={`You can upload multiple files, summing up to maximum ${(maxTotalSize / 1048576).toFixed(0)}MB. Files supported: ${validFileTypes.join(', ')}.`}
              toolTipTriggerComponent={
                <Box height='16px' width='16px' position='relative' minWidth='16px'>
                  <InfoOutlinedIcon sx={{ height: '100%', width: '100%' }} />
                </Box>
              }
            />
          </Stack>
          <DragAndDropFileUpload
            setCurrentFiles={setCurrentFiles}
            currentFiles={currentFiles}
            validFileTypes={validFileTypes}
            maxTotalSize={maxTotalSize}
            maxFileSize={maxFileSize}
            multiple
          />
        </Stack>
      </Stack>
      <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' padding='16px 32px' boxSizing='border-box' sx={{ backgroundColor: variables.colors.lightNeutral.subtle, borderRadius: '0 0 12px 12px' }} >
        <Button variant='outlined' color='primary' onClick={closeModal}>
          Cancel
        </Button>
        <Button type='submit' variant='contained' color='primary' disabled={!isDirty}>
          {type === 'create' ? 'Create' : 'Update'}
        </Button>
      </Stack>
    </form>
  );
};

// Renders Edit Icon for Grid column definitions - opens add/edit form in modal
const Edit = ({ employeeNote }: { employeeNote: EmployeeNote }) => {
  const [, setModalState] = useAtom(modalStateAtom);
  if (!employeeNote) return null;

  // Function to open/close the modal.
  // The filteredColorList is passed to the form to filter out the colors that are already in use
  // We also filter the color of the current row to be able to select it
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'right',
      component: EmployeeNotesForm,
      props: {
        type: 'edit',
        employeeNote,
      }
    }));
  }, [setModalState, employeeNote]);

  return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'pointer' }} onClick={handleModalOpen}>
      <EditOutlinedIcon sx={{ color: variables.colors.icon.standard }} />
    </Stack>
  );
};

const StyledBox = styled(Box)`
  height: 100%;
  width: 100%;
  background-color: white;
`;

const SearchBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px 24px 24px 24px;
  gap: 16px;
`;

export default EmployeeNotes;