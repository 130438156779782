import React from "react";
import { Typography } from "@mui/material";
import { userProfileStatuses } from "types/dbSchema/userProfiles";
import { isActiveStatusObjects } from "components/common/IsActiveDropdown/types";
import { IFilter } from "components/common/FilterWithDropdown/types";
import { statuses } from "types/dbSchema/participants";
import { serviceTimeslots } from "types/dbSchema/schedulingSupports";
import { ndisClaimsStatuses } from "types/dbSchema/ndisClaims";
import { invoiceBatchStatuses } from "types/dbSchema/invoiceBatches";
import { payfileStatuses } from "types/dbSchema/payfiles";
import { deliveredServiceRecordStatuses } from "types/dbSchema/deliveredServiceRecord";
import { priceGuideRegionOptions } from "types/dbSchema/productAndServices";
import { participantScheduleStatuses } from "types/dbSchema/participantSchedules";
import Status from "components/common/Status";

export const goalCommentTypeFilter = {
  field: 'GoalCommentType',
  name: 'Comment Type',
  options: [{
    value: 'generalComment',
    label: <Typography variant='body2'>General Comment</Typography>
  },
  {
    value: 'goalReview',
    label: <Typography variant='body2'>Goal Review</Typography>
  }]
};

export const goalTypeFilter = {
  field: 'GoalType',
  name: 'Goal Type',
  options: [
    {
      value: 'shortTerm',
      label: <Typography variant='body2'>Short Term</Typography>
    },
    {
      value: 'longTerm',
      label: <Typography variant='body2'>Long Term</Typography>
    }
  ]
};

export const goalStatusFilter = {
  field: 'Status',
  name: 'Status',
  options: [
    {
      value: 'notStarted',
      label: <Typography variant='body2'>Not Started</Typography>
    },
    {
      value: 'inProgress',
      label: <Typography variant='body2'>In Progress</Typography>
    },
    {
      value: 'completed',
      label: <Typography variant='body2'>Completed</Typography>
    }
  ]
};

export const userTypeFilters = {
  field: 'UserType',
  name: 'User Type',
  options: [
    {
      value: 'WebAppStatus',
      label: <Typography variant='body2'>Web App User</Typography>
    },
    {
      value: 'MobileAppStatus',
      label: <Typography variant='body2'>Mobile App User</Typography>
    }
  ]
};

export const userProfileStatusFilter = {
  field: 'Status',
  name: 'Status',
  options: userProfileStatuses.map((status) => {
    return {
      value: status.value,
      label: (
        <Status
          backgroundColor={status.backgroundColor}
          fontColor={status.fontColor}
          label={status.label}
        />
      )
    };
  })
};

export const participantProfileStatusFilter = {
  field: 'Status',
  name: 'Status',
  options: statuses.map((status) => {
    return {
      value: status.value,
      label: (
        <Status
          backgroundColor={status.backgroundColor}
          fontColor={status.fontColor}
          label={status.label}
        />
      )
    };
  })
};

export const participantScheduleStatusFilter = {
  field: 'Status',
  name: 'Status',
  options: participantScheduleStatuses.map((status) => {
    return {
      value: status.value,
      label: (
        <Status
          backgroundColor={status.backgroundColor}
          fontColor={status.fontColor}
          label={status.label}
        />
      )
    };
  })
};

export const serviceTimeslotsFilter = {
  field: 'DeliveredDuring',
  name: 'Delivered During',
  options: serviceTimeslots.map((status) => {
    return {
      value: status,
      label: (
        <Typography variant='body2'>
          {status}
        </Typography>
      )
    };
  })
};

export const pricingRegionFilter = {
  field: 'PricingRegion',
  name: 'Pricing Region',
  options: priceGuideRegionOptions.map((region) => ({
    label: (
      <Typography variant='body2'>
        {region.label}
      </Typography>
    ),
    value: region.value,
  })),
};

export const isActiveFilter: IFilter = {
  field: 'IsActive',
  name: 'Status',
  options: isActiveStatusObjects.map((isActive) => ({
    label: (
      <Status
        backgroundColor={isActive.backgroundColor}
        fontColor={isActive.fontColor}
        label={isActive.label}
      />
    ),
    value: isActive.isActive.toString()
  })),
};

export const ndisClaimsStatusFilter = {
  field: 'Status',
  name: 'Status',
  options: ndisClaimsStatuses.map((status) => {
    return {
      value: status.value,
      label: (
        <Status
          backgroundColor={status.backgroundColor}
          fontColor={status.fontColor}
          label={status.label}
        />
      )
    };
  })
};

export const payfileStatusFilter = {
  field: 'Status',
  name: 'Status',
  options: payfileStatuses.map((status) => {
    return {
      value: status.value,
      label: (
        <Status
          backgroundColor={status.backgroundColor}
          fontColor={status.fontColor}
          label={status.label}
        />
      )
    };
  })
};

export const invoiceBatchStatusFilter = {
  field: 'Status',
  name: 'Status',
  options: invoiceBatchStatuses.map((status) => {
    return {
      value: status.value,
      label: (
        <Status
          backgroundColor={status.backgroundColor}
          fontColor={status.fontColor}
          label={status.label}
        />
      )
    };
  })
};

export const deliveredServiceStatusFilter = {
  field: 'Status',
  name: 'Status',
  options: deliveredServiceRecordStatuses.map((status) => {
    return {
      value: status.value,
      label: (
        <Status
          backgroundColor={status.backgroundColor}
          fontColor={status.fontColor}
          label={status.label}
        />
      )
    };
  })
};

export const quoteFilter = {
  field: 'Quote',
  name: 'Quote',
  options: [
    {
      value: 'yes',
      label: <Typography variant='body2'>Yes</Typography>
    },
    {
      value: 'no',
      label: <Typography variant='body2'>No</Typography>
    }
  ]
};

export const nonFaceToFaceSupportProvisionFilter = {
  field: 'NonFaceToFaceSupportProvision',
  name: 'Non-F2F',
  options: [
    {
      value: 'yes',
      label: <Typography variant='body2'>Yes</Typography>
    },
    {
      value: 'no',
      label: <Typography variant='body2'>No</Typography>
    }
  ]
};

export const providerTravelFilter = {
  field: 'ProviderTravel',
  name: 'Provider Travel',
  options: [
    {
      value: 'yes',
      label: <Typography variant='body2'>Yes</Typography>
    },
    {
      value: 'no',
      label: <Typography variant='body2'>No</Typography>
    }
  ]
};

export const shortNoticeCancellationsFilter = {
  field: 'ShortNoticeCancellations',
  name: 'Short Notice Cancellations',
  options: [
    {
      value: 'yes',
      label: <Typography variant='body2'>Yes</Typography>
    },
    {
      value: 'no',
      label: <Typography variant='body2'>No</Typography>
    }
  ]
};

export const ndiaRequestedReportsFilter = {
  field: 'NdiaRequestedReports',
  name: 'NDIA Requested Reports',
  options: [
    {
      value: 'yes',
      label: <Typography variant='body2'>Yes</Typography>
    },
    {
      value: 'no',
      label: <Typography variant='body2'>No</Typography>
    }
  ]
};

export const irregularSILSupportsFilter = {
  field: 'IrregularSILSupports',
  name: 'Irregular SIL Supports',
  options: [
    {
      value: 'yes',
      label: <Typography variant='body2'>Yes</Typography>
    },
    {
      value: 'no',
      label: <Typography variant='body2'>No</Typography>
    }
  ]
};

export const productAndServicesFilters = [
  quoteFilter,
  nonFaceToFaceSupportProvisionFilter,
  providerTravelFilter,
  shortNoticeCancellationsFilter,
  ndiaRequestedReportsFilter,
  irregularSILSupportsFilter,
  isActiveFilter
];