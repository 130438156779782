import React, { FC } from 'react';
import { LinearProgress } from '@mui/material';
import ViewParticipantGoal from 'components/features/Views/ViewParticipantGoal';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useParams } from 'react-router-dom';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import { getParticipantGoalById } from 'api/organisations/participants/goals';
import { useAtom } from 'jotai';
import { organisationStateAtom } from 'atoms/organisationsAtom';

const ViewParticipantGoalPage: FC = () => {
  const { id, goalId } = useParams();
  const [organisations] = useUserOrganisations();
  const [organisationState] = useAtom(organisationStateAtom);

  // If these aren't loaded, we'll skip the fetch until they are
  const canFetch = organisationState.hasBeenFetched && organisations.length > 0;

  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({
    getByIDFunc: () => getParticipantGoalById(organisations[0].organisation.globalId, id as string, goalId as string),
    skip: !canFetch,
  });

  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;

  return <ViewParticipantGoal participantGoal={currentDataPoint} />;
};

export default ViewParticipantGoalPage;