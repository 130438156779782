import React from 'react';
import { LinearProgress } from '@mui/material';
import { organisationStateAtom } from 'atoms/organisationsAtom';
import ParticipantScheduleForm from 'components/features/Forms/ParticipantScheduleForm';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useAtom } from 'jotai';
import { useParams } from 'react-router-dom';
import { placeholderParticipantSchedule } from 'utils/helpers/getDBData';

const EditParticipantSchedulePage = () => {
  const { id } = useParams();
  const [organisations] = useUserOrganisations();
  const [organisationState] = useAtom(organisationStateAtom);

  // If these aren't loaded, we'll skip the fetch until they are
  const canFetch = organisationState.hasBeenFetched && organisations.length > 0;

  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({
    // getByIDFunc: () => getUserById(organisations[0].organisation.globalId, id as string),
    getByIDFunc: () => placeholderParticipantSchedule[0] as any, // TODO: Replace with actual fetch function
    skip: !canFetch
  });

  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;

  return <ParticipantScheduleForm participantSchedule={currentDataPoint} />;
};

export default EditParticipantSchedulePage;