import axiosInstance from "api/config";
import { SelectedFilter } from "components/common/FilterLookup/types";
import { ProductAndServiceByRegion, ProductAndServiceList, TPriceGuideRegion } from "types/dbSchema/productAndServices";
import { createFilterString } from "utils/helpers";

// Returns a list of products and services from the price guide
export const listSupportItems = async (
  filters: SelectedFilter[] | null,
  page?: number,
  pageSize?: number,
  searchText?: string
): Promise<ProductAndServiceList> => {
  try {

    // Create a formatted filter string
    let formattedFilters = '';
    if (filters && filters.length > 0) {
      formattedFilters = createFilterString(filters);
    }

    const response = await axiosInstance.get(`/api/pricing/supportitems`, {
      params: {
        filters: formattedFilters,
        page,
        pageSize,
        searchText
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing support items:', error);
    throw error;
  }
};

// Returns a list of products and services from the price guide
export const getSupportItemByID = async (
  supportItemId: string,
  pricingRegion: TPriceGuideRegion,
  page?: number,
  pageSize?: number,
): Promise<ProductAndServiceByRegion> => {
  try {

    if (!supportItemId) throw new Error('Support Item ID is required');
    if (!pricingRegion) throw new Error('Pricing Region is required');

    const response = await axiosInstance.get(`/api/pricing/supportitem/${supportItemId}`, {
      params: {
        pricingRegion,
        page,
        pageSize,
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing support items:', error);
    throw error;
  }
};