import React, { FC, useCallback, useState } from 'react';
import { Stack } from '@mui/material';
import DataTableControls from './Controls';
import DataTable from 'components/common/DataTable';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { GridRowId } from '@mui/x-data-grid';
import { Columns } from './Controls/Columns';
import { SelectedFilter } from 'components/common/FilterWithDropdown/types';
import { Data, PaginationModel } from 'components/common/DataTable/types';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import ExternalContactForm from 'components/features/Forms/ExternalContactForm';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import { listExternalContacts } from 'api/organisations/externalcontacts';
import useUserOrganisations from 'hooks/useUserOrganisations';
import useInvalidateData from 'hooks/useInvalidateData';
import { paginationModelDefault } from 'utils/helpers';
import useDebounceValue from 'hooks/useDebounceValue';

const ContactsDataTable: FC = () => {
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([]);
  const [searchText, setSearchText] = useState('');
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>(paginationModelDefault);
  const [, setModalState] = useAtom(modalStateAtom);
  const [organisations] = useUserOrganisations();
  useInvalidateData('external-contacts');

  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: ExternalContactForm,
      props: {
        type: 'create',
      }
    }));
  }, [setModalState]);

  const debouncedSearchText = useDebounceValue(searchText, 500);

  const { isPending, isError, data, isFetching, } = useQuery({
    queryKey: ['external-contacts', paginationModel, selectedFilters, debouncedSearchText],
    queryFn: () => listExternalContacts(
      organisations[0].organisation.globalId,
      selectedFilters,
      paginationModel.page + 1,
      paginationModel.pageSize,
      debouncedSearchText
    ),
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.externalContacts,
  });

  return (
    <Stack height='100%' width='100%' sx={{ backgroundColor: 'white' }}>
      <DataTableControls
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        searchText={searchText}
        setSearchText={setSearchText}
        selectedRowIds={selectedRowIds}
      />
      <DataTable
        data={data as Data}
        columns={Columns}
        setSelectedRowIds={setSelectedRowIds}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        isPending={isPending || isFetching}
        isError={isError}
        onNoDataTitle='No Contacts added yet'
        dataTableName='external-contacts'
        checkboxSelection={false}
        paginationMode='server'
        onNoData={handleModalOpen}
        allowDataImport
      />
    </Stack>
  );
};

export default ContactsDataTable;