import React, { forwardRef, useEffect, useState } from 'react';
import { Avatar, Stack, Typography } from '@mui/material';
import variables from 'styles/variables';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import styled from 'styled-components';
import { ProfileImageUploadProps } from './types';

const ProfileImageUpload = forwardRef<HTMLInputElement, ProfileImageUploadProps>(({ variant, isMandatory = false, ...props }, ref) => {
  const { value } = props;
  const [imageFile, setImageFile] = useState<File | null>(null); // Store the file itself in state
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string>(value || ''); // Use the initial URL

  // TODO: When editing from the view page and the value is updated we will update the database
  // const uploadImage = (file: File) => {
  // Implement image upload functionality here
  // const data = axios.patch(`/api/users/${userId}`, { profileImage: file });
  // };

  // Handle file selection
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
    }
  };

  const handleClose = () => {
    setImageFile(null);
    setImagePreviewUrl('');
  };

  // Create a URL for the selected image file
  useEffect(() => {
    if (imageFile) {
      const fileUrl = URL.createObjectURL(imageFile);
      setImagePreviewUrl(fileUrl);

      // Clean up the URL to avoid memory leaks
      return () => {
        URL.revokeObjectURL(fileUrl);
      };
    }
  }, [imageFile]);

  return (
    <Stack>
      {variant === 'text' && (
        <label>
          <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary} sx={{ marginBottom: '8px' }}>
            Profile picture {isMandatory && <span style={{ color: variables.colors.error.main }}>*</span>}
          </Typography>
        </label>
      )}
      <Stack flexDirection='row' alignItems='center' gap='8px' position='relative'>
        {(imagePreviewUrl && variant === 'text') && (
          <StyledCloseOutlined onClick={handleClose} />
        )}
        {(variant === 'icon') && (
          <label htmlFor="raised-button-file">
            <StyledEditOutlinedIcon />
          </label>
        )}
        <Avatar
          alt='Profile image'
          src={imagePreviewUrl ? imagePreviewUrl : ''}
          sx={{ width: '64px', height: '64px' }}
        />
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="raised-button-file"
          multiple={false}
          type="file"
          onChange={handleImageChange}
          ref={ref}
        />
        {variant === 'text' &&
          <label htmlFor="raised-button-file">
            <Typography variant='body2' fontWeight='600' color={variables.colors.primary.main} sx={{ cursor: 'pointer' }}>
              {imagePreviewUrl ? 'Change' : 'Add Image'}
            </Typography>
          </label>
        }
      </Stack>
    </Stack>
  );
});

const StyledCloseOutlined = styled(CloseOutlined)`
  cursor: pointer;
  height: 12px;
  width: 12px;
  background-color: ${variables.colors.primary.subtle};
  color: ${variables.colors.primary.darker};
  transition: background-color 200ms;
  border-radius: 100%;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  &:hover {
    background-color: ${variables.colors.primary.main};
  }
`;

const StyledEditOutlinedIcon = styled(EditOutlinedIcon)`
  cursor: pointer;
  height: 20px;
  width: 20px;
  padding: 4px;
  background-color: ${variables.colors.primary.subtle};
  color: ${variables.colors.primary.darker};
  transition: background-color 200ms;
  border-radius: 100%;
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  &:hover {
    background-color: ${variables.colors.primary.main};
  }
`;

ProfileImageUpload.displayName = 'ProfileImageUpload';

export default ProfileImageUpload;