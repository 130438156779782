import { IPages } from 'types';
import { Navigate } from 'react-router-dom';
import { permissions } from 'types/dbSchema/permissions';

// Base/Unique Pages
import HomePage from 'components/pages/HomePage';
import LoginPage from 'components/pages/LoginPage';
import ReturnPage from 'components/pages/ReturnPage';
import PowerviewPage from 'components/pages/PowerviewPage';
import MyUserProfilePage from 'components/pages/MyUserProfilePage';
import OrganisationRegistrationPage from 'components/pages/OrganisationRegistrationPage';

// Views
import ViewUserProfilePage from 'components/pages/Settings/UserManagement/UserProfiles/ViewUserProfilePage';
import ViewParticipantPage from 'components/pages/ParticipantCentre/Participants/ViewParticipantPage';
import ViewServiceProviderContactPage from 'components/pages/Resources/ServiceProviders/ViewServiceProvidersPage/Contacts/ViewParticipantContactPage';
import ViewServiceTeamRosterPage from 'components/pages/Scheduling/ServiceTeamPage/ViewServiceTeamRosterPage';
import ViewServiceTeamLeavePage from 'components/pages/Scheduling/ServiceTeamPage/ViewServiceTeamLeavePage';
import ViewServiceTeamDetailsPage from 'components/pages/Scheduling/ServiceTeamPage/ViewServiceTeamDetailsPage';
import ViewServiceTeamSkillsQualificationsPage from 'components/pages/Scheduling/ServiceTeamPage/ViewServiceTeamSkillsQualificationsPage';
import ViewServiceTeamNotesPage from 'components/pages/Scheduling/ServiceTeamPage/ViewServiceTeamNotesPage';
import ViewServiceTeamAvailabilityPage from 'components/pages/Scheduling/ServiceTeamPage/ViewServiceTeamAvailabilityPage';
import ViewParticipantContactPage from 'components/pages/ParticipantCentre/Participants/ViewParticipantPage/Contacts/ViewParticipantContactPage';
import ViewContactPage from 'components/pages/ParticipantCentre/ContactsConsole/ViewContactPage';
import ViewParticipantGoalPage from 'components/pages/ParticipantCentre/Participants/ViewParticipantPage/Goals/ViewParticipantGoalPage';
import ViewServiceProvidersPage from 'components/pages/Resources/ServiceProviders/ViewServiceProvidersPage';
import ViewPayfilePage from 'components/pages/Billing/Payfiles/ViewPayfilePage';
import ViewServiceContractsPage from 'components/pages/ParticipantCentre/Participants/ViewParticipantPage/ServiceContracts/ViewServiceContractsPage';
import ViewInvoiceBatchesPage from 'components/pages/Billing/InvoiceBatches/ViewInvoiceBatchesPage';
import ViewNDISClaimPage from 'components/pages/Billing/NDISClaims/ViewNDISClaimPage';
import ViewProductAndServicePage from 'components/pages/Resources/ProductsAndServices/ViewProductAndServicePage';
import ViewParticipantSchedulePage from 'components/pages/Scheduling/ParticipantSchedules/ViewParticipantSchedulePage';
import ViewAvailabilitySettingsPage from 'components/pages/Settings/AvailabilitySettings/ViewAvailabilitySettingsPage';
import ViewServiceTeamSettingsPage from 'components/pages/Settings/ViewServiceTeamSettingsPage';
import ViewSchedulingSettingsPage from 'components/pages/Settings/ViewSchedulingSettingsPage';
import ViewParticipantSettingsPage from 'components/pages/Settings/ViewParticipantSettingsPage';
import ViewNotificationSettingsPage from 'components/pages/Settings/NotificationSettings/ViewNotificationSettingsPage';
import ViewOrganisationSettingsPage from 'components/pages/Settings/OrganisationSettings/ViewOrganisationSettingsPage';
import ViewServiceProviderSettingsPage from 'components/pages/Settings/ViewServiceProviderSettingsPage';
// import ViewTaskSettings from 'components/pages/Settings/ViewTaskSettingsPage'; // This page is partially completed (except for the APIs), it is not in the scope of MVP1
// import ViewIncidentSettingsPage from 'components/pages/Settings/ViewIncidentSettingsPage'; // This page is partially completed (except for the APIs), it is not in the scope of MVP1


// List Pages
import ListUserPositionsPage from 'components/pages/Settings/UserManagement/ListUserPositionsPage';
import ListServiceRegionsPage from 'components/pages/Resources/ListServiceRegionsPage';
import ListUnallocatedServicesPage from 'components/pages/Scheduling/ListUnallocatedServicesPage';
import ListParticipantsPage from 'components/pages/ParticipantCentre/Participants/ListParticipantsPage';
import ListUserProfilesPage from 'components/pages/Settings/UserManagement/UserProfiles/ListUserProfilesPage';
import ListPayfilesPage from 'components/pages/Billing/Payfiles/ListPayfilesPage';
import ListNDISClaimsPage from 'components/pages/Billing/NDISClaims/ListNDISClaimsPage';
import ListInvoiceBatchesPage from 'components/pages/Billing/InvoiceBatches/ListInvoiceBatchesPages';
import ListServiceProvidersPage from 'components/pages/Resources/ServiceProviders/ListServiceProvidersPage';
import ListContactsPage from 'components/pages/ParticipantCentre/ContactsConsole/ListContactsPage';
import ListSchedulingSupportsPage from 'components/pages/Scheduling/ListSchedulingSupportsPage';
import ListUserRolesPage from 'components/pages/Settings/UserManagement/ListUserRolesPage';
import ListParticipantSchedulePage from 'components/pages/Scheduling/ParticipantSchedules/ListParticipantSchedulePage';
import ListProductAndServicesPage from 'components/pages/Resources/ProductsAndServices/ListProductAndServicesPage';

// Edit Pages
import EditServiceProvidersPage from 'components/pages/Resources/ServiceProviders/EditServiceProvidersPage';
import EditParticipantGoalPage from 'components/pages/ParticipantCentre/Participants/ViewParticipantPage/Goals/EditParticipantGoalPage';
import EditNotificationSettingsPage from 'components/pages/Settings/NotificationSettings/EditNotificationSettingsPage';
import EditAvailabilityTemplatePage from 'components/pages/Settings/AvailabilitySettings/EditAvailabilityTemplatePage';
import EditOrganisationDetailsPage from 'components/pages/Settings/OrganisationSettings/EditOrganisationDetailsPage';
import EditParticipantPage from 'components/pages/ParticipantCentre/Participants/EditParticipantPage';
import EditUserProfilePage from 'components/pages/Settings/UserManagement/UserProfiles/EditUserProfilePage';
import EditParticipantSchedulePage from 'components/pages/Scheduling/ParticipantSchedules/EditParticipantSchedulePage';

// Create Pages
import CreateParticipantPage from 'components/pages/ParticipantCentre/Participants/CreateParticipantPage';
import CreateUserProfilePage from 'components/pages/Settings/UserManagement/UserProfiles/CreateUserProfilePage';
import CreateAvailabilityTemplatePage from 'components/pages/Settings/AvailabilitySettings/CreateAvailabilityTemplatePage';
import CreateInvoiceBatchPage from 'components/pages/Billing/InvoiceBatches/CreateInvoiceBatchPage';
import CreateNDISClaimPage from 'components/pages/Billing/NDISClaims/CreateNDISClaimPage';
import CreatePayfilePage from 'components/pages/Billing/Payfiles/CreatePayfilePage';
import CreateServiceProvidersPage from 'components/pages/Resources/ServiceProviders/CreateServiceProvidersPage';
import CreateParticipantGoalPage from 'components/pages/ParticipantCentre/Participants/ViewParticipantPage/Goals/CreateParticipantGoalPage';
import CreateParticipantSchedulePage from 'components/pages/Scheduling/ParticipantSchedules/CreateParticipantSchedulePage';

// Misc/Not done yet Pages
import StaffRosterPage from 'components/pages/Scheduling/StaffRosterPage';
import ShiftReleasesPage from 'components/pages/Scheduling/ShiftReleasesPage';
import ShiftApprovalPage from 'components/pages/Scheduling/ShiftApprovalPage';
import ServiceTeamPage from 'components/pages/Scheduling/ServiceTeamPage';
import NotificationsPage from 'components/pages/Scheduling/ViewNotificationsPage';
import ReviewParticipantGoalPage from 'components/pages/ParticipantCentre/Participants/ViewParticipantPage/Goals/ReviewParticipantGoalPage';
import LinkContactToParticipantPage from 'components/pages/ParticipantCentre/Participants/ViewParticipantPage/Contacts/LinkContactToParticipantPage';
import LinkParticipantToContactPage from 'components/pages/ParticipantCentre/ContactsConsole/LinkParticipantToContactPage';

// Below are lists of pages that will be rendered in the application - see src/App.tsx
// Each page has a "to" property which is the path that the page will be accessible at.
// The "pageComponent" property is a REFERENCE to a component that will be rendered when the path is accessed.
// The "props" property is an object that will be passed to the component when it is rendered.
// The "children" property is an array of pages that will be accessible under the current page.

// The uniquePages array contains pages that handle their own layout, authentication, routing, logic etc.
export const uniquePages: IPages[] = [
  {
    to: '/login',
    pageComponent: LoginPage,
    requiredPermissions: [],
  },
  {
    to: '/return',
    pageComponent: ReturnPage,
    requiredPermissions: [],
  },
];

// The pagesWithoutLayout array contains pages that do not follow the layout of the application, they are rendered inside ProtectedRoute.
export const pagesWithoutLayout: IPages[] = [
  {
    to: '/organisation-registration',
    pageComponent: OrganisationRegistrationPage,
    requiredPermissions: [],
  },
  {
    to: '/settings/user-management/user-profiles/create',
    pageComponent: CreateUserProfilePage,
    requiredPermissions: [],
  },
  {
    to: '/settings/user-management/user-profiles/edit/:id',
    pageComponent: EditUserProfilePage,
    requiredPermissions: [],
  },
  {
    to: '/participant-centre/participants/create',
    pageComponent: CreateParticipantPage,
    requiredPermissions: [permissions.CREATE_PARTICIPANTS],
  },
  {
    to: '/participant-centre/participants/edit/:id',
    pageComponent: EditParticipantPage,
    requiredPermissions: [],
  },
  {
    to: '/participant-centre/participants/view/:id/goals/create',
    pageComponent: CreateParticipantGoalPage,
    requiredPermissions: [],
  },
  {
    to: '/participant-centre/participants/view/:id/goals/edit/:goalId',
    pageComponent: EditParticipantGoalPage,
    requiredPermissions: [],
  },
  {
    to: '/participant-centre/participants/view/:id/goals/review/:goalId',
    pageComponent: ReviewParticipantGoalPage,
    requiredPermissions: [],
  },
  {
    to: '/participant-centre/participants/view/:id/link-contact',
    pageComponent: LinkContactToParticipantPage,
    requiredPermissions: [],
  },
  {
    to: '/participant-centre/contacts-console/view/:id/link-participant',
    pageComponent: LinkParticipantToContactPage,
    requiredPermissions: [],
  },
  {
    to: '/scheduling/service-team/:id/availability',
    pageComponent: ViewServiceTeamAvailabilityPage,
    requiredPermissions: [],
  },
  {
    to: '/settings/organisation/organisation-settings/edit',
    pageComponent: EditOrganisationDetailsPage,
    requiredPermissions: [],
  },
  {
    to: '/scheduling/participant-schedules/create',
    pageComponent: CreateParticipantSchedulePage,
    requiredPermissions: [],
  },
  {
    to: '/scheduling/participant-schedules/edit/:id',
    pageComponent: EditParticipantSchedulePage,
    requiredPermissions: [],
  },
  {
    to: '/resources/service-providers/create',
    pageComponent: CreateServiceProvidersPage,
    requiredPermissions: [],
  },
  {
    to: '/resources/service-providers/edit/:id',
    pageComponent: EditServiceProvidersPage,
    requiredPermissions: [],
  },
  {
    to: '/settings/notification-settings/edit/:id',
    pageComponent: EditNotificationSettingsPage,
    requiredPermissions: [],
  },
  {
    to: '/billing/ndis-claims/create',
    pageComponent: CreateNDISClaimPage,
    requiredPermissions: [],
  },
  {
    to: '/billing/invoice-batches/create',
    pageComponent: CreateInvoiceBatchPage,
    requiredPermissions: [],
  },
  {
    to: '/billing/payfiles/create',
    pageComponent: CreatePayfilePage,
    requiredPermissions: [],
  },
];

// The pages array contains pages that will be rendered in the application with the layout, they are rendered inside ProtectedRoute.
export const pages: IPages[] = [
  {
    to: '/',
    pageComponent: HomePage,
    requiredPermissions: [],
  },
  {
    to: '/powerview',
    pageComponent: PowerviewPage,
    requiredPermissions: [],
  },
  // There is no page a the base path. Redirect to first child page
  {
    to: '/participant-centre',
    pageComponent: Navigate,
    props: {
      replace: true,
      to: '/participant-centre/participants'
    },
    requiredPermissions: [permissions.VIEW_PARTICIPANT_CENTRE],
  },
  {
    to: '/participant-centre/participants',
    pageComponent: ListParticipantsPage,
    requiredPermissions: [permissions.VIEW_PARTICIPANT_CENTRE, permissions.VIEW_PARTICIPANTS],
  },
  {
    to: '/participant-centre/participants/view/:id',
    pageComponent: ViewParticipantPage,
    requiredPermissions: [],
  },
  {
    to: '/participant-centre/participants/:id/service-contracts/:serviceContractId',
    pageComponent: ViewServiceContractsPage,
    requiredPermissions: [],
  },
  {
    to: '/participant-centre/participants/view/:id/goals/view/:goalId',
    pageComponent: ViewParticipantGoalPage,
    requiredPermissions: [],
  },
  {
    to: '/participant-centre/participants/view/:id/contacts/:contactId',
    pageComponent: ViewParticipantContactPage,
    requiredPermissions: [],
  },
  // Not in the scope of MVP1
  // {
  //   to: '/participant-centre/tasks',
  //   pageComponent: PlaceholderPage,
  //   props: { label: 'Tasks' },
  //   requiredPermissions: [],
  // },
  {
    to: '/participant-centre/contacts-console',
    pageComponent: ListContactsPage,
    requiredPermissions: [],
  },
  {
    to: '/participant-centre/contacts-console/view/:id',
    pageComponent: ViewContactPage,
    requiredPermissions: [],
  },
  // Not in the scope of MVP1
  // {
  //   to: '/participant-centre/incidents',
  //   pageComponent: PlaceholderPage,
  //   props: { label: 'Incidents' },
  //   requiredPermissions: [],
  // },
  // There is no page a the base path. Redirect to first child page
  {
    to: '/scheduling',
    pageComponent: Navigate,
    props: {
      replace: true,
      to: '/scheduling/schedule-of-supports'
    },
    requiredPermissions: [],
  },
  {
    to: '/scheduling/participant-schedules',
    pageComponent: ListParticipantSchedulePage,
    requiredPermissions: [],
  },
  {
    to: '/scheduling/participant-schedules/view/:id',
    pageComponent: ViewParticipantSchedulePage,
    requiredPermissions: [],
  },
  {
    to: '/scheduling/staff-roster',
    pageComponent: StaffRosterPage,
    requiredPermissions: [],
  },
  {
    to: '/scheduling/shift-releases',
    pageComponent: ShiftReleasesPage,
    requiredPermissions: [],
  },
  {
    to: '/scheduling/shift-approval',
    pageComponent: ShiftApprovalPage,
    requiredPermissions: [],
  },
  {
    to: '/scheduling/service-team',
    pageComponent: ServiceTeamPage,
    requiredPermissions: [],
  },
  {
    to: '/scheduling/service-team/:id/roster',
    pageComponent: ViewServiceTeamRosterPage,
    requiredPermissions: [],
  },
  {
    to: '/scheduling/service-team/:id/leave',
    pageComponent: ViewServiceTeamLeavePage,
    requiredPermissions: [],
  },
  {
    to: '/scheduling/service-team/:id/employee-details',
    pageComponent: ViewServiceTeamDetailsPage,
    requiredPermissions: [],
  },
  {
    to: '/scheduling/service-team/:id/skills-qualifications',
    pageComponent: ViewServiceTeamSkillsQualificationsPage,
    requiredPermissions: [],
  },
  {
    to: '/scheduling/service-team/:id/employee-notes',
    pageComponent: ViewServiceTeamNotesPage,
    requiredPermissions: [],
  },
  {
    to: '/scheduling/unallocated-services',
    pageComponent: ListUnallocatedServicesPage,
    requiredPermissions: [],
  },
  {
    to: '/scheduling/scheduling-supports',
    pageComponent: ListSchedulingSupportsPage,
    requiredPermissions: [],
  },
  {
    to: '/scheduling/notifications',
    pageComponent: NotificationsPage,
    requiredPermissions: [],
  },
  // There is no page a the base path. Redirect to first child page
  {
    to: '/billing',
    pageComponent: ListNDISClaimsPage,
    props: {
      replace: true,
      to: '/billing/ndis-claims'
    },
    requiredPermissions: [],
  },
  {
    to: '/billing/ndis-claims',
    pageComponent: ListNDISClaimsPage,
    requiredPermissions: [],
  },
  {
    to: '/billing/invoice-batches',
    pageComponent: ListInvoiceBatchesPage,
    requiredPermissions: [],
  },
  {
    to: '/billing/payfiles',
    pageComponent: ListPayfilesPage,
    requiredPermissions: [],
  },
  {
    to: '/billing/ndis-claims/view/:id',
    pageComponent: ViewNDISClaimPage,
    requiredPermissions: [],
  },
  {
    to: '/billing/invoice-batches/view/:id',
    pageComponent: ViewInvoiceBatchesPage,
    requiredPermissions: [],
  },
  {
    to: '/billing/payfiles/view/:id',
    pageComponent: ViewPayfilePage,
    requiredPermissions: [],
  },
  // There is no page a the base path. Redirect to first child page
  {
    to: '/resources',
    pageComponent: Navigate,
    props: {
      replace: true,
      to: '/resources/service-providers'
    },
    requiredPermissions: [],
  },
  {
    to: '/resources/service-providers',
    pageComponent: ListServiceProvidersPage,
    requiredPermissions: [],
  },
  {
    to: '/resources/service-providers/view/:id',
    pageComponent: ViewServiceProvidersPage,
    requiredPermissions: [],
  },
  {
    to: '/resources/service-providers/view/:id/contact/:contactId',
    pageComponent: ViewServiceProviderContactPage,
    requiredPermissions: [],
  },
  {
    to: '/resources/service-regions',
    pageComponent: ListServiceRegionsPage,
    requiredPermissions: [],
  },
  {
    to: '/resources/products-services',
    pageComponent: ListProductAndServicesPage,
    requiredPermissions: [],
  },
  {
    to: '/resources/products-services/view/:id',
    pageComponent: ViewProductAndServicePage,
    requiredPermissions: [],
  },
  // Not in the scope of MVP1
  // {
  //   to: '/downloads',
  //   pageComponent: PlaceholderPage,
  //   props: { label: 'Downloads' },
  //   requiredPermissions: [],
  // },
  {
    to: '/user-profile',
    pageComponent: MyUserProfilePage,
    requiredPermissions: [],
  },
  // Not in the scope of MVP1
  // There is no page a the base path. Redirect to first child page
  // {
  //   to: '/help-centre',
  //   pageComponent: Navigate,
  //   props: {
  //     replace: true,
  //     to: '/help-centre/help-articles'
  //   },
  //   requiredPermissions: [],
  // },
  // {
  //   to: '/help-centre/help-articles',
  //   pageComponent: PlaceholderPage,
  //   props: { label: 'Help Articles' },
  //   requiredPermissions: [],
  // },
  // Not in the scope of MVP1
  // {
  //   to: '/help-centre/support-requests',
  //   pageComponent: PlaceholderPage,
  //   props: { label: 'Support Requests' },
  //   requiredPermissions: [],
  // },
  // Not in the scope of MVP1
  // {
  //   to: '/notification-alerts',
  //   pageComponent: PlaceholderPage,
  //   props: { label: 'Notifications Alerts' },
  //   requiredPermissions: [],
  // },
  // There is no page a the base path. Redirect to first child page
  {
    to: '/organisation',
    pageComponent: Navigate,
    props: {
      replace: true,
      to: '/settings/organisation/organisation-settings'
    },
    requiredPermissions: [],
  },
  {
    to: '/settings/organisation/organisation-settings',
    pageComponent: ViewOrganisationSettingsPage,
    requiredPermissions: [],
  },
  // There is no page a the base path. Redirect to first child page
  {
    to: '/settings/user-management',
    pageComponent: Navigate,
    props: {
      replace: true,
      to: '/settings/user-management/user-profiles'
    },
    requiredPermissions: [],
  },
  {
    to: '/settings/user-management/user-profiles',
    pageComponent: ListUserProfilesPage,
    requiredPermissions: [],
  },
  {
    to: '/settings/user-management/user-profiles/view/:id',
    pageComponent: ViewUserProfilePage,
    requiredPermissions: [],
  },
  {
    to: '/settings/user-management/user-positions',
    pageComponent: ListUserPositionsPage,
    requiredPermissions: [],
  },
  {
    to: '/settings/user-management/user-roles',
    pageComponent: ListUserRolesPage,
    requiredPermissions: [],
  },
  {
    to: '/settings/participant',
    pageComponent: ViewParticipantSettingsPage,
    requiredPermissions: [],
  },
  {
    to: '/settings/scheduling-settings',
    pageComponent: ViewSchedulingSettingsPage,
    requiredPermissions: [],
  },
  {
    to: '/settings/service-team-settings',
    pageComponent: ViewServiceTeamSettingsPage,
    requiredPermissions: [],
  },
  {
    to: '/settings/availability-settings',
    pageComponent: ViewAvailabilitySettingsPage,
    requiredPermissions: [],
  },
  {
    to: '/settings/availability-settings/create',
    pageComponent: CreateAvailabilityTemplatePage,
    requiredPermissions: [],
  },
  {
    to: '/settings/availability-settings/edit/:id',
    pageComponent: EditAvailabilityTemplatePage,
    requiredPermissions: [],
  },
  // This page is partially completed (except for the APIs), it is not in the scope of MVP1
  // {
  //   to: '/settings/task-settings',
  //   pageComponent: TaskSettings,
  //   props: { label: 'task-settings' },
  // requiredPermissions: [],
  // },
  // This page is partially completed (except for the APIs), it is not in the scope of MVP1
  // {
  //   to: '/settings/incident-settings',
  //   pageComponent: IncidentSettingsPage,
  //   props: { label: '/settings/incident-settings' },
  // requiredPermissions: [],
  // },
  //
  {
    to: '/settings/provider-settings',
    pageComponent: ViewServiceProviderSettingsPage,
    requiredPermissions: [],
  },
  {
    to: '/settings/notification-settings',
    pageComponent: ViewNotificationSettingsPage,
    requiredPermissions: [],
  },
  // Not in the scope of MVP1
  // {
  //   to: '/settings/integration',
  //   pageComponent: PlaceholderPage,
  //   props: { label: 'Integration' },
  //   requiredPermissions: [],
  // },
];