import React, { FC, useCallback } from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Button } from '@mui/material';
import { modalStateAtom } from 'atoms';
import { useAtom } from 'jotai';
import ServiceProviderContactForm from 'components/features/Forms/ServiceProviderContactForm';
import { useParams } from 'react-router-dom';

const Actions: FC = () => {
  const [, setModalState] = useAtom(modalStateAtom);
  const { id } = useParams();

  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: ServiceProviderContactForm,
      props: {
        type: 'create',
        serviceProviderId: id,
      }
    }));
  }, [setModalState]);

  return (
    <Button color='primary' variant='contained' onClick={() => handleModalOpen()} startIcon={<AddOutlinedIcon />} sx={{ minWidth: 'fit-content', height: '100%' }}>
      Add
    </Button>
  );
};

export default Actions;