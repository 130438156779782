import React, { useState, useRef, forwardRef, useEffect } from 'react';
import { CircularProgress, LinearProgress, Stack, Typography } from '@mui/material';
import variables from 'styles/variables';
import styled from 'styled-components';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchInput from '../InputFields/SearchInput';
import { LookupFieldProps, LookupSelectFieldOptions, StyledLookupFieldProps } from './types';


// Recreate the MUI AutoComplete/ComboBox component
//   - This includes a search box to filter the options inside the dropdown (querying DB)
//   - Allows for any label (react element) to display in the dropdown
//   - Search box is pinned to the top of the dropdown
//   - Fixing MUIs issue of strange/bad functionality when the component is mounted inside a modal
//   - Integrating with react-hook-form
//   - Passing the ref down from react-hook-form to the component
//   - Preventing the dropdown from closing when the search input is clicked
//   - Infinite scroll to load more options
const LookupInputField = forwardRef<HTMLDivElement, LookupFieldProps>(({
  id,
  label,
  errorText,
  helperText,
  placeholder,
  hasNextPage,
  isMandatory,
  searchValue,
  isFetching,
  validationError,
  fetchNextPage,
  isFetchingNextPage,
  dataFetchingError,
  onChange,
  options,
  setSearchValue,
  value,
  ...props }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropUp, setDropUp] = useState(false);
  const [isCalculatingDropUp, setIsCalculatingDropUp] = useState(false);
  const internalRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  // Set the selected option to the option that matches the value, or null if no match
  const [selectedOption, setSelectedOption] = useState<LookupSelectFieldOptions | null>(null);

  useEffect(() => {
    setSelectedOption(options.find(option => option.value === value) || null);
  }, [options, value]);

  useEffect(() => {
    if (isOpen && dropdownRef.current) {
      const boundingRect = dropdownRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - boundingRect.bottom;

      if (spaceBelow < 20) {
        setDropUp(true);
      } else {
        setDropUp(false);
      }
      setIsCalculatingDropUp(false);
    }
  }, [isOpen]);

  useEffect(() => {
    const handleScroll = (event: any) => {
      if (!event.target) return;
      const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
      if (bottom && hasNextPage) fetchNextPage();
    };

    const selectElement = internalRef.current as HTMLElement | null;
    if (selectElement) selectElement.addEventListener('scroll', handleScroll);


    return () => {
      if (selectElement) selectElement.removeEventListener('scroll', handleScroll);

    };
  }, [fetchNextPage, hasNextPage]);

  // Function to close the dropdown when clicking outside the component
  // Does not close when clicking the component or the dropdown
  useEffect(() => {
    // Function to check if the click is outside the component
    const handleClickOutside = (event: MouseEvent) => {
      if (internalRef.current && !internalRef.current.contains(event.target as Node) &&
        dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
        setDropUp(false);
      }
    };
    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);
    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [internalRef]);

  const handleOptionClick = (optionValue: LookupSelectFieldOptions) => {
    setSelectedOption(optionValue);
    onChange(optionValue.value);
    setIsOpen(false);
    setDropUp(false);
  };

  const handleInputClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!isOpen) {
      setIsOpen(true);
      setIsCalculatingDropUp(true);
    } else {
      setIsOpen(false);
      setDropUp(false);
    }
  };

  return (
    <div ref={internalRef} style={{ margin: '0', position: 'relative', width: '100%' }} {...props}>
      {label && (
        <label htmlFor={id}>
          <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary} sx={{ marginBottom: '4px' }}>
            {label} {isMandatory && <span style={{ color: 'red' }}>*</span>}
          </Typography>
        </label>
      )}

      <StyledSelectInput error={validationError ? true : false} onClick={handleInputClick} isOpen={isOpen} tabIndex={0} ref={ref}>
        {selectedOption?.label ? (
          selectedOption.label
        ) : (
          <Typography variant='body2' color={selectedOption ? variables.colors.text.primary : variables.colors.text.secondary}>
            {placeholder}
          </Typography>
        )}
        <KeyboardArrowDownIcon sx={{ transform: isOpen ? 'rotate(180deg)' : '' }} />
        {isOpen && (
          <Dropdown ref={dropdownRef} dropUp={dropUp} isCalculatingDropUp={isCalculatingDropUp}>
            <Stack position='relative' gap='8px' marginBlock='8px'>
              {isFetching && !isFetchingNextPage &&
                <Stack zIndex='1000' position='absolute' top='0' left='0' width='100%' height='100%' justifyContent='center' alignItems='center' sx={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
                  <CircularProgress size={20} />
                </Stack>
              }
              <SearchBox onClick={(e) => e.stopPropagation()}>
                <SearchInput placeholderText='Search' searchValue={searchValue} setSearchValue={setSearchValue} />
              </SearchBox>
              <Stack gap='10px' maxHeight='200px' overflow='auto' onScroll={(event: any) => {
                const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
                if (bottom && hasNextPage) {
                  fetchNextPage();
                }
              }}>
                {options.map((option, index) => (
                  <Stack key={index} onClick={() => handleOptionClick(option)} sx={{ cursor: 'pointer', justifyContent: 'center', padding: '0 10px' }}>
                    {option.label}
                  </Stack>
                ))}
              </Stack>
            </Stack>
            {isFetchingNextPage &&
              <LinearProgress
                sx={{
                  position: 'absolute',
                  bottom: '0',
                  left: '0',
                  width: '100%',
                  borderRadius: '0 0 4px 4px',
                }}
              />
            }
          </Dropdown>
        )}
      </StyledSelectInput>

      {((dataFetchingError || validationError) && errorText) ? <Typography variant='subtitle2' color='red' fontWeight='400'>{errorText}</Typography>
        : helperText && <Typography variant='subtitle2' color={variables.colors.text.secondary} fontWeight='400'>{helperText}</Typography>
      }
    </div >
  );
});

const StyledSelectInput = styled(Stack).withConfig({
  shouldForwardProp: (prop) => prop !== 'error' && prop !== 'isOpen',
}) <StyledLookupFieldProps>`
  box-sizing: border-box;
  border-radius: 4px;
  border: ${({ error, isOpen }) => error ? '1px solid red' : isOpen ? `2px solid ${variables.colors.primary.darker}` : '1px solid #ccc'};
  padding: ${({ isOpen }) => isOpen ? '4px 9px' : '5px 10px'}; // Adjust padding to account for the increased border width - for some reason the box-sizing property doesn't work here 🤷‍♂️
  width: 100%;
  position: relative;
  cursor: pointer;
  min-height: 42px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  font-family: ${variables.fonts.family.primary};
  font-size: 14px;
  background-color: white;
  &:focus-visible {
    border: 2px solid #5C068C !important;
    outline: none;
    padding: 0 9px;
  }
`;

export interface DropdownProps {
  dropUp: boolean;
  isCalculatingDropUp: boolean;
}

const Dropdown = styled(Stack).withConfig({
  shouldForwardProp: (prop) => prop !== 'dropUp' && prop !== 'isCalculatingDropUp',
}) <DropdownProps>`
  z-index: 999;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  position: absolute;
  top: ${({ dropUp }) => dropUp ? 'unset' : 'calc(100% + 3px)'};
  bottom: ${({ dropUp }) => dropUp ? 'calc(100% + 3px)' : 'unset'};
  left: 0;
  background-color: white;
  border: 1px solid #E0E0E0;
  box-shadow: 0px 3px 8px -1px #E0E0E0;
  border-radius: 4px;
  opacity: ${({ isCalculatingDropUp }) => isCalculatingDropUp ? '0' : '1'};
  cursor: initial;
`;

const SearchBox = styled.div`
  border-bottom: 1px solid #E0E0E0;
  background-color: white;
  min-width: 100%;
  & * {
    border: none;
    width: 100%;
  }
  .MuiInputAdornment-positionEnd {
    width: unset;
  }
`;

LookupInputField.displayName = 'LookupInputField';

export default LookupInputField;