import React from "react";
import { Stack } from "@mui/material";
import { modalStateAtom } from "atoms";
import { useAtom } from "jotai";
import { useCallback } from "react";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ExternalContactForm from "components/features/Forms/ExternalContactForm";
import variables from "styles/variables";
import { ExternalContact } from "types/dbSchema/externalContacts";

// Renders Edit Icon for Grid column definitions - opens add/edit form in modal
export const EditIcon = ({ externalContact }: { externalContact: ExternalContact }) => {
  const [, setModalState] = useAtom(modalStateAtom);

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: ExternalContactForm,
      props: {
        type: 'edit',
        externalContact,
      }
    }));
  }, [setModalState, externalContact]);

  return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'pointer' }} onClick={handleModalOpen}>
      <EditOutlinedIcon sx={{ color: variables.colors.icon.standard }} />
    </Stack>
  );
};