import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Stack, Typography } from '@mui/material';
import { formatISODate, formatISODateTime } from 'utils/helpers';
import { Participant } from 'types/dbSchema/participants';
import Status from 'components/common/Status';
import { ParticipantScheduleStatus, participantScheduleStatuses } from 'types/dbSchema/participantSchedules';
import ParticipantProfileCell from 'components/features/ParticipantProfileCell';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Tooltip from 'components/common/Tooltip';
import ViewIcon from './ViewIcon';
import EditIcon from './EditIcon';
import DeleteIcon from './DeleteIcon';

export const Columns: GridColDef[] = [
  {
    field: 'view',
    headerName: '',
    width: 24,
    sortable: false,
    renderCell: (params) => (
      <ViewIcon participantSchedule={params.row} />
    ),
  },
  {
    field: 'edit',
    headerName: '',
    width: 24,
    sortable: false,
    renderCell: (params) => (
      <EditIcon participantSchedule={params.row} />
    ),
  },
  {
    field: 'delete',
    headerName: '',
    width: 24,
    sortable: false,
    renderCell: (params) => (
      <DeleteIcon participantSchedule={params.row} />
    ),
  },
  {
    field: 'participant',
    headerName: 'Participant',
    minWidth: 300,
    sortable: true,
    renderCell: (params) => (
      <ParticipantProfileCell
        isEditable={false}
        participant={params.row.participant as Participant}
      />
    ),
  },
  {
    field: 'emailSentError',
    headerName: '',
    width: 50,
    sortable: false,
    renderCell: (params) => {
      const emailSentError = true; // TODO: Logic to determine if email was sent error
      if (!emailSentError) return null;

      return (
        <Tooltip
          position='left'
          toolTipTitle='Email was not sent!'
          toolTipText='There was an error while sending the approval email. Find more details in the Calendar view.'
          toolTipTriggerComponent={
            <Stack justifyContent='center' alignItems='center' width='100%' height='100%' >
              <ErrorOutlineOutlinedIcon color='error' />
            </Stack>
          }
        />
      );
    }
  },
  {
    field: 'ndisNumber',
    headerName: 'NDIS No.',
    flex: 1,
    minWidth: 200,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2'>
        {params.row.ndisNumber}
      </Typography>
    ),
  },
  {
    field: 'totalBudget',
    headerName: 'Total Budget',
    width: 150,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2' fontWeight={600}>
        ${params.row.totalBudget.toFixed(2)}
      </Typography>
    ),
  },
  {
    field: 'allocatedTotal',
    headerName: 'Total Allocated',
    width: 200,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2'>
        ${params.row.allocatedTotal.toFixed(2)}
      </Typography>
    ),
  },
  {
    field: 'scheduleOfSupportStartDate',
    headerName: 'Start Date',
    width: 200,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2'>
        {formatISODate(params.row?.scheduleOfSupportStartDate)}
      </Typography>
    ),
  },
  {
    field: 'scheduleOfSupportEndDate',
    headerName: 'End Date',
    width: 200,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2'>
        {formatISODate(params.row?.scheduleOfSupportEndDate)}
      </Typography>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 200,
    sortable: true,
    renderCell: (params) => {
      const status = params.row.status as ParticipantScheduleStatus;
      const statusObject = participantScheduleStatuses.find((statusObj) => statusObj.value === status);

      if (!statusObject) return null;

      return (
        <Status
          backgroundColor={statusObject.backgroundColor}
          fontColor={statusObject.fontColor}
          label={statusObject.label}
        />
      );
    }
  },
];