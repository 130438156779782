import React, { FC, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import variables from 'styles/variables';
import styled from 'styled-components';
// import SearchInput from 'components/common/InputFields/SearchInput';
import Actions from './Actions';
import FilterWithDropdown from 'components/common/FilterWithDropdown';
import { ControlsProps } from '../../types';
import { goalStatusFilter, goalTypeFilter } from 'utils/filters';

const Controls: FC<ControlsProps> = ({
  selectedFilters,
  setSelectedFilters,
  // searchText,
  // setSearchText,
  // selectedRowIds,
}) => {
  const [filterOpen, setFilterOpen] = useState('');

  return (
    <StyledStack>
      <Stack justifyContent='space-between' height='100%' gap='16px'>
        <ActionStack>
          <Typography variant='subtitle2' minWidth='fit-content' fontSize='18px'>
            Goals
          </Typography>
          <Stack flexDirection='row' gap='16px' height='36px' width='fit-content'>
            <Filters>
              {selectedFilters.length > 0 &&
                <ResetFiltersBox onClick={() => setSelectedFilters([])}>
                  <Typography variant='body2' fontWeight='600' color='secondary' >
                    Reset filters
                  </Typography>
                </ResetFiltersBox>
              }
              <FilterWithDropdown
                filter={goalTypeFilter}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                filterOpen={filterOpen}
                setFilterOpen={setFilterOpen}
              />
              <FilterWithDropdown
                filter={goalStatusFilter}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                filterOpen={filterOpen}
                setFilterOpen={setFilterOpen}
              />
            </Filters>
            {/* Commented out for MVP */}
            {/* <SearchInput searchValue={searchText} setSearchValue={setSearchText} placeholderText='Search Participant Goals' height='100%' /> */}
            <Actions />
          </Stack>
        </ActionStack>
      </Stack>
    </StyledStack>
  );
};

const Filters = styled(Stack)`
  width: fit-content;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

const StyledStack = styled(Stack)`
  height: fit-content;
  padding: 24px;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
`;

const ActionStack = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const ResetFiltersBox = styled(Stack)`
  cursor: pointer;
  padding: 8px;
  border-right: 1px solid ${variables.colors.secondary.outlineBorder};
  min-width: fit-content;
`;

export default Controls;