import React, { FC } from 'react';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { ProductAndService } from 'types/dbSchema/productAndServices';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';

export interface ViewIconProps {
  productAndService: ProductAndService;
}

const ViewIcon: FC<ViewIconProps> = ({ productAndService }) => {
  const navigate = useNavigate();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);

  const navigateToItem = () => {
    setInvalidateData((prev) => ({ ...prev, 'products-and-services': true }));
    navigate(`/resources/products-services/view/${productAndService.id}`);
  };

  return (
    <StyledBox onClick={navigateToItem}>
      <RemoveRedEyeOutlinedIcon />
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export default ViewIcon;