import React from "react";
import { updateExternalContact } from "api/organisations/externalcontacts";
import { invalidateDataAtom } from "atoms/invalidateDataAtom";
import IsActiveDropdown from "components/common/IsActiveDropdown";
import useErrorMessage from "hooks/useErrorMessage";
import useUserOrganisations from "hooks/useUserOrganisations";
import { useAtom } from "jotai";
import { ExternalContact } from "types/dbSchema/externalContacts";

// Handles the status dropdown in the DataTable
const EditStatus = ({ externalContact }: { externalContact: ExternalContact }) => {
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const showError = useErrorMessage();

  const handleEdit = async (status: boolean) => {
    try {
      await updateExternalContact(organisations[0].organisation.globalId, { ...externalContact, isActive: status });
      setInvalidateData((prev) => ({ ...prev, 'external-contacts': true }));
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <IsActiveDropdown
      isActive={externalContact.isActive}
      setIsActive={handleEdit}
    />
  );
};

export default EditStatus;