import React, { FC, useState } from 'react';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { Stack } from '@mui/material';
import styled from 'styled-components';
import TitleWithClose from 'components/common/TitleWithClose';
import DragAndDropFileUpload from 'components/common/InputFields/DragAndDropFileUpload';
import { ModalAtomState } from 'atoms/types';
import DownloadTemplate from './DownloadTemplate';
import WarningNotice from './WarningNotice';
import ErrorNotice from './ErrorNotice';
import ButtonBox from './ButtonBox';
import { ImportStatus } from './types';
import { userMessageAtom } from 'atoms/userMessageAtom';
import { closeModalAtom } from 'atoms/modalAtom';
import { UploadedFile } from 'components/common/InputFields/DragAndDropFileUpload/types';
import { importExternalContacts } from 'api/organisations/externalcontacts';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { importUsers } from 'api/organisations/users';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import { importParticipants } from 'api/organisations/participants';
import { importServiceProviders } from 'api/organisations/serviceproviders';
import useCurrentUser from 'hooks/useCurrentUser';

export type ImportExportNames = 'user-profiles' | 'participants' | 'service-providers' | 'external-contacts';

const ImportModal: FC = () => {
  const [modalState, setModalState] = useAtom(modalStateAtom);
  const [, closeModal] = useAtom(closeModalAtom);
  const [currentFiles, setCurrentFiles] = useState<UploadedFile[]>([]);
  const validFileTypes = ['text/csv'];
  const [importStatus, setImportStatus] = useState<ImportStatus>({ status: 'idle', message: '', });
  const [, setUserMessage] = useAtom(userMessageAtom);
  const maxTotalSize = 104857600; // 100MB
  const maxFileSize = 104857600; // 100MB
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const [currentUser] = useCurrentUser();

  const importFunction = async () => {
    const datatableName = modalState.props.dataTableName as ImportExportNames;
    const formData = new FormData();
    formData.append('ImportFile', currentFiles[0].file);
    let response;

    switch (datatableName) {
      case 'user-profiles':
        response = await importUsers(organisations[0].organisation.globalId, formData);
        setInvalidateData((prev) => ({ ...prev, 'user-profiles': true }));
        closeModal();
        return response;
      case 'participants':
        response = await importParticipants(organisations[0].organisation.globalId, currentUser?.id as string, formData);
        setInvalidateData((prev) => ({ ...prev, 'participants': true }));
        closeModal();
        return response;
      case 'service-providers':
        response = await importServiceProviders(organisations[0].organisation.globalId, formData);
        setInvalidateData((prev) => ({ ...prev, 'service-providers': true }));
        closeModal();
        return response;
      case 'external-contacts':
        response = await importExternalContacts(organisations[0].organisation.globalId, currentUser?.id as string, formData);
        setInvalidateData((prev) => ({ ...prev, 'external-contacts': true }));
        closeModal();
        return response;
      default:
        console.error('Invalid data table name');
        break;
    }
  };

  // Handle upload API
  const handleImport = async () => {
    // Check if a file is selected, otherwise show an error message and return
    if (!currentFiles) {
      setImportStatus({ status: 'error', message: 'No file selected for upload.' });
      return;
    }

    // Set the modal status to loading, create a FormData object and append the file to it
    setModalState((prevState: ModalAtomState) => ({ ...prevState, status: 'loading' }));

    // Make the API call
    try {
      const response = await importFunction();

      // If some records were not imported, show a warning message
      if (response.errors.length > 0) {
        setImportStatus({ status: 'success', message: 'File uploaded successfully.' });
        closeModal();
        setUserMessage({
          title: 'Partial Success',
          message: 'The file was imported successfully, but some records were not imported due to errors. Please check the error log for more details.',
          variant: 'warning',
          open: true,
          autoHideDuration: 20000,
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        });

        // If all records were imported, show a success message
      } else if (response.entryCount === response.importedCount) {
        setImportStatus({ status: 'success', message: 'File uploaded successfully.' });
        closeModal();
        setUserMessage({
          title: 'Success',
          message: 'All records imported successfully.',
          variant: 'success',
          open: true,
          autoHideDuration: 6000,
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        });
      }

    } catch (error: any) {
      // If the API call fails, show an error message
      setUserMessage({
        title: 'Error',
        message: 'An error occurred during file upload.',
        variant: 'error',
        open: true,
        autoHideDuration: 6000,
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      });
      setImportStatus({ status: 'error', message: 'An error occurred during file upload.' });
      setModalState((prevState: ModalAtomState) => ({ ...prevState, status: 'open' })); // Update modal status to open, instead of loading, for the user to see the error
    } finally {
      setCurrentFiles([]);
    }
  };

  return (
    <ImportModalBox data-testid='ImportModal'>
      <Stack gap='32px'>
        <TitleWithClose title='Import from CSV' handleClose={closeModal} />
        <DownloadTemplate template={modalState.props.dataTableName} />
        <DragAndDropFileUpload
          setCurrentFiles={setCurrentFiles}
          currentFiles={currentFiles}
          validFileTypes={validFileTypes}
          maxTotalSize={maxTotalSize}
          maxFileSize={maxFileSize}
          onFileUploaded={() => setImportStatus({ status: 'idle', message: '' })} // Reset the import status when a new file is uploaded for UX
        />
      </Stack>
      <Stack gap='16px'>
        {currentFiles.length > 0 && <WarningNotice />}
        {importStatus.status === 'error' && <ErrorNotice errorMessage={importStatus.message} />}
        <ButtonBox handleClose={closeModal} handleImport={handleImport} isDisabled={currentFiles.length === 0} />
      </Stack>
    </ImportModalBox>
  );
};

const ImportModalBox = styled(Stack)`
  width: 100%;
  height: 100%;
  justify-content: space-between;
  position: relative;
  padding: 32px;
  overflow-y: auto;
  gap: 32px;
`;

export default ImportModal;