import React, { FC, useEffect } from 'react';
import { LinearProgress } from '@mui/material';
import ServiceTeamSkillsQualifications from 'components/features/Scheduling/ServiceTeamSchedule/ServiceTeamSkillsQualifications';
import { useAtom } from 'jotai';
import { sidebarAtom } from 'atoms';
import ServiceTeamMemberDetails from 'components/features/ServiceTeamMemberDetails';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import { getUserById } from 'api/organisations/users';
import { useParams } from 'react-router-dom';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { organisationStateAtom } from 'atoms/organisationsAtom';

const ViewServiceTeamSkillsQualificationsPage: FC = () => {
  const [sidebarState, setSidebarState] = useAtom(sidebarAtom);
  const { id } = useParams();
  const [organisations] = useUserOrganisations();
  const [organisationState] = useAtom(organisationStateAtom);

  // If these aren't loaded, we'll skip the fetch until they are
  const canFetch = organisationState.hasBeenFetched && organisations.length > 0;

  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({
    getByIDFunc: () => getUserById(organisations[0].organisation.globalId, id as string),
    skip: !canFetch,
  });

  useEffect(() => {
    setSidebarState({
      ...sidebarState,
      content: <ServiceTeamMemberDetails />,
    });
  }, [setSidebarState, sidebarState]);

  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;
  return <ServiceTeamSkillsQualifications />;
};

export default ViewServiceTeamSkillsQualificationsPage;