import React, { FC } from 'react';
import { useCallback } from "react";
import { useAtom } from "jotai";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { modalStateAtom, triggerRefreshCurrentDataPointAtom } from "atoms";
import { Box, Button, Stack, Typography } from "@mui/material";
import variables from 'styles/variables';
import { ServiceProviderContact } from 'types/dbSchema/serviceProviders';
import styled from 'styled-components';
import { closeModalAtom } from 'atoms/modalAtom';
import { deleteServiceProviderContact } from 'api/organisations/serviceproviders/serviceprovidercontacts';
import { useNavigate, useParams } from 'react-router-dom';
import useUserOrganisations from 'hooks/useUserOrganisations';
import useErrorMessage from 'hooks/useErrorMessage';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';

export const DeleteIcon = ({ serviceProviderContact }: { serviceProviderContact: ServiceProviderContact }) => {
  const [, setModalState] = useAtom(modalStateAtom);
  const { id } = useParams();

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: DeleteModal,
      props: {
        type: 'delete',
        id,
        contactId: serviceProviderContact.id
      }
    }));
  }, [setModalState]);

  return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'pointer' }} onClick={handleModalOpen}>
      <DeleteOutlinedIcon sx={{ color: '#C62828' }} />
    </Stack>
  );
};

export interface DeleteModalProps {
  id: string;
  contactId: string;
}

const DeleteModal: FC<DeleteModalProps> = ({ id, contactId }) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const [organisations] = useUserOrganisations();
  const showError = useErrorMessage();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const [, setTriggerRefresh] = useAtom(triggerRefreshCurrentDataPointAtom);

  const handleDelete = async () => {
    try {
      await deleteServiceProviderContact(organisations[0].organisation.globalId, id, contactId);
      setInvalidateData((prev) => ({ ...prev, 'service-provider-contacts': true }));
      setTriggerRefresh((prev) => prev + 1);
      closeModal();
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <Stack alignItems="center" width='500px'>
      <Stack flexDirection="row" alignItems="center" gap='24px' sx={{ padding: '32px' }}>
        <Box sx={{ borderRadius: '100%', background: '#FECDD2', padding: '8px', height: '40px', width: '40px', display: 'flex', alignSelf: 'flex-start', boxSizing: 'border-box' }}>
          <DeleteOutlinedIcon color='error' />
        </Box>
        <Stack gap='16px'>
          <Typography variant='h6'>
            Are you sure you want to delete this Service Provider Contact?
          </Typography>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            This action cannot be reversed.
          </Typography>
        </Stack>
      </Stack>
      <ButtonStack>
        <Button variant='outlined' onClick={closeModal}>Cancel</Button>
        <Button variant='contained' color='error' onClick={handleDelete}>Delete</Button>
      </ButtonStack>
    </Stack>
  );
};

const ButtonStack = styled(Stack)`
  flex-direction: row;
  width: 100%;
  padding: 16px 32px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 0px 0px 12px 12px;
  background: #F9FAFB;
  box-sizing: border-box;
`;