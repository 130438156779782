import React, { FC, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import variables from 'styles/variables';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddOutlined from '@mui/icons-material/AddOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import { ParticipantSchedule } from 'types/dbSchema/participantSchedules';
import { useForm } from 'react-hook-form';
import useErrorMessage from 'hooks/useErrorMessage';
import { useNavigate } from 'react-router-dom';

export interface ParticipantScheduleFormProps {
  participantSchedule?: ParticipantSchedule;
}

const ParticipantScheduleForm: FC<ParticipantScheduleFormProps> = ({ participantSchedule }) => {
  const navigate = useNavigate();
  const [variant] = useState<'create' | 'edit'>(participantSchedule ? 'edit' : 'create');
  const showError = useErrorMessage();
  const { control, handleSubmit, formState: { errors, isSubmitted, touchedFields }, getValues, setValue, resetField } = useForm<ParticipantSchedule>({
    defaultValues: participantSchedule || {},
    mode: 'onChange'
  });

  const onSubmit = (data: ParticipantSchedule) => {
    console.log('Form submitted:', data);
  };

  const handleCancel = () => navigate('/scheduling/participant-schedules');

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Banner>
        <MaxWidthContainer>
          <Stack flexDirection='row' gap={1} alignItems='center' minWidth='fit-content'>
            {variant === 'edit' ?
              <EditOutlinedIcon sx={{ borderRadius: '100%', border: ' 2px dashed #81D4FA', color: '#81D4FA', padding: '4px', height: '28px', width: '28px', boxSizing: 'border-box' }} />
              : <AddOutlined sx={{ borderRadius: '100%', border: ' 2px dashed #81D4FA', color: '#81D4FA', padding: '4px', height: '28px', width: '28px', boxSizing: 'border-box' }} />
            }
            <Typography variant='h5'>
              {participantSchedule ? 'Edit Participant Schedule' : 'Create Participant Schedule'}
            </Typography>
          </Stack>
          {variant === 'create' ? <CloseOutlined sx={{ cursor: 'pointer' }} onClick={handleCancel} />
            : (
              <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' maxWidth='960px'>
                <Button
                  variant='outlined'
                  sx={{ color: 'white', border: '1px solid white' }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  type="submit"
                  sx={{ color: 'white', backgroundColor: variables.colors.secondary.main, ':hover': { backgroundColor: variables.colors.secondary.darker } }}
                >
                  Update
                </Button>
              </Stack>
            )}
        </MaxWidthContainer>
      </Banner>
      <Stack padding='32px' width='100%' height='fit-content' justifyContent='center' alignItems='center' boxSizing='border-box' gap='32px' overflow='auto'>
        {/* <PersonalDetails control={control} errors={errors} />
        <ContactDetails
          control={control}
          errors={errors}
          handleSetAddress={handleSetAddress}
          resetField={resetField}
        />
        <EmploymentDetails control={control} errors={errors} />
        <AccessDetails
          control={control}
          isSubmitted={isSubmitted}
          touchedFields={touchedFields}
          setValue={setValue}
          getValues={getValues}
        /> */}
        {variant === 'create' && (
          <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' maxWidth='960px' boxSizing='border-box'>
            <Button variant='text' onClick={handleCancel}>Cancel</Button>
            <Button
              variant='contained'
              type='submit'
            >
              Create
            </Button>
          </Stack>
        )}
      </Stack>
    </StyledForm>
  );
};

const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 16px;
  background-color: ${variables.colors.primary.darker};
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  color: white;
  position: fixed;
  padding: 16px 32px;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 70px;
`;

const MaxWidthContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  max-width: 960px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  height: fit-content;
  background-color: #F3F4F6;
  margin-top: 70px;
`;

export default ParticipantScheduleForm;