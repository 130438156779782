import React, { FC } from 'react';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ParticipantSchedule } from 'types/dbSchema/participantSchedules';

export interface ViewIconProps {
  participantSchedule: ParticipantSchedule;
}

const ViewIcon: FC<ViewIconProps> = ({ participantSchedule }) => {
  const navigate = useNavigate();

  return (
    <StyledBox onClick={() => navigate(`/scheduling/participant-schedules/view/${participantSchedule.id}`)}>
      <TodayOutlinedIcon />
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export default ViewIcon;