import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import variables from 'styles/variables';
import EditStatusDropdown from './EditStatusDropdown';
import Edit from './Edit';
import Delete from './Delete';
import { priceGuideRegionOptions } from 'types/dbSchema/productAndServices';

export const Columns: GridColDef[] = [
  {
    field: 'edit',
    headerName: '',
    width: 50,
    sortable: false,
    renderCell: (params) => (
      <Edit serviceRegion={params.row} />
    ),
  },
  {
    field: 'delete',
    headerName: '',
    width: 50,
    sortable: false,
    renderCell: (params) => (
      <Delete serviceRegion={params.row} />
    ),
  },
  {
    field: 'name',
    headerName: 'Service Region Name',
    width: 200,
    renderCell: (params) => (
      <Typography variant='subtitle2'>
        {params.value}
      </Typography>
    ),
    sortable: false,
  },
  {
    field: 'pricingRegion',
    headerName: 'Price Region',
    width: 200,
    renderCell: (params) => (
      <Typography variant='body2' fontSize='14px' fontWeight='400' color={variables.colors.text.secondary}>
        {priceGuideRegionOptions.find((option) => option.value === params.value)?.label || ''}
      </Typography>
    ),
    sortable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (params) => (
      <EditStatusDropdown serviceRegion={params.row} />
    ),
  },
];