import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import DataTableControls from './Controls';
import DataTable from 'components/common/DataTable';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { GridRowId } from '@mui/x-data-grid';
import { Columns } from './Controls/Columns';
import { SelectedFilter } from 'components/common/FilterWithDropdown/types';
import { PaginationModel } from 'components/common/DataTable/types';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import { useNavigate, useParams } from 'react-router-dom';
import useUserOrganisations from 'hooks/useUserOrganisations';
import useInvalidateData from 'hooks/useInvalidateData';
import { paginationModelDefault } from 'utils/helpers';
import { placeholderParticipantSchedule } from 'utils/helpers/getDBData';

const ParticipantSchedulesDataTable: FC = () => {
  const navigate = useNavigate();
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([]);
  const [searchText, setSearchText] = useState('');
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>(paginationModelDefault);
  const [organisations] = useUserOrganisations();
  const { id } = useParams();
  useInvalidateData('participant-schedules');

  const { isPending, isError, data, isFetching, } = useQuery({
    queryKey: ['participant-schedules', paginationModel, selectedFilters],
    queryFn: () => placeholderParticipantSchedule,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    enabled: organisations.length > 0,
    staleTime: cachingInvalidation.generalNotes,
  });

  return (
    <Stack height='100%' width='100%' sx={{ backgroundColor: 'white' }}>
      <DataTableControls
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        searchText={searchText}
        setSearchText={setSearchText}
        selectedRowIds={selectedRowIds}
      />
      <DataTable
        data={{ items: data || [], totalCount: 1, pageNumber: paginationModel.page, pageSize: paginationModel.pageSize }}
        columns={Columns}
        setSelectedRowIds={setSelectedRowIds}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        isPending={isPending || isFetching}
        isError={isError}
        onNoDataTitle='No Participation Schedules found'
        dataTableName='participant-schedules'
        checkboxSelection={false}
        paginationMode='server'
        onNoData={() => navigate(`/participants/${id}/general-notes/new`)}
      />
    </Stack>
  );
};

export default ParticipantSchedulesDataTable;