import React from 'react';
import { LinearProgress } from '@mui/material';
import ServiceProviderForm from 'components/features/Forms/ServiceProviderForm';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useParams } from 'react-router-dom';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import { getServiceProviderByID } from 'api/organisations/serviceproviders';
import { useAtom } from 'jotai';
import { organisationStateAtom } from 'atoms/organisationsAtom';

const EditServiceProvidersPage = () => {
  const { id } = useParams();
  const [organisations] = useUserOrganisations();
  const [organisationState] = useAtom(organisationStateAtom);

  // If these aren't loaded, we'll skip the fetch until they are
  const canFetch = organisationState.hasBeenFetched && organisations.length > 0;

  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({
    getByIDFunc: () => getServiceProviderByID(organisations[0].organisation.globalId, id as string),
    skip: !canFetch,
  });

  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;

  return (
    <ServiceProviderForm serviceProvider={currentDataPoint} />
  );
};

export default EditServiceProvidersPage;