import React, { useCallback } from 'react';
import { Stack } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { LeaveType, } from 'types/dbSchema/availabilitySettings';
import LeaveTypeForm from 'components/features/Forms/LeaveTypeForm';

const EditIcon = ({ leaveType, filteredColorList }: { leaveType: LeaveType, filteredColorList: string[] }) => {
  const [, setModalState] = useAtom(modalStateAtom);

  // If the leave type is not editable, return null
  if (!leaveType || !leaveType.isEditable) return null;

  // Function to open/close the modal.
  // The filteredColorList is passed to the form to filter out the colors that are already in use
  // We also filter the color of the current row to be able to select it
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: LeaveTypeForm,
      props: {
        type: 'edit',
        leaveType: leaveType,
        filterOut: filteredColorList.filter(color => color !== leaveType.color)
      }
    }));
  }, [setModalState, leaveType]);

  return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'pointer' }} onClick={handleModalOpen}>
      <EditOutlinedIcon sx={{ color: '#9CA3AF' }} />
    </Stack>
  );
};

export default EditIcon;