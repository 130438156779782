import React from 'react';
import { Stack } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { ServiceProviderPosition } from 'types/dbSchema/serviceProviderSettings';
import ServiceProviderPositionForm from 'components/features/Forms/ServiceProviderPositionForm';

const EditIcon = ({ serviceProviderPosition }: { serviceProviderPosition: ServiceProviderPosition }) => {
  const [modalState, setModalState] = useAtom(modalStateAtom);
  if (!serviceProviderPosition || !serviceProviderPosition.isEditable) return null;

  const handleModalOpen = () => {
    setModalState({
      ...modalState,
      status: 'open',
      position: 'center',
      component: ServiceProviderPositionForm,
      props: {
        type: 'edit',
        serviceProviderPosition,
      }
    });
  };

  return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'pointer' }} onClick={handleModalOpen}>
      <EditOutlinedIcon sx={{ color: '#9CA3AF' }} />
    </Stack>
  );
};

export default EditIcon;