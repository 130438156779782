import { Data } from "components/common/DataTable/types";
import { PaginationBase } from "types";
export interface ProductAndService {
  id: string;
  fileVersion: string;
  effectiveDate: string;
  supportItemNumber: string;
  supportItemName: string;
  registrationGroupNumber: string;
  registrationGroupName: string;
  supportCategoryNumber: number;
  supportCategoryNumberPACE: number;
  supportCategoryName: string;
  supportCategoryNamePACE: string;
  unit: string;
  quote: string;
  startDate: string;
  endDate: string;
  nonFaceToFaceSupportProvision: string;
  providerTravel: string;
  shortNoticeCancellations: string;
  ndiaRequestedReports: string;
  irregularSILSupports: string;
  type: string;
  prices: PartialRate[];
  isActive: boolean;
}

export interface PartialRate {
  amount: number;
  pricingRegion: TPriceGuideRegion;
  region: TRegion;
}

export interface Rate {
  fileVersion: string;
  effectiveDate: string;
  unit: TUnitOfMeasure;
  amount: number;
  region: TRegion;
  pricingRegion: TPriceGuideRegion;
}

export type TUnitOfMeasure = 'a' | 'd' | 'e' | 'h' | 'mon' | 'wk' | 'yr' | string;

export type TPriceGuideRegion = 'QLDNSWVIC' | 'NTSAWATAS' | 'Remote' | 'VeryRemote';
export type TRegion = 'QLD' | 'NSW' | 'VIC' | 'NT' | 'SA' | 'WA' | 'TAS' | 'ACT' | 'Remote' | 'VeryRemote';
export const priceGuideRegionOptions: { value: TPriceGuideRegion; label: string }[] = [
  { value: 'QLDNSWVIC', label: 'QLD/NSW/VIC' },
  { value: 'NTSAWATAS', label: 'NT/SA/WA/TAS' },
  { value: 'Remote', label: 'Remote' },
  { value: 'VeryRemote', label: 'Very Remote' },
];

export interface ProductAndServiceByRegion {
  id: string;
  supportItemNumber: string;
  supportItemName: string;
  registrationGroupNumber: string;
  registrationGroupName: string;
  supportCategoryNumber: number;
  supportCategoryNumberPACE: number;
  supportCategoryName: string;
  supportCategoryNamePACE: string;
  quote: string;
  startDate: string;
  endDate: string;
  nonFaceToFaceSupportProvision: string;
  providerTravel: string;
  shortNoticeCancellations: string;
  ndiaRequestedReports: string;
  irregularSILSupports: string;
  type: string;
  isActive: boolean;
  pricingRegion: string;
  pagination: Data;
}

export type ProductAndServiceList = PaginationBase<ProductAndService>;