import React from "react";
import { updateServiceProvider } from "api/organisations/serviceproviders";
import { invalidateDataAtom } from "atoms/invalidateDataAtom";
import IsActiveDropdown from "components/common/IsActiveDropdown";
import useErrorMessage from "hooks/useErrorMessage";
import useUserOrganisations from "hooks/useUserOrganisations";
import { useAtom } from "jotai";
import { ServiceProvider } from "types/dbSchema/serviceProviders";
import { ServiceType } from "types/dbSchema/schedulingSettings";

// Handles the status dropdown in the DataTable
const EditStatus = ({ serviceProvider }: { serviceProvider: ServiceProvider }) => {
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const showError = useErrorMessage();

  const handleEdit = async (status: boolean) => {
    try {
      await updateServiceProvider(
        organisations[0].organisation.globalId,
        {
          ...serviceProvider,
          serviceTypes: (serviceProvider.serviceTypes as ServiceType[]).map((serviceType) => serviceType.id),
          isActive: status
        }
      );
      setInvalidateData((prev) => ({ ...prev, 'service-providers': true }));
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <IsActiveDropdown
      isActive={serviceProvider.isActive}
      setIsActive={handleEdit}
    />
  );
};

export default EditStatus;