import React, { useCallback } from 'react';
import { Stack } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { ServiceType } from 'types/dbSchema/schedulingSettings';
import ServiceTypeForm from 'components/features/Forms/ServiceTypeForm';

const EditIcon = ({ serviceType, filteredColorList }: { serviceType: ServiceType, filteredColorList: string[] }) => {
  const [, setModalState] = useAtom(modalStateAtom);
  if (!serviceType || !serviceType.isEditable) return null;

  // Function to open/close the modal.
  // The filteredColorList is passed to the form to filter out the colors that are already in use
  // We also filter the color of the current row to be able to select it
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: ServiceTypeForm,
      props: {
        type: 'edit',
        serviceType: serviceType,
        filterOut: filteredColorList.filter(color => color !== serviceType.color)
      }
    }));
  }, [setModalState, serviceType]);

  return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'pointer' }} onClick={handleModalOpen}>
      <EditOutlinedIcon sx={{ color: '#9CA3AF' }} />
    </Stack>
  );
};

export default EditIcon;