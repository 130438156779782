import React, { FC } from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { Button } from '@mui/material';
import ServiceProviderNoteForm from 'components/features/Forms/ServiceProviderNoteForm';
import { useParams } from 'react-router-dom';

const Actions: FC = () => {
  const [modalState, setModalState] = useAtom(modalStateAtom);
  const { id } = useParams();

  const handleModalOpen = () => {
    setModalState({
      ...modalState,
      status: 'open',
      position: 'right',
      component: ServiceProviderNoteForm,
      props: {
        type: 'create',
        serviceProviderId: id as string,
      }
    });
  };

  return (
    <Button color='primary' variant='contained' onClick={() => handleModalOpen()} startIcon={<AddOutlinedIcon />} sx={{ minWidth: 'fit-content', height: '100%' }}>
      Add Note
    </Button>
  );
};

export default Actions;