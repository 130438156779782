import { Button, Stack, Typography } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { GeneralNote } from 'types/dbSchema/generalNotes';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import variables from 'styles/variables';
import styled from 'styled-components';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import GeneralNoteForm from '../../Forms/GeneralNoteForm';
import { closeModalAtom } from 'atoms/modalAtom';
import Label from 'components/common/Label';
import { SubmittedState } from 'components/common/InputFields/DragAndDropFileUpload/FileStates';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { format, parseISO } from 'date-fns';
import { GeneralNoteType } from 'types/dbSchema/participantSettings';
import { NameAndID } from 'types/dbSchema/userProfiles';
import { downloadMultipleAttachments } from 'utils/helpers';
import { deleteGeneralNoteById } from 'api/organisations/participants/generalnotes';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';

export interface GeneralNoteViewProps {
  generalNote: GeneralNote;
}

const ViewGeneralNotes: FC<GeneralNoteViewProps> = ({ generalNote }) => {
  const [modalState, setModalState] = useAtom(modalStateAtom);
  const [, closeModal] = useAtom(closeModalAtom);
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);

  const handleModalOpen = useCallback(() => {
    setModalState({
      ...modalState,
      status: 'open',
      position: 'right',
      component: GeneralNoteForm,
      disableClickAway: true,
      props: {
        type: 'edit',
        generalNote,
      }
    });
  }, [generalNote, setModalState]);

  const downloadAllFiles = () => downloadMultipleAttachments(generalNote.attachments);

  const handleDelete = async () => {
    const participantId = (generalNote.participant as NameAndID).id;
    if (!participantId || !generalNote.id) return;

    await deleteGeneralNoteById(
      organisations[0].organisation.globalId,
      participantId,
      generalNote.id
    );
    setInvalidateData((prev) => ({ ...prev, 'general-notes': true }));
    closeModal();
  };

  return (
    <Stack padding='0 32px'>
      <Stack flexDirection='row' justifyContent='space-between' padding='32px 0'>
        <Typography variant='h6'>Note details</Typography>
        <Stack flexDirection='row' justifyContent='center' alignItems='center' gap='16px'>
          <Button color='primary' variant='outlined' onClick={handleModalOpen} startIcon={<EditOutlinedIcon />}>
            Edit
          </Button>
          <StyledDeleteOutlinedIcon
            onClick={handleDelete}
            style={{ color: '#4B5563' }}
          />
          <CloseOutlinedIcon onClick={closeModal} sx={{ cursor: 'pointer' }} style={{ color: '#4B5563' }} />
        </Stack>
      </Stack>
      <Stack gap='32px' alignItems='flex-start'>
        <Stack gap='8px'>
          <Stack gap='16px' flexDirection='row' alignItems='center'>
            <Typography variant='body2' color={variables.colors.text.secondary} width='120px'>Created by</Typography>
            <Typography variant='body2'>{generalNote?.createdBy && (generalNote.createdBy as NameAndID).fullName}</Typography>
          </Stack>
          <Stack gap='16px' flexDirection='row' alignItems='center'>
            <Typography variant='body2' color={variables.colors.text.secondary} width='120px'>Created on</Typography>
            <Typography variant='body2'>
              {generalNote.createdDate ? format(parseISO(generalNote.createdDate), 'dd/MM/yyyy, hh:mm a') : ''}
            </Typography>
          </Stack>
          <Stack gap='16px' flexDirection='row' alignItems='center'>
            <Typography variant='body2' color={variables.colors.text.secondary} width='120px'>Last modified</Typography>
            <Typography variant='body2'>
              {generalNote.lastModified ? format(parseISO(generalNote.lastModified), 'dd/MM/yyyy, hh:mm a') : ''}
            </Typography>
          </Stack>
          <Stack gap='16px' flexDirection='row' alignItems='center'>
            <Typography variant='body2' color={variables.colors.text.secondary} width='120px'>Note type</Typography>
            <Label text={(generalNote.generalNoteType as GeneralNoteType).name} />
          </Stack>
        </Stack>
        <Stack gap='8px'>
          <Typography variant='subtitle2'>{generalNote.subject}</Typography>
          <Typography variant='body2'>{generalNote.note}</Typography>
        </Stack>
        <Stack gap='16px' width='100%'>
          <Stack flexDirection='row' justifyContent='space-between'>
            <Typography variant='subtitle2'>Documents</Typography>
            <Button color='primary' variant='outlined' onClick={downloadAllFiles} startIcon={<FileDownloadOutlinedIcon />} disabled={!generalNote.attachments?.length}>
              Download all
            </Button>
          </Stack>
          <Stack gap='8px' width='100%'>
            {generalNote.attachments?.map((file, index) => (
              <SubmittedState key={index} uploadedFile={file} />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const StyledDeleteOutlinedIcon = styled(DeleteOutlinedIcon)`
  color: ${variables.colors.text.secondary};
  cursor: pointer;
  font-size: 20px;
  transition: color 240ms;
  margin: auto;
  &:hover {
    color: ${variables.colors.text.main};
  }
`;

export default ViewGeneralNotes;