import React, { FC } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ParticipantSchedule } from 'types/dbSchema/participantSchedules';

export interface EditIconProps {
  participantSchedule: ParticipantSchedule;
}

const EditIcon: FC<EditIconProps> = ({ participantSchedule }) => {
  const navigate = useNavigate();

  return (
    <StyledBox onClick={() => navigate(`/scheduling/participant-schedules/edit/${participantSchedule.id}`)}>
      <EditOutlinedIcon />
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export default EditIcon;