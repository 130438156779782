import React, { useCallback } from 'react';
import { Stack } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { ServiceProviderNoteType } from 'types/dbSchema/serviceProviderSettings';
import ServiceProviderNoteTypeForm from 'components/features/Forms/ServiceProviderNoteTypeForm';

const EditIcon = ({ serviceProviderNoteType }: { serviceProviderNoteType: ServiceProviderNoteType }) => {
  const [, setModalState] = useAtom(modalStateAtom);

  // If the service provider note type is not editable, return null
  if (!serviceProviderNoteType || !serviceProviderNoteType.isEditable) return null;

  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: ServiceProviderNoteTypeForm,
      props: {
        type: 'edit',
        serviceProviderNoteType,
      }
    }));
  }, [setModalState, serviceProviderNoteType]);

  return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'pointer' }} onClick={handleModalOpen}>
      <EditOutlinedIcon sx={{ color: '#9CA3AF' }} />
    </Stack>
  );
};

export default EditIcon;