import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';

export interface StatusProps {
  backgroundColor: string
  fontColor: string
  label: string
}

const Status: FC<StatusProps> = ({ backgroundColor, fontColor, label }) => {
  return (
    <Box padding='8px 16px'>
      <Box sx={{ backgroundColor, padding: '2px 4px', borderRadius: '4px', width: 'fit-content', cursor: 'pointer', position: 'relative' }}>
        <Typography variant='body2' fontWeight='600' color={fontColor}>
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

export default Status;