import React from 'react';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { SelectedFilter } from 'components/common/FilterWithDropdown/types';
import { Address, DBAttachment, TTimezone, TTimezoneValue, timezoneOptions } from 'types';
import { RgbaColor } from 'react-colorful';
import { differenceInYears } from 'date-fns';
import { PaginationModel } from 'components/common/DataTable/types';

export const paginationModelDefault: PaginationModel = { page: 0, pageSize: 20 };

export const phoneValidationRegex = /^\(?((0|\+61)(2|4|3|7|8))?\)?( |-)?[0-9]{2}( |-)?[0-9]{2}( |-)?[0-9]{1}( |-)?[0-9]{3}$/;
export const mobileValidationRegex = /^(?:\+61|0)4\d{8}$/;
export const emailValidationRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|^[a-zA-Z0-9._%+-]+@[IPv6:[0-9a-fA-F:]+]$/;

export function formatABNForUI(abn: string) {
  if (!abn || typeof abn !== 'string') throw new Error('Invalid ABN. Input must be a string.');

  // Ensure input contains only digits
  const cleanABN = abn.replace(/\D/g, '');

  if (cleanABN.length !== 11) throw new Error('Invalid ABN. It must contain exactly 11 digits.');

  // Format into groups: XX XXX XXX XXX
  return cleanABN.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4');
}


// This function is used to capitalize the first letter of a string
export const capitalize = (string: string) => {
  return string[0].toUpperCase() + string.slice(1);
};

// This function replaces all space with - and makes the string lowercase
export const kebabFormat = (string: string) => {
  return string.replace(/\s/g, '-').toLowerCase();
};

// This function converts camelCase to separated words
export const camelCaseToSeparatedWords = (input: string) => {
  const result = input.replace(/([A-Z])/g, ' $1').trim();
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const isValidUUID = (uuid: string) => {
  const regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return regex.test(uuid);
};

export const isValidABN = (input: string) => {
  if (!input) return false;
  // Remove whitespace and hyphens
  const cleanedInput = input.replace(/\s|-/g, '');

  // Check if the cleaned input has exactly 11 digits
  if (!cleanedInput.match(/^\d{11}$/)) {
    return false;
  }

  // Apply the ABN validation algorithm
  const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
  let sum = 0;

  for (let i = 0; i < cleanedInput.length; i++) {
    let digit = parseInt(cleanedInput[i], 10);
    // Subtract 1 from the first digit according to the validation algorithm
    if (i === 0) {
      digit -= 1;
    }

    sum += digit * weights[i];
  }

  // The sum modulo 89 should be 0 for a valid ABN
  return sum % 89 === 0;
};

export const handleExport = async (
  exportFunction: () => Promise<Blob>,
  dataTableName: string,
) => {
  try {
    const response = await exportFunction();

    // Create a Blob from response
    const csvBlob = new Blob([response], { type: 'text/csv' });

    // Create a temporary object URL
    const downloadUrl = window.URL.createObjectURL(csvBlob);

    // Create a hidden link element, set `href` to your blob, and "click" it
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `${dataTableName}-${new Date().toISOString()}.csv`);
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);

  } catch (error) {
    console.error('Error exporting users:', error);
  }
};

export const getCurrentAge = (dateOfBirth: string): number => {
  return differenceInYears(new Date(), new Date(dateOfBirth));
};

export const getFormattedDate = (date: Date | null) => {
  if (!date || isNaN(date.getTime())) return ''; // Check for null or invalid date
  return date.toISOString().split('T')[0]; // Format the date as 'YYYY-MM-DD'
};

export const formatISODate = (isoDateString: string) => {
  const date = new Date(isoDateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const formatISODateTime = (isoDateString: string) => {
  if (!isoDateString) return '';

  return `${new Date(isoDateString).toLocaleDateString('en-AU')} ${new Date(isoDateString).toLocaleTimeString('en-AU')}`;
};

export function isPastDate(isoDateString: string) {
  const date = new Date(isoDateString);
  const now = new Date();
  return date < now;
}

export const getNextDayOfWeek = (dayOfWeek: number) => {
  const today = new Date();
  const resultDate = new Date(today);
  resultDate.setDate(today.getDate() + ((7 + dayOfWeek - today.getDay()) % 7 || 7));
  return resultDate.toISOString();
};

export function createFilterString(filters: SelectedFilter[] | null) {
  if (!filters) return '';

  // Sort filters alphabetically by field name
  // We need to do this because of caching the return is based on key values, with filter string being on of them
  const sortedFilters = [...filters].sort((a, b) => a.field.localeCompare(b.field));

  // Initialize an array to hold the formatted strings
  const filterStrings: string[] = [];

  // Iterate over each sorted filter object
  sortedFilters.forEach(filter => {
    const { field, valuesSelected } = filter;

    // Sort the valuesSelected array alphabetically
    const sortedValues = [...valuesSelected].sort();

    // Format the string for each field and its values
    const formattedString = `[${field}=[${sortedValues.map(value => `'${value}'`).join(', ')}]]`;

    // Add the formatted string to the array
    filterStrings.push(formattedString);
  });

  // Join the array into a single string with commas separating each filter
  return filterStrings.join(', ');
}

export const rgbaToHex = ({ r, g, b, a }: { r: number; g: number; b: number; a: number }): string => {
  const toHex = (num: number): string => num.toString(16).padStart(2, '0');
  const alpha = Math.round(a * 255);
  return `#${toHex(r)}${toHex(g)}${toHex(b)}${toHex(alpha)}`;
};

export const hexToRgba = (hex: string, opacity?: number): RgbaColor => {
  let r = 0, g = 0, b = 0, a = opacity || 1;

  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  } else if (hex.length === 9) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
    a = parseInt(hex[7] + hex[8], 16) / 255;
  }

  return { r, g, b, a };
};

export function formatAddress(address: Address) {
  const { streetAddress, suburb, state, country } = address;

  // Format the address string
  const formattedAddress = `${streetAddress}, ${suburb} ${state}, ${country}`;

  return formattedAddress;
}

export const getTimezoneLabel = (value: TTimezoneValue): TTimezone | undefined => {
  const option = timezoneOptions.find(option => option.value === value);
  return option ? option.label : undefined;
};

const fileIconMap: { [key: string]: string } = {
  avi: '/assets/fileIcons/AVI.svg',
  csv: '/assets/fileIcons/CSV.svg',
  doc: '/assets/fileIcons/DOC.svg',
  docx: '/assets/fileIcons/DOCX.svg',
  gif: '/assets/fileIcons/GIF.svg',
  img: '/assets/fileIcons/IMG.svg',
  jpeg: '/assets/fileIcons/JPEG.svg',
  jpg: '/assets/fileIcons/JPG.svg',
  mkv: '/assets/fileIcons/MKV.svg',
  mp3: '/assets/fileIcons/MP3.svg',
  mp4: '/assets/fileIcons/MP4.svg',
  ogg: '/assets/fileIcons/OGG.svg',
  pdf: '/assets/fileIcons/PDF.svg',
  png: '/assets/fileIcons/PNG.svg',
  ppt: '/assets/fileIcons/PPT.svg',
  pptx: '/assets/fileIcons/PPTX.svg',
  rar: '/assets/fileIcons/RAR.svg',
  txt: '/assets/fileIcons/TXT.svg',
  wav: '/assets/fileIcons/WAV.svg',
  webp: '/assets/fileIcons/WEBP.svg',
  xls: '/assets/fileIcons/XLS.svg',
  xlsx: '/assets/fileIcons/XLSX.svg',
  zip: '/assets/fileIcons/ZIP.svg',
};

export const getFileIcon = (fileType: string) => {
  if (!fileType) return <UploadFileOutlinedIcon />;
  for (const key in fileIconMap) {
    if (fileType.includes(key)) return <img src={fileIconMap[key]} alt={`${key}-icon`} />;
  }
  return <UploadFileOutlinedIcon />;
};

export const downloadAttachment = (file: DBAttachment) => {
  const a = document.createElement('a');
  a.href = file.blobUrl;
  a.download = file.fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  // Delay the revocation of the object URL
  setTimeout(() => {
    window.URL.revokeObjectURL(file.blobUrl);
  }, 5000); // Delay for 1 second
};

export const downloadImportTemplate = (templateName: string) => {
  let templatePath = '';
  switch (templateName) {
    case 'user-profiles':
      templatePath = '/assets/importTemplates/user-profiles_template.xlsx';
      break;
    case 'external-contacts':
      templatePath = '/assets/importTemplates/external-contacts_template.xlsx';
      break;
    case 'participants':
      templatePath = '/assets/importTemplates/participants_template.xlsx';
      break;
    case 'service-providers':
      templatePath = '/assets/importTemplates/service-providers_template.xlsx';
      break;
    default:
      console.error('Invalid template name:', templateName);
      break;
  }

  const a = document.createElement('a');
  a.href = templatePath;
  a.download = `${templateName}_template`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export function downloadMultipleAttachments(files: DBAttachment[]) {
  function downloadNext(i: number) {
    try {
      if (i >= files.length) return;

      const a = document.createElement('a');
      a.href = files[i].blobUrl;
      a.target = '_parent';

      // Use a.download if available, it prevents plugins from opening.
      if ('download' in a) a.download = files[i].fileName;

      // Add a to the doc for click to work.
      (document.body || document.documentElement).appendChild(a);

      // The click method is supported by most browsers.
      if (a.click) a.click();

      // Delete the temporary link.
      a.parentNode?.removeChild(a);

      // Download the next file with a small timeout. The timeout is necessary
      // for IE, which will otherwise only download the first file.
    } catch (e) {
      console.log('Error downloading file:', e);
    } finally {
      setTimeout(function () {
        downloadNext(i + 1);
      }, 500);
    }
  }

  // Initiate the first download
  downloadNext(0);
}

// TODO: Remove this function when backend updates handling of timezone offsets
export function extractTimezoneOffset(dateString: string): string | null {
  // Regular expression to match the timezone offset at the end of the date string
  const offsetRegex = /([+-]\d{2}):?(\d{2})?$/;
  const match = dateString.match(offsetRegex);
  if (match) {
    return match[0]; // This will return something like '+10:00'
  }
  return null;
}

// TODO: Remove this function when backend updates handling of timezone offsets
export function normalizeOffset(offset: string | null): string | undefined {
  if (!offset) return undefined;
  // Remove colon for easier processing
  const cleanOffset = offset.replace(':', '');

  const sign = cleanOffset.startsWith('+') ? '+' : '-';
  const hours = cleanOffset.substr(1, 2);
  const minutes = cleanOffset.substr(3, 2);

  if (minutes === '00') {
    return `${sign}${parseInt(hours, 10)}`;
  } else {
    return `${sign}${parseInt(hours, 10)}:${minutes}`;
  }
}

export function camelCaseToTitleCase(str: string): string {
  // Insert a space before all uppercase letters and capitalize the first letter of each word
  const result = str
    .replace(/([A-Z])/g, ' $1') // Add space before capital letters
    .replace(/^./, (char) => char.toUpperCase()) // Capitalize the first character
    .trim(); // Remove any leading/trailing spaces

  // Capitalize each word
  return result
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}