import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import CustomBreadcrumbs from 'components/common/Breadcrumbs';
import styled from 'styled-components';
import SearchInput from 'components/common/InputFields/SearchInput';
// import FilterWithDropdown from 'components/common/FilterWithDropdown';
// import { IFilter } from 'components/common/FilterWithDropdown/types';
// import variables from 'styles/variables';
import { ControlsProps } from '../../types';
// import { productAndServicesFilters } from 'utils/filters';

const Controls: FC<ControlsProps> = ({
  // selectedFilters,
  // setSelectedFilters,
  searchText,
  setSearchText,
  // selectedRowIds,
}) => {
  // const [filterOpen, setFilterOpen] = useState('');

  return (
    <StyledStack>
      <CustomBreadcrumbs />
      <Stack justifyContent='space-between' height='100%' gap='16px'>
        <Stack gap='16px' flexDirection='row' justifyContent='space-between'>
          <Typography variant='h4' minWidth='fit-content'>
            Products and Services
          </Typography>
          <Stack flexDirection='row' gap='16px' >
            <SearchInput searchValue={searchText} setSearchValue={setSearchText} placeholderText='Search Products and Services' />
          </Stack>
        </Stack>
      </Stack>
      {/* <Stack paddingTop='24px'>
        <Filters>
          {productAndServicesFilters.map((filter: IFilter, index: number) => (
            <FilterWithDropdown
              key={index}
              filter={filter}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              filterOpen={filterOpen}
              setFilterOpen={setFilterOpen}
            />
          ))}
          {selectedFilters.length > 0 &&
            <ResetFiltersBox onClick={() => setSelectedFilters([])}>
              <Typography variant='body2' fontWeight='600' color='secondary' >
                Reset filters
              </Typography>
            </ResetFiltersBox>
          }
        </Filters>
      </Stack> */}
    </StyledStack>
  );
};

const StyledStack = styled(Stack)`
  height: fit-content;
  padding: 16px 24px 32px 24px;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
`;

// const Filters = styled(Stack)`
//   width: 100%;
//   flex-direction: row;
//   justify-content: flex-start;
//   flex-wrap: wrap;
//   align-items: center;
//   gap: 16px;
// `;

// const ResetFiltersBox = styled(Stack)`
//   cursor: pointer;
//   padding: 8px;
//   border-left: 1px solid ${variables.colors.secondary.outlineBorder};
//   min-width: fit-content;
// `;

export default Controls;