import React, { FC } from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Action } from 'components/common/ActionsDropdown/types';
import ActionsDropdown from 'components/common/ActionsDropdown';
import { ActionsProps } from 'types';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { handleExport } from 'utils/helpers';
import ImportModal from 'components/features/ImportModal';
import ButtonWithDropdown from 'components/common/ButtonWithDropdown';
import { closeModalAtom } from 'atoms/modalAtom';
import { useHasPermission } from 'hooks/useHasPermission';
import { permissions } from 'types/dbSchema/permissions';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { exportParticipants } from 'api/organisations/participants';

const Actions: FC<ActionsProps> = ({ selectedRowIds, dataTableName }) => {
  const navigate = useNavigate();
  const [modalState, setModalState] = useAtom(modalStateAtom);
  const [, closeModal] = useAtom(closeModalAtom);
  const [organisations] = useUserOrganisations();

  const exportData = async () => {
    try {
      handleExport(
        () => exportParticipants(organisations[0].organisation.globalId, selectedRowIds),
        'participants',
      );
    } catch (error) {
      console.error('There was an error exporting users', error);
      throw error;
    }
  };

  const handleImportModalOpen = () => {
    if (modalState.status === 'closed') {
      setModalState({
        ...modalState,
        status: 'open',
        position: 'right',
        component: ImportModal,
        props: {
          dataTableName,
        },
      });
    } else {
      closeModal();
    }
  };

  const actions: Action[] = [
    {
      icon: AddOutlinedIcon,
      isDisabled: !useHasPermission([permissions.CREATE_PARTICIPANT_DETAILS, permissions.CREATE_PARTICIPANTS]),
      label: 'Add New Participant',
      function: () => {
        navigate('/participant-centre/participants/create');
      },
    },
    {
      icon: PostAddOutlinedIcon,
      label: 'Import from CSV',
      function: () => {
        handleImportModalOpen();
      },
    },
    {
      icon: FileUploadOutlinedIcon,
      // label: `Export ${selectedRowIds.length > 0 ? 'Selected' : 'All'}`,
      label: 'Export All',
      function: () => {
        exportData();
      },
    }
  ];

  return (
    <ButtonWithDropdown
      color='primary'
      label='Actions'
      isDisabled={false}
      dropdownContents={
        <ActionsDropdown selectedRowIds={selectedRowIds} dataTableName={dataTableName} actions={actions} />
      }
    />
  );
};

export default Actions;