import React, { FC } from 'react';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { closeModalAtom } from 'atoms/modalAtom';
import styled from 'styled-components';
import { Box } from '@mui/material';
import ViewProgressNotes from 'components/features/Views/ViewProgressNotes';
import { ProgressNote } from 'types/dbSchema/progressNotes';

export interface ViewIconProps {
  progressNote: ProgressNote;
}

const ViewIcon: FC<ViewIconProps> = ({ progressNote }) => {
  const [modalState, setModalState] = useAtom(modalStateAtom);
  const [, closeModal] = useAtom(closeModalAtom);

  const handleModalOpen = () => {
    if (modalState.status === 'closed') {
      setModalState({
        ...modalState,
        status: 'open',
        position: 'right',
        component: ViewProgressNotes,
        props: {
          progressNote,
        }
      });
    } else {
      closeModal();
    }
  };

  return (
    <StyledBox onClick={handleModalOpen}>
      <RemoveRedEyeOutlinedIcon />
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export default ViewIcon;