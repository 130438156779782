import React from "react";
import { invalidateDataAtom } from "atoms/invalidateDataAtom";
import IsActiveDropdown from "components/common/IsActiveDropdown";
import useErrorMessage from "hooks/useErrorMessage";
// import useUserOrganisations from "hooks/useUserOrganisations";
import { useAtom } from "jotai";
import { AvailabilityTemplate } from "types/dbSchema/availabilitySettings";

// TODO: Implement the updateAvailabilityTemplate function
// Handles the status dropdown in the DataTable
const EditStatusDropdown = ({ availabilityTemplate }: { availabilityTemplate: AvailabilityTemplate }) => {
  // const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const showError = useErrorMessage();

  // const handleEdit = async (status: boolean) => {
  const handleEdit = async () => {
    try {
      // await updateAvailabilityTemplate(organisations[0].organisation.globalId, { ...availabilityTemplate, isActive: status });
      setInvalidateData((prev) => ({ ...prev, 'availability-template': true }));
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <IsActiveDropdown
      isActive={availabilityTemplate.isActive}
      setIsActive={handleEdit}
    />
  );
};

export default EditStatusDropdown;