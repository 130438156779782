import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { organisationsAtom } from 'atoms';
import { userOrganisations } from 'api';
import { Organisation } from 'types/dbSchema/organisation';
import { User } from 'types/dbSchema/userProfiles';
import { useMsal } from '@azure/msal-react';
import { organisationStateAtom } from 'atoms/organisationsAtom';

export interface CurrentOrganisation {
  organisation: Organisation;
  owner: User;
}

const useUserOrganisations = (): [CurrentOrganisation[], (organisations: CurrentOrganisation[]) => void, () => Promise<void>, boolean] => {
  const { accounts } = useMsal();
  const [organisations, setOrganisations] = useAtom(organisationsAtom);
  const [hasBeenFetched, setHasBeenFetched] = useState<boolean>(false);
  const [organisationsState, setOrganisationsState] = useAtom(organisationStateAtom);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  if (accounts.length === 0) return [[], setOrganisations, async () => { }, hasBeenFetched];

  // This function allows us to manually fetch the user's organisations
  const fetchOrganisations = async () => {
    if (organisationsState.isLoading) return;

    setOrganisationsState({ ...organisationsState, isLoading: true });
    const fetchedOrganisations = await userOrganisations();

    setOrganisations(fetchedOrganisations.items);
    setOrganisationsState({ ...organisationsState, isLoading: false, hasBeenFetched: true });
  };

  // Fetch the user's organisations when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      if (organisations.length === 0) {
        await fetchOrganisations();
        setHasBeenFetched(true);
      }
    };

    fetchData(); // Call the async function within useEffect
  }, []);

  return [organisations, setOrganisations, fetchOrganisations, hasBeenFetched];
};

export default useUserOrganisations;