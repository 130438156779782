import { GeneralNote } from "types/dbSchema/generalNotes";
import { Participant } from "types/dbSchema/participants";
import { ExitReason, GeneralNoteType, ParticipantStatus, PrimaryDisability } from "types/dbSchema/participantSettings";
import { ProductAndService } from "types/dbSchema/productAndServices";
import { ProgressNote } from "types/dbSchema/progressNotes";
import { ServiceType } from "types/dbSchema/schedulingSettings";
import { SchedulingSupport } from "types/dbSchema/schedulingSupports";
import { ServiceProviderNote } from "types/dbSchema/serviceProviderNotes";
import { ServiceProvider } from "types/dbSchema/serviceProviders";
import { ServiceProviderNoteType } from "types/dbSchema/serviceProviderSettings";
import { Tag } from "types/dbSchema/serviceTeamSettings";
import { NameAndID } from "types/dbSchema/userProfiles";

export const convertParticipant = (participant: Participant): Participant => {
  return {
    ...participant,
    status: (participant.status as ParticipantStatus).id,
    // serviceRegion: (participant.serviceRegion as ServiceRegion).id,
    servicePreferences: (participant.servicePreferences as Tag[]).map((servicePreference) => servicePreference.id),
    exitReason: participant.exitReason ? (participant.exitReason as ExitReason).id : undefined,
    assignedTo: (participant.assignedTo as NameAndID).id,
    primaryDisability: participant.primaryDisability ? (participant.primaryDisability as PrimaryDisability).id : undefined,
  };
};

export const convertGeneralNote = (generalNote: GeneralNote): GeneralNote => {
  return {
    ...generalNote,
    generalNoteType: (generalNote.generalNoteType as GeneralNoteType).id,
    participant: (generalNote.participant as NameAndID),
    createdBy: (generalNote.participant as NameAndID),
  };
};

export const convertServiceProviderNote = (serviceProviderNote: ServiceProviderNote): ServiceProviderNote => {
  return {
    ...serviceProviderNote,
    noteType: (serviceProviderNote.noteType as ServiceProviderNoteType).id,
    serviceProvider: (serviceProviderNote.serviceProvider as ServiceProvider),
    createdBy: (serviceProviderNote.createdBy as NameAndID),
  };
};

export const convertProgressNote = (progressNote: ProgressNote, participantGoalId: string | null): ProgressNote => {
  return {
    ...progressNote,
    createdBy: (progressNote.createdBy as NameAndID).id,
    lastModifiedBy: (progressNote.lastModifiedBy as NameAndID).id,
    participantGoal: participantGoalId,
    attachments: null
  };
};

export const convertSchedulingSupport = (schedulingSupport: SchedulingSupport): SchedulingSupport => {
  return {
    ...schedulingSupport,
    serviceTypes: (schedulingSupport.serviceTypes as ServiceType[]).map((serviceType: ServiceType) => serviceType.id),
    supportItem: (schedulingSupport.supportItem as ProductAndService).id,
  };
};