import { Box, Stack, Typography } from '@mui/material';
import React, { useState, useCallback, useRef, FC } from 'react';
import styled from 'styled-components';
import variables from 'styles/variables';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { DragAndDropAreaProps, DragAndDropFileUploadProps, DraggingProps } from './types';
import { CompleteState, ErrorState, UploadingState } from './FileStates';

// Drag and drop file upload component, created for the CSV upload functionality
const DragAndDropFileUpload: FC<DragAndDropFileUploadProps> = ({
  multiple = false,
  currentFiles,
  setCurrentFiles,
  validFileTypes,
  onFileUploaded,
  maxTotalSize,
  maxFileSize,
}) => {
  const [dragging, setDragging] = useState(false); // Dragging state
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state for upload error - individual file errors are handled in the currentFiles state
  const dropAreaRef = useRef<HTMLDivElement>(null); // Ref for the drop area
  const dropAreaOverlayRef = useRef<HTMLDivElement>(null); // Ref for the drop area overlay
  const dragCounter = useRef(0); // Drag counter to keep track of the number of drag events (this is used to prevent flickering of the overlay)
  const fileInputRef = useRef<HTMLInputElement>(null); // Ref for the file input
  const [loadingProgress, setLoadingProgress] = useState(0); // Loading progress state - used to display a loading bar for UI/UX

  // Simulate file upload function - We use this to increment the loadingProgress for UI/UX
  const simulateUpload = (file: File) => {
    return new Promise((resolve) => {
      const uploadInterval = setInterval(() => {
        setLoadingProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(uploadInterval);
            resolve(file);
            return 100;
          }
          return prevProgress + 10;
        });
      }, 100);
    });
  };

  // File validation function
  const validateFile = useCallback(async (file: File) => {
    setIsLoading(true); // Set loading state to true
    setError(null); // Reset error state

    // Check if no file is detected, set error state and return if no file is detected
    if (!file) {
      setError('No file detected');
      setIsLoading(false);
      return;
    }

    // Check if the file is already in the current files state, set error state and return if it is
    if (currentFiles.find((currentFile) => currentFile.fileName === file.name)) {
      setError('File already uploaded');
      setIsLoading(false);
      return;
    }

    // Check if the file size is too large, set error state and return if it is
    if (file.size > maxFileSize) {
      setError(`File size too large (${(maxTotalSize / 1048576).toFixed(0)}MB)`); // Set error state if file size is too large
      setIsLoading(false);
      return;
    }

    // Check if the total file size is too large, set error state and return if it is
    if (currentFiles.reduce((acc, currentFile) => acc + currentFile.fileSize, file.size) > maxTotalSize) {
      setError(`Total file size too large (${(maxTotalSize / 1048576).toFixed(0)}MB)`);
      setIsLoading(false);
      return;
    }

    // Check if the file is supported, set error state and return if it is not
    if (!validFileTypes.includes(file.type)) {
      setError('File not supported');
      setIsLoading(false);
      return;
    }

    // Set the file state to uploading and add it to the current files state
    if (!multiple) {
      setCurrentFiles([{ file, state: 'uploading', message: 'Uploading file', fileSize: file.size, fileName: file.name, fileType: file.type }]);
    } else {
      setCurrentFiles((prevFiles) => [...prevFiles, { file, state: 'uploading', message: 'Uploading file', fileSize: file.size, fileName: file.name, fileType: file.type }]);
    }

    // Try to upload the file
    try {
      // Simulate upload
      await simulateUpload(file);

      // Update the file state to complete
      setCurrentFiles((prevFiles) => prevFiles.map((prevFile) => {
        if (prevFile.fileName === file.name) {
          return { ...prevFile, state: 'complete', message: 'File uploaded' };
        }
        return prevFile;
      }));

      // Reset the parse progress
      setLoadingProgress(0);

      // Run the new file uploaded function if it exists
      if (onFileUploaded) onFileUploaded();

    } catch (error) {
      console.error('Error processing the file', error);
    }

    // Reset loading state
    setIsLoading(false);
  }, [currentFiles, setCurrentFiles, maxFileSize, maxTotalSize, multiple, onFileUploaded, validFileTypes]);

  // Function to remove a file from the current files state
  const removeFile = useCallback((index: number) => {
    setCurrentFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  }, [currentFiles, setCurrentFiles]);

  // Function to trigger/simulate a click on the file input
  const triggerFileInputClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  // Handles the drag enter event
  const handleDragEnter = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current += 1;
    setError(null);
    if (dragCounter.current > 0) {
      setDragging(true);
    }
  }, []);

  // Handles the drag leave event
  const handleDragLeave = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current -= 1;
    setError(null);
    if (dragCounter.current === 0) {
      setDragging(false);
    }
  }, []);

  // Handles the drag over event
  const handleDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  // Handles the drop event and triggers the file upload
  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current = 0;
    setDragging(false);
    // Validate the file if it exists
    if (e.dataTransfer.files && e.dataTransfer.files[0]) validateFile(e.dataTransfer.files[0]);
  }, [validateFile]);

  return (
    <Stack gap='16px'>
      <DragAndDropArea
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        dragging={dragging}
        isLoading={isLoading}
        ref={dropAreaRef}
      >
        {dragging && (
          <DraggingOverlay dragging={dragging} ref={dropAreaOverlayRef}>
            <Typography variant='body1'>Drop the file here</Typography>
          </DraggingOverlay>
        )}
        <UploadStack dragging={dragging}>
          {isLoading ?
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Uploading...
            </Typography> :
            <>
              {currentFiles.length > 0 && !multiple ?
                <CachedOutlinedIcon sx={{ color: variables.colors.primary.main }} /> :
                <UploadFileOutlinedIcon sx={{ color: variables.colors.primary.main }} />
              }
              <Stack flexDirection='row'>
                <UploadText variant='body2' onClick={triggerFileInputClick}>
                  {currentFiles.length > 0 && !multiple ? 'Click to replace file' : 'Click to upload'}
                </UploadText>
                <input
                  type='file'
                  accept={validFileTypes.join(',')}
                  ref={fileInputRef}
                  onChange={(e) => e.target.files && validateFile(e.target.files[0])}
                  style={{ display: 'none' }}
                />
                <Typography variant='body2'>
                  &nbsp;or drag and drop
                </Typography>
              </Stack>
              {error ?
                <ErrorBox>
                  <ErrorOutlineOutlinedIcon sx={{ color: variables.colors.error.main }} />
                  <Typography variant='subtitle2' color={variables.colors.error.main}>
                    {error}
                  </Typography>
                </ErrorBox> :
                <Typography variant='body2' color={variables.colors.text.secondary} textAlign='center'>
                  {validFileTypes.join(', ')} supported
                </Typography>
              }
            </>
          }
        </UploadStack>
      </DragAndDropArea>
      {currentFiles.length > 0 &&
        <Stack>
          <Typography variant='subtitle2'>
            Uploads
          </Typography>
          <Stack gap='4px'>
            {currentFiles.map((file, index) => (
              file.state === 'uploading' ?
                <UploadingState key={index} uploadedFile={file} loadingProgress={loadingProgress} removeFile={() => removeFile(index)} /> :
                file.state === 'complete' ?
                  <CompleteState key={index} uploadedFile={file} removeFile={() => removeFile(index)} /> :
                  file.state === 'error' &&
                  <ErrorState key={index} uploadedFile={file} removeFile={() => removeFile(index)} />
            ))}
          </Stack>
        </Stack>}
    </Stack>
  );
};

const DragAndDropArea = styled(Box).withConfig({
  shouldForwardProp: (prop) => prop !== 'dragging' && prop !== 'isLoading',
}) <DragAndDropAreaProps>`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: ${({ dragging }) => dragging ? `2px dashed ${variables.colors.primary.main}` : '1px dashed  #D4A3E3'};
  position: relative;
  height: 145px;
  box-sizing: border-box;
  input {
    display: none;
  }
`;

const UploadStack = styled(Stack).withConfig({
  shouldForwardProp: (prop) => prop !== 'dragging',
}) <DraggingProps>`
  opacity: ${({ dragging }) => dragging ? 0.1 : 1};
  align-items: center;
  gap: 8px;
`;

const DraggingOverlay = styled(Box).withConfig({
  shouldForwardProp: (prop) => prop !== 'dragging',
}) <DraggingProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${variables.colors.primary.main30};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  z-index: 40;
  p {
    font-size: 24px;
    color: ${variables.colors.primary.main};
  }
`;

const UploadText = styled(Typography)`
  cursor: pointer;
  color: ${variables.colors.primary.main};
  font-weight: 600;
  &:hover{
    color: ${variables.colors.primary.darker};
    text-decoration: underline;
  }
`;

const ErrorBox = styled(Box)`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background:  #FEEBEE;
  width: 100%;
  box-sizing: border-box;
`;

export default DragAndDropFileUpload;