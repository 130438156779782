import { Button, Stack } from '@mui/material';
import styled from 'styled-components';
import React, { FC } from 'react';
import { ButtonBoxProps } from '../types';

const ButtonBox: FC<ButtonBoxProps> = ({ handleClose, handleImport, isDisabled }) => {
  return (
    <ButtonStack>
      <Button variant='outlined' color='primary' onClick={handleClose}>Cancel</Button>
      <Button variant='contained' color='primary' onClick={handleImport} disabled={isDisabled}>Import</Button>
    </ButtonStack>
  );
};

const ButtonStack = styled(Stack)`
  gap: 16px;
  justify-content: flex-end;
  flex-direction: row;
`;

export default ButtonBox;