import React from 'react';
import { useCallback } from "react";
import { useAtom } from "jotai";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { modalStateAtom } from "atoms";
import { Stack } from "@mui/material";
import variables from 'styles/variables';
import { ExternalContact } from 'types/dbSchema/externalContacts';
import DeleteModal from '../DeleteModal';

export const DeleteIcon = ({ externalContact }: { externalContact: ExternalContact }) => {
  const [, setModalState] = useAtom(modalStateAtom);

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: DeleteModal,
      props: {
        type: 'delete',
        externalContact: externalContact,
      }
    }));
  }, [setModalState]);

  const isDisabled = externalContact.linkedParticipantsCount > 0;

  if (isDisabled) return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'not-allowed' }}>
      <DeleteOutlinedIcon sx={{ color: variables.colors.icon.standard, opacity: 0.2 }} />
    </Stack>
  );

  return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'pointer' }} onClick={handleModalOpen}>
      <DeleteOutlinedIcon sx={{ color: '#C62828' }} />
    </Stack>
  );
};