import React, { useCallback } from 'react';
import { Stack } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { PayPeriod } from 'types/dbSchema/serviceTeamSettings';
import PayPeriodForm from 'components/features/Forms/PayPeriodForm';

// Renders Edit Icon for Grid column definitions - opens add/edit form in modal
const EditIcon = ({ payPeriod }: { payPeriod: PayPeriod }) => {
  const [, setModalState] = useAtom(modalStateAtom);
  if (!payPeriod || !payPeriod.isEditable) return null;

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: PayPeriodForm,
      props: {
        type: 'edit',
        payPeriod,
      }
    }));
  }, [setModalState, payPeriod]);

  return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'pointer' }} onClick={handleModalOpen}>
      <EditOutlinedIcon sx={{ color: '#9CA3AF' }} />
    </Stack>
  );
};

export default EditIcon;