import { Box, Button, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import styled from 'styled-components';
import { modalStateAtom } from 'atoms';
import { useAtom } from 'jotai';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { NoDataOverlayProps } from './types';
import { closeModalAtom } from 'atoms/modalAtom';
import ImportModal from 'components/features/ImportModal';

const NoDataOverlay: FC<NoDataOverlayProps> = ({ title, subtitle, dataTableName, onNoData, allowImport = false }) => {
  const [modalState, setModalState] = useAtom(modalStateAtom);
  const [, closeModal] = useAtom(closeModalAtom);

  const handleImportModalOpen = () => {
    if (modalState.status === 'closed') {
      setModalState({
        ...modalState,
        status: 'open',
        position: 'right',
        component: ImportModal,
        props: {
          dataTableName,
        }
      });
    } else {
      closeModal();
    }
  };

  return (
    <OverlayBox>
      <Stack gap='24px' flexDirection='column' justifyContent='center' alignItems='center'>
        <img src='/assets/images/NoDataOverlay.png' alt='No data' style={{ height: 'auto', width: '160px' }} />
        <Stack width='fit-content' justifyContent='center' alignItems='center'>
          <Typography variant='h6'>{title}</Typography>
          <Typography variant='body1'>{subtitle}</Typography>
        </Stack>
      </Stack>
      <Stack gap='16px' flexDirection='row' width='fit-content'>
        {allowImport && (
          <Button
            sx={{ textTransform: 'unset', backgroundColor: 'rgba(92, 6, 140, 0.10)' }}
            variant='text'
            onClick={handleImportModalOpen}
            data-testid='import-csv-button'
          >
            <PostAddOutlinedIcon sx={{ marginRight: '8px' }} />
            Import from CSV
          </Button>
        )}
        {onNoData && (
          <Button sx={{ textTransform: 'unset' }} variant='outlined' color='primary' onClick={() => onNoData()}>
            <AddOutlinedIcon sx={{ marginRight: '8px' }} />
            Add New Record
          </Button>
        )}
      </Stack>
    </OverlayBox>
  );
};

const OverlayBox = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
`;

export default NoDataOverlay;