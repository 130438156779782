import { OrganisationState } from 'atoms/types';
import { CurrentOrganisation } from 'hooks/useUserOrganisations';
import { atom } from 'jotai';

export const organisationsAtom = atom<CurrentOrganisation[]>([]);

// TODO: This should be combined with the organisationsAtom however, I added it in at a later stage and didn't want to refactor the existing code.
export const organisationStateAtom = atom<OrganisationState>({
  isLoading: false,
  hasBeenFetched: false,
});