import React from 'react';
import { useAtom } from 'jotai';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import { ExitReason } from 'types/dbSchema/participantSettings';
import { updateExitReason } from 'api/organisations/settings/participants/exit-reasons';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';

// For MVP 1, we are not implementing the edit functionality, so the dropdown is disabled
// I have created the component for future use - just remove this comment and the disabled prop
const EditStatusDropdown = ({ exitReason }: { exitReason: ExitReason }) => {
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const showError = useErrorMessage();

  const handleEdit = async (status: boolean) => {
    try {
      await updateExitReason(organisations[0].organisation.globalId, { ...exitReason, isActive: status });
      setInvalidateData((prev) => ({ ...prev, 'exit-reasons': true }));
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <IsActiveDropdown
      isActive={exitReason.isActive}
      setIsActive={handleEdit}
      // disabled={!exitReason.isEditable} // For MVP 1, the dropdown is disabled
      disabled
    />
  );
};

export default EditStatusDropdown;