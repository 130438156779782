import React, { FC } from 'react';
import { ActionsProps } from 'types';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

const Actions: FC<ActionsProps> = ({ selectedRowIds }) => {
  const navigate = useNavigate();
  const handleXeroExtract = () => {
    alert('Extract Xero CSV');
  };

  return (
    <Button color='primary' variant='contained' onClick={handleXeroExtract}>
      Add
    </Button>
  );
};

export default Actions;