import React, { FC, useState } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Button, Stack, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { modalStateAtom, userMessageAtom } from 'atoms';
import { Controller, useForm } from 'react-hook-form';
import { closeModalAtom } from 'atoms/modalAtom';
import { GridRowId } from '@mui/x-data-grid';
import useErrorMessage from 'hooks/useErrorMessage';
import ParticipantGoalLookupField from 'components/common/LookupInputField/ParticipantGoalLookupField';
import useUserOrganisations from 'hooks/useUserOrganisations';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { bulkLinkProgressNotesToGoalID } from 'api/organisations/participants/progressNotes';
import LinkIcon from '@mui/icons-material/Link';
import variables from 'styles/variables';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';

export interface LinkToGoalFormProps {
  selectedRowIds: GridRowId[];
  participantId: string;
}

const LinkToGoalForm: FC<LinkToGoalFormProps> = ({ selectedRowIds, participantId }) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const showError = useErrorMessage();
  const [organisations] = useUserOrganisations();
  const [isLoading, setIsLoading] = useState(false);
  const [, setUserMessage] = useAtom(userMessageAtom);
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: 'onChange', defaultValues: {
      goal: ''
    }
  });

  const showMessage = (title: string, message: string) => {
    setUserMessage({
      message,
      title,
      variant: "success",
      open: true,
      autoHideDuration: 10000, // 10 seconds
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
    });
  };

  const handleLinkToGoal = async (data: any) => {
    setIsLoading(true); // Start loading
    try {
      const organisationId = organisations[0]?.organisation?.globalId;

      if (!organisationId) throw new Error('Organisation ID is missing.');
      if (selectedRowIds.length === 0) throw new Error('No progress notes selected.');

      await bulkLinkProgressNotesToGoalID(
        organisationId,
        participantId,
        data.goal,
        selectedRowIds
      );

      setInvalidateData((prev) => ({ ...prev, 'participant-progress-notes': true }));
      showMessage(
        `${selectedRowIds.length} progress note${selectedRowIds.length > 1 ? `s have` : ` has`} been successfully linked to the selected goal.`,
        "To unlink them, visit either the progress note details or the goal details pages."
      );
      closeModal();
    } catch (error) {
      showError((error as Error).message || 'An unexpected error occurred.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <form style={{ width: '500px', padding: '32px' }} onSubmit={handleSubmit((data) => handleLinkToGoal(data))}>
        <Stack height='fit-content' gap='24px'>
          <Stack gap='12px'>
            <Typography variant='h6' fontWeight='600'>
              Link to a goal
            </Typography>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              You have selected {selectedRowIds.length} progress note{selectedRowIds.length > 1 ? 's' : ''}. Please select the goal to link them to.
            </Typography>
          </Stack>
          <Controller
            name="goal"
            control={control}
            rules={{ required: 'Goal is required' }}
            render={({ field }) =>
              <ParticipantGoalLookupField
                {...field}
                id='goal'
                label='Select a goal'
                validationError={errors.goal ? true : false}
                errorText={errors.goal?.message}
                isMandatory
                placeholder='Select'
                participantId={participantId}
              />
            }
          />
          <Stack sx={{ backgroundColor: '#F0FAFF', padding: '16px', borderRadius: '4px', borderLeft: `4px solid ${variables.colors.info.main}` }}>
            <Typography fontWeight='500' fontSize='12px'>
              The linked progress notes will be visible in the goal details page.
            </Typography>
          </Stack>
          <Stack flexDirection='row' justifyContent='flex-end' gap='16px' padding='16px 0 0 0'>
            <Button variant='outlined' color='primary' onClick={closeModal}>
              Cancel
            </Button>
            <Button type='submit' variant='contained' color='primary' startIcon={<LinkIcon />}>
              Link to goal
            </Button>
          </Stack>
        </Stack>
      </form>
    </>
  );
};

export interface EditProps {
  selectedRowId: string;
}

export const Edit: FC<EditProps> = ({ selectedRowId }) => {
  const [modalState, setModalState] = useAtom(modalStateAtom);

  const handleModalOpen = () => {
    setModalState({
      ...modalState,
      status: 'open',
      position: 'center',
      component: LinkToGoalForm,
      props: {
        selectedRowIds: [selectedRowId],
      }
    });
  };

  return (
    <Stack width='100%' height='100%' justifyContent='center' alignItems='center'>
      <EditOutlinedIcon
        sx={{ color: '#9CA3AF', cursor: 'pointer' }}
        onClick={handleModalOpen}
      />
    </Stack>
  );
};

export default LinkToGoalForm;