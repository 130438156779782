import { PaginationBase } from "types";
import { ServiceType } from "./schedulingSettings";

export interface SchedulingSupport {
  id: string;
  supportItem: SchedulingSupportSupportItem | string;
  // productCode: string; // TODO: Auto populate based on service?
  deliveredDuring: ServiceTimeslot;
  startTime: string;
  endTime: string;
  totalHours: number; // TODO: Auto populate based on startTime and endTime?
  isActive: boolean;
  serviceTypes: ServiceType[] | string[];
}

export interface SchedulingSupportSupportItem {
  id: string,
  supportItemNumber: string,
  supportItemName: string
}

export type SchedulingSupportList = PaginationBase<SchedulingSupport>;

export type ServiceTimeslot = 'Any Day' | 'Weekday Day' | 'Weekday Evening' | 'Weekday Night' | 'Saturday' | 'Sunday' | 'Public Holiday' | 'Overnight';
export const serviceTimeslots: ServiceTimeslot[] = ['Any Day', 'Overnight', 'Public Holiday', 'Saturday', 'Sunday', 'Weekday Day', 'Weekday Evening', 'Weekday Night'];
export const schedulingSupportStatuses: string[] = ['Active', 'Inactive'];

export interface ITimeslot {
  startTime: string;
  endTime: string;
  totalHours: number;
  editable: boolean;
}

export const serviceTimeslotsObj: Record<ServiceTimeslot, ITimeslot> = {
  'Any Day': {
    startTime: '00:00',
    endTime: '23:59',
    totalHours: 24,
    editable: false
  },
  'Weekday Day': {
    startTime: '06:00',
    endTime: '20:00',
    totalHours: 14,
    editable: true
  },
  'Weekday Evening': {
    startTime: '18:00',
    endTime: '23:59',
    totalHours: 6,
    editable: true
  },
  'Weekday Night': {
    startTime: '20:00',
    endTime: '23:59',
    totalHours: 4,
    editable: true
  },
  'Saturday': {
    startTime: '00:00',
    endTime: '23:59',
    totalHours: 24,
    editable: false
  },
  'Sunday': {
    startTime: '00:00',
    endTime: '23:59',
    totalHours: 24,
    editable: false
  },
  'Public Holiday': {
    startTime: '00:00',
    endTime: '23:59',
    totalHours: 24,
    editable: false
  },
  'Overnight': {
    startTime: '22:00',
    endTime: '06:00',
    totalHours: 8,
    editable: false
  },
};