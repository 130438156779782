import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import DataTableControls from './Controls';
import DataTable from 'components/common/DataTable';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { GridRowId } from '@mui/x-data-grid';
import { Columns } from './Controls/Columns';
import { SelectedFilter } from 'components/common/FilterWithDropdown/types';
import { Data, PaginationModel } from 'components/common/DataTable/types';
import { Participant } from 'types/dbSchema/participants';
import { useNavigate, useParams } from 'react-router-dom';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import useUserOrganisations from 'hooks/useUserOrganisations';
import useInvalidateData from 'hooks/useInvalidateData';
import { listLinkedContacts } from 'api/organisations/participants';
import { paginationModelDefault } from 'utils/helpers';
import useDebounceValue from 'hooks/useDebounceValue';

const ParticipantContactsDataTable: FC = () => {
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([]);
  const [searchText, setSearchText] = useState('');
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>(paginationModelDefault);
  const navigate = useNavigate();
  const { id } = useParams();
  const [organisations] = useUserOrganisations();
  useInvalidateData('participant-contacts');

  const handleViewContact = () => navigate(`/participant-centre/participants/view/${id}/link-contact`);

  const debouncedSearchText = useDebounceValue(searchText, 500);

  const { isPending, isError, data, isFetching, } = useQuery({
    queryKey: ['participant-contacts', id, paginationModel, selectedFilters, debouncedSearchText],
    queryFn: () => listLinkedContacts(
      organisations[0].organisation.globalId, id as string,
      null,
      paginationModel.page + 1,
      paginationModel.pageSize,
      debouncedSearchText
    ),
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.participantContacts,
  });

  return (
    <Stack height='100%' width='100%' sx={{ backgroundColor: 'white' }}>
      <DataTableControls
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        searchText={searchText}
        setSearchText={setSearchText}
        selectedRowIds={selectedRowIds}
      />
      <DataTable
        data={data as Data}
        columns={Columns}
        setSelectedRowIds={setSelectedRowIds}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        isPending={isPending || isFetching}
        isError={isError}
        onNoDataTitle='No Participant Contacts found'
        dataTableName='participant-contacts'
        checkboxSelection={false}
        paginationMode='server'
        onNoData={handleViewContact}
      />
    </Stack>
  );
};

export default ParticipantContactsDataTable;