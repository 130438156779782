import React, { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import PersonalDetails from './PersonalDetails';
import { Button, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { AddOutlined, CloseOutlined } from '@mui/icons-material';
import variables from 'styles/variables';
import { useLocation, useNavigate } from 'react-router-dom';
import ContactDetails from './ContactDetails';
import EmploymentDetails from './EmploymentDetails';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { closeModalAtom } from 'atoms/modalAtom';
import AccessDetails from './AccessDetails';
import { Address } from 'types';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import { NameAndID, SystemUserStatus, User } from 'types/dbSchema/userProfiles';
import { createUserProfile, updateUserById } from 'api/organisations/users';
import useUserOrganisations from 'hooks/useUserOrganisations';
import useErrorMessage from 'hooks/useErrorMessage';
import { UserPosition } from 'types/dbSchema/userPositions';
import { RelationshipType } from 'types/dbSchema/participantSettings';
import { ServiceRegion } from 'types/dbSchema/serviceRegions';
import { extractTimezoneOffset, normalizeOffset } from 'utils/helpers';
import { UserRole } from 'types/dbSchema/userRoles';

export interface UserProfileFormProps {
  userProfile?: User;
}

export interface ModalState {
  isOpen: boolean;
  type: ModalType;
}

export type ModalType = 'createUser' | 'createAndInviteUser' | 'update' | 'cancel' | '';

const UserProfileForm: FC<UserProfileFormProps> = ({ userProfile }) => {
  const [variant, setVariant] = useState<'create' | 'edit'>('create');
  const location = useLocation();
  const [modalState, setModalState] = useAtom(modalStateAtom);
  const { control, handleSubmit, formState: { errors, isSubmitted, touchedFields }, getValues, setValue, resetField } = useForm<User>({
    defaultValues: userProfile ? {
      ...userProfile,
      manager: (userProfile?.manager as NameAndID)?.id as string || undefined,
      position: (userProfile?.position as UserPosition)?.id as string || undefined,
      nextOfKinRelationship: (userProfile?.nextOfKinRelationship as RelationshipType)?.id as string || undefined,
      serviceRegion: (userProfile?.serviceRegion as ServiceRegion)?.id as string || undefined,
      userRoles: (userProfile?.userRoles as UserRole[])?.map((role) => role.id) || [],
      // TODO: Remove this when backend is updated
      timezone: userProfile?.timezone ? normalizeOffset(extractTimezoneOffset(userProfile?.timezone)) : undefined,
    } : {
      isActive: true,
      userRoles: [],
    },
    mode: 'onChange'
  });

  const handleSetAddress = (values: Address) => {
    setValue('address', values, { shouldValidate: true });
  };

  // Check if the current location is an edit page
  useEffect(() => {
    if (location.pathname.includes('edit')) {
      setVariant('edit');
    } else {
      setVariant('create');
    }
  }, [location]);

  // We are not submitting the form here, we trigger the validation checks.
  // If validation passes, open a modal which will handle the submission
  // This will allow us to show a confirmation modal before submitting the form
  const onSubmit = () => {
    if (Object.keys(errors).length !== 0) return;
    setModalState({
      ...modalState,
      status: 'open',
      position: 'center',
      component: UserProfileModalContent,
      props: {
        ...modalState.props,
        formData: getValues(),
        variant: variant,
      },
    });
  };

  const handleCancel = () => {
    setModalState({
      ...modalState,
      status: 'open',
      position: 'center',
      component: UserProfileModalContent,
      props: {
        type: 'cancel',
        variant: variant,
        formData: getValues(),
      },
    });
  };

  const handleModalStateChange = (type: ModalType) => {
    setModalState({
      ...modalState,
      props: {
        type: type,
      },
    });
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Banner>
        <MaxWidthContainer>
          <Stack flexDirection='row' gap={1} alignItems='center' minWidth='fit-content'>
            {variant === 'edit' ?
              <EditOutlinedIcon sx={{ borderRadius: '100%', border: ' 2px dashed #81D4FA', color: '#81D4FA', padding: '4px', height: '28px', width: '28px', boxSizing: 'border-box' }} />
              : <AddOutlined sx={{ borderRadius: '100%', border: ' 2px dashed #81D4FA', color: '#81D4FA', padding: '4px', height: '28px', width: '28px', boxSizing: 'border-box' }} />
            }
            <Typography variant='h5'>
              {userProfile ? 'Edit User Details' : 'Create New User'}
            </Typography>
          </Stack>
          {variant === 'create' ? <CloseOutlined sx={{ cursor: 'pointer' }} onClick={handleCancel} />
            : (
              <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' maxWidth='960px'>
                <Button variant='outlined' sx={{ color: 'white', border: '1px solid white' }} onClick={handleCancel}>Cancel</Button>
                <Button variant='contained' type="submit" sx={{ color: 'white', backgroundColor: variables.colors.secondary.main, ':hover': { backgroundColor: variables.colors.secondary.darker } }} onClick={() => handleModalStateChange('update')}>Update</Button>
              </Stack>
            )}
        </MaxWidthContainer>
      </Banner>
      <Stack padding='32px' width='100%' height='fit-content' justifyContent='center' alignItems='center' boxSizing='border-box' gap='32px' overflow='auto'>
        <PersonalDetails control={control} errors={errors} />
        <ContactDetails
          control={control}
          errors={errors}
          handleSetAddress={handleSetAddress}
          resetField={resetField}
        />
        <EmploymentDetails control={control} errors={errors} />
        <AccessDetails
          control={control}
          isSubmitted={isSubmitted}
          touchedFields={touchedFields}
          setValue={setValue}
          getValues={getValues}
        />
        {variant === 'create' && (
          <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' maxWidth='960px' boxSizing='border-box'>
            <Button variant='text' onClick={handleCancel}>Cancel</Button>
            <Button
              variant='contained'
              type='submit'
              sx={{ backgroundColor: 'rgba(166, 0, 255, 0.1)', color: variables.colors.primary.darker, ':hover': { backgroundColor: 'rgba(166, 0, 255, 0.4)' } }}
              onClick={() => handleModalStateChange('createUser')}
            >
              Create User
            </Button>
            <Button
              variant='contained'
              type='submit'
              onClick={() => handleModalStateChange('createAndInviteUser')}
            >
              Create & Invite
            </Button>
          </Stack>
        )}
      </Stack>
    </StyledForm>
  );
};

export interface UserProfileModalContentProps {
  formData: User;
  variant: 'create' | 'edit';
}

const UserProfileModalContent: FC<UserProfileModalContentProps> = ({ formData, variant }) => {
  const navigate = useNavigate();
  const [modalState] = useAtom(modalStateAtom);
  const [, closeModal] = useAtom(closeModalAtom);
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const [organisations] = useUserOrganisations();
  const showError = useErrorMessage();

  const handleCreate = async () => {
    try {
      // Set the status to 'Pending Invite'
      formData.status = 'PendingInvite';
      // Set the isInvite flag to false, preventing the invitation email from being sent
      formData.isInvite = false;
      // TODO: Remove this when backend is updated
      formData.profilePicture = "https://via.placeholder.com/150";

      // Create the user profile
      await createUserProfile(organisations[0].organisation.globalId, formData);

      // Refetch the user profiles to update the list
      setInvalidateData((prev) => ({ ...prev, 'user-profiles': true }));

      // Close the modal and navigate to the user profiles page
      closeModal();
      navigate('/settings/user-management/user-profiles');
    } catch (error) {
      showError((error as Error).message);
    }
  };

  const handleCreateAndInvite = async () => {
    try {
      // Set the status to 'Invite Sent'
      formData.status = 'InviteSent';
      // Trigger the invitation email
      formData.isInvite = true;
      // TODO: Remove this when backend is updated
      formData.profilePicture = "https://via.placeholder.com/150";
      // Create the user profile
      await createUserProfile(organisations[0].organisation.globalId, formData);
      // Refetch the user profiles to update the list
      setInvalidateData((prev) => ({ ...prev, 'user-profiles': true }));
      // Close the modal and navigate to the user profiles page
      closeModal();
      navigate('/settings/user-management/user-profiles');

    } catch (error) {
      showError((error as Error).message);
    }
  };

  // Update the user profile
  const handleUpdate = async () => {
    try {
      // Remove any whitespace from the status
      formData.status = formData.status.replace(/\s/g, '') as SystemUserStatus;
      // Update the user profile
      await updateUserById(organisations[0].organisation.globalId, formData.id, formData);
      // Refetch the user profiles to update the list
      setInvalidateData((prev) => ({ ...prev, 'user-profiles': true }));
      // Close the modal and navigate to the user profiles page
      closeModal();
      navigate('/settings/user-management/user-profiles');

    } catch (error) {
      showError((error as Error).message);
    }
  };

  const handleLeave = () => {
    closeModal();
    if (variant === 'edit') {
      navigate(`/settings/user-management/user-profiles/view/${formData.id}`);
    } else {
      navigate('/settings/user-management/user-profiles');
    }
  };

  const handleSubmit = useMemo(() => {
    switch (modalState.props.type) {
      case 'createUser':
        return handleCreate;
      case 'createAndInviteUser':
        return handleCreateAndInvite;
      case 'update':
        return handleUpdate;
      default:
        return closeModal;
    }
  }, [modalState.props.type]);

  return (
    <Stack width='500px' boxSizing='border-box'>
      <Stack flexDirection='row' gap='24px' width='100%' padding='32px' boxSizing='border-box'>
        <Stack>
          <Stack sx={{ backgroundColor: modalState.props.type === 'cancel' ? variables.colors.error.subtle : variables.colors.primary.subtle, borderRadius: '100%', width: '40px', height: '40px', justifyContent: 'center', alignItems: 'center' }} >
            {modalState.props.type === 'createUser' || modalState.props.type === 'createAndInviteUser'
              ? <PersonAddAlt1OutlinedIcon sx={{ color: variables.colors.primary.darker, width: '24px' }} />
              : modalState.props.type === 'update'
                ? <EditOutlinedIcon sx={{ color: variables.colors.primary.darker, width: '24px' }} />
                : <CancelOutlinedIcon sx={{ color: variables.colors.error.main, width: '24px' }} />
            }
          </Stack>
        </Stack>
        <Stack gap='12px'>
          <Typography variant='h6'>
            {modalState.props.type === 'createUser'
              ? 'Create new user?'
              : modalState.props.type === 'createAndInviteUser'
                ? 'Create and invite new user?'
                : modalState.props.type === 'update'
                  ? 'Update user?'
                  : 'Are you sure you want to leave?'
            }
          </Typography>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            {modalState.props.type === 'createUser'
              ? 'Please confirm to create new user. Remember that the user will still need to be sent an invitation to be able to sign up to the system.'
              : modalState.props.type === 'createAndInviteUser'
                ? 'New user will be created an email will be sent inviting them to sign up to Skymo. Please confirm to create new user.'
                : modalState.props.type === 'update'
                  ? 'Please confirm to update user details.'
                  : 'Any information added will be lost.'
            }
          </Typography>
        </Stack>
      </Stack>
      <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' maxWidth='960px' padding='16px 32px' boxSizing='border-box' sx={{ backgroundColor: variables.colors.lightNeutral.subtle, borderRadius: '0 0 12px 12px' }} >
        {modalState.props.type === 'cancel' ? (
          <>
            <Button variant='text' onClick={closeModal} sx={{ border: `1px solid ${variables.colors.lightNeutral.darker}` }}>Stay</Button>
            <Button variant='contained' color='error' onClick={handleLeave}>Leave</Button>
          </>
        ) : (
          <>
            <Button variant='text' onClick={closeModal}>Cancel</Button>
            <Button variant='contained' onClick={handleSubmit}>Confirm</Button>
          </>
        )}
      </Stack>
    </Stack>
  );
};

const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 16px;
  background-color: ${variables.colors.primary.darker};
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  color: white;
  position: fixed;
  padding: 16px 32px;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 70px;
`;

const MaxWidthContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  max-width: 960px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  height: fit-content;
  background-color: #F3F4F6;
  margin-top: 70px;
`;

export default UserProfileForm;