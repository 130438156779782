import React from 'react';
import { useAtom } from 'jotai';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import { ServiceType } from 'types/dbSchema/schedulingSettings';
import { updateServiceType } from 'api/organisations/settings/scheduling/service-types';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';

const EditStatusDropdown = ({ serviceType }: { serviceType: ServiceType }) => {
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const showError = useErrorMessage();

  const handleEdit = async (status: boolean) => {
    try {
      await updateServiceType(organisations[0].organisation.globalId, { ...serviceType, isActive: status });
      setInvalidateData((prev) => ({ ...prev, 'service-type': true }));
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <IsActiveDropdown
      isActive={serviceType.isActive}
      setIsActive={handleEdit}
      disabled={!serviceType.isEditable}
    />
  );
};

export default EditStatusDropdown;