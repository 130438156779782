import React from "react";
import { Stack } from "@mui/material";
import { modalStateAtom } from "atoms";
import { useAtom } from "jotai";
import { useCallback } from "react";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import variables from "styles/variables";
import ServiceProviderContactForm from "components/features/Forms/ServiceProviderContactForm";
import { ServiceProviderContact } from "types/dbSchema/serviceProviders";
import { useParams } from "react-router-dom";

// Renders Edit Icon for Grid column definitions - opens add/edit form in modal
export const EditIcon = ({ serviceProviderContact }: { serviceProviderContact: ServiceProviderContact }) => {
  const [, setModalState] = useAtom(modalStateAtom);
  const { id } = useParams();

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: ServiceProviderContactForm,
      props: {
        type: 'edit',
        serviceProviderContact: serviceProviderContact,
        serviceProviderId: id,
      }
    }));
  }, [setModalState, serviceProviderContact]);

  return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'pointer' }} onClick={handleModalOpen}>
      <EditOutlinedIcon sx={{ color: variables.colors.icon.standard }} />
    </Stack>
  );
};