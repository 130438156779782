import React from "react";
import { updateTimeSheetDeclinedReason } from "api/organisations/settings/service-team/time-sheet-declined-reasons";
import { invalidateDataAtom } from "atoms/invalidateDataAtom";
import IsActiveDropdown from "components/common/IsActiveDropdown";
import useErrorMessage from "hooks/useErrorMessage";
import useUserOrganisations from "hooks/useUserOrganisations";
import { useAtom } from "jotai";
import { TimeSheetDeclinedReason } from "types/dbSchema/serviceTeamSettings";

// Handles the status dropdown in the DataTable
// For MVP 1, we are not implementing the edit functionality, so the dropdown is disabled
// I have created the component for future use - just remove this comment and the disabled prop
const EditStatusDropdown = ({ timeSheetDeclinedReason }: { timeSheetDeclinedReason: TimeSheetDeclinedReason }) => {
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const showError = useErrorMessage();

  const handleEdit = async (status: boolean) => {
    try {
      await updateTimeSheetDeclinedReason(organisations[0].organisation.globalId, { ...timeSheetDeclinedReason, isActive: status });
      setInvalidateData((prev) => ({ ...prev, 'time-sheet-declined-reasons': true }));
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <IsActiveDropdown
      isActive={timeSheetDeclinedReason.isActive}
      setIsActive={handleEdit}
      // disabled={!timeSheetDeclinedReason.isEditable} // For MVP 1, the dropdown is disabled
      disabled
    />
  );
};

export default EditStatusDropdown;