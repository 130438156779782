import React, { FC } from 'react';
import { LinearProgress } from '@mui/material';
import ViewParticipantContact from 'components/features/Views/ViewParticipant/Contacts/ViewParticipantContact';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useParams } from 'react-router-dom';
import { getParticipantsParticipantContactByID } from 'api/organisations/participants/participantcontacts';
import { useAtom } from 'jotai';
import { organisationStateAtom } from 'atoms/organisationsAtom';

const ViewParticipantContactPage: FC = () => {
  const { id, contactId } = useParams();
  const [organisations] = useUserOrganisations();
  const [organisationState] = useAtom(organisationStateAtom);

  // If these aren't loaded, we'll skip the fetch until they are
  const canFetch = organisationState.hasBeenFetched && organisations.length > 0;

  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({
    getByIDFunc: () => getParticipantsParticipantContactByID(organisations[0].organisation.globalId, contactId as string, id as string),
    skip: !canFetch,
  });

  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint || !id || !contactId) return null;

  return <ViewParticipantContact participantId={id} participantAndContactLink={currentDataPoint} contactId={contactId} />;
};

export default ViewParticipantContactPage;