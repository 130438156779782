import React, { FC } from 'react';
import { useAtom } from "jotai";
import { Box, Button, Stack, Typography } from "@mui/material";
import { ExternalContact } from 'types/dbSchema/externalContacts';
import { deleteExternalContact } from 'api/organisations/externalcontacts';
import useUserOrganisations from 'hooks/useUserOrganisations';
import useErrorMessage from 'hooks/useErrorMessage';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import { useNavigate } from 'react-router-dom';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import styled from 'styled-components';
import variables from 'styles/variables';
import { closeModalAtom } from 'atoms/modalAtom';

export interface DeleteModalProps {
  externalContact: ExternalContact;
}

const DeleteModal: FC<DeleteModalProps> = ({ externalContact }) => {
  const [organisations] = useUserOrganisations();
  const showError = useErrorMessage();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const navigate = useNavigate();
  const [, closeModal] = useAtom(closeModalAtom);

  const handleDelete = async () => {
    try {
      if (!externalContact.id) return showError('External Contact ID not found');
      await deleteExternalContact(organisations[0].organisation.globalId, externalContact.id);
      setInvalidateData((prev) => ({ ...prev, 'external-contacts': true }));
      navigate('/participant-centre/contacts-console');
      closeModal();
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <Stack alignItems="center" width='500px'>
      <Stack flexDirection="row" alignItems="center" gap='24px' sx={{ padding: '32px' }}>
        <Box sx={{ borderRadius: '100%', background: '#FECDD2', padding: '8px', height: '40px', width: '40px', display: 'flex', alignSelf: 'flex-start', boxSizing: 'border-box' }}>
          <DeleteOutlinedIcon color='error' />
        </Box>
        <Stack gap='16px'>
          <Typography variant='h6'>
            Are you sure you want to delete this Contact?
          </Typography>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            This action cannot be reversed.
          </Typography>
        </Stack>
      </Stack>
      <ButtonStack>
        <Button variant='outlined' onClick={closeModal}>Cancel</Button>
        <Button variant='contained' color='error' onClick={handleDelete}>Delete</Button>
      </ButtonStack>
    </Stack>
  );
};

const ButtonStack = styled(Stack)`
  flex-direction: row;
  width: 100%;
  padding: 16px 32px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 0px 0px 12px 12px;
  background: #F9FAFB;
  box-sizing: border-box;
`;

export default DeleteModal;