import { Box, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import styled from 'styled-components';
import variables from 'styles/variables';
import { TooltipContentsProps, TooltipProps } from './types';

const Tooltip: FC<TooltipProps> = ({ toolTipTitle, toolTipText, toolTipTriggerComponent, position = 'left' }) => {
  return (
    <TriggerWrapper data-testid="tooltip">
      <Box height='16px' width='16px' position='relative' minWidth='16px'>
        {toolTipTriggerComponent}
      </Box>
      <TooltipContents position={position} data-testid="tooltip-contents">
        <ToolTipTitle data-testid="tooltip-title">
          {toolTipTitle}
        </ToolTipTitle>
        <ToolTipText data-testid="tooltip-text">
          {toolTipText}
        </ToolTipText>
      </TooltipContents>
    </TriggerWrapper>
  );
};

const TriggerWrapper = styled(Box)`
  position: relative;
  display: inline-block;
  cursor: help;

  &:hover > div {
    visibility: visible;
    opacity: 1;
  }
`;

const TooltipContents = styled(Stack).withConfig({
  shouldForwardProp: (prop) => prop !== 'position',
}) <TooltipContentsProps>`
  visibility: hidden;
  opacity: 0;
  background-color: white;
  color: black;
  text-align: left;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  top: calc(100% + 8px);
   ${({ position }) => position === 'right' ? 'right: 0' : 'left: 0'};
  transition: opacity 0.3s;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px -4px 6px -2px rgba(16, 24, 40, 0.03);
  z-index: 100000;
  width: 300px;
`;

const ToolTipTitle = styled(Typography)`
  color: #1F2937;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
    word-wrap: normal;
  white-space: normal;
  overflow-wrap: normal;
`;

const ToolTipText = styled(Stack)`
  color: ${variables.colors.text.secondary};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  word-wrap: normal;
  white-space: normal;
  overflow-wrap: normal;
`;

export default Tooltip;