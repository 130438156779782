import React from 'react';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import { LinearProgress } from '@mui/material';
import { getOrganisationDetails } from 'api';
import OrganisationSettings from 'components/features/OrganisationSettings';
import { useAtom } from 'jotai';
import { organisationStateAtom } from 'atoms/organisationsAtom';

const ViewOrganisationSettingsPage = () => {
  const [organisations] = useUserOrganisations();
  const [organisationState] = useAtom(organisationStateAtom);

  // If these aren't loaded, we'll skip the fetch until they are
  const canFetch = organisationState.hasBeenFetched && organisations.length > 0;

  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({
    getByIDFunc: () => getOrganisationDetails(organisations[0].organisation.globalId),
    skip: !canFetch,
  });

  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;

  return <OrganisationSettings organisationDetails={currentDataPoint} />;
};

export default ViewOrganisationSettingsPage;