import React, { FC } from 'react';
import { LinearProgress } from '@mui/material';
import UserProfileForm from 'components/features/Forms/UserProfileForm';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import { getUserById } from 'api/organisations/users';
import { useParams } from 'react-router-dom';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useAtom } from 'jotai';
import { organisationStateAtom } from 'atoms/organisationsAtom';

const EditUserProfilePage: FC = () => {
  const { id } = useParams();
  const [organisations] = useUserOrganisations();
  const [organisationState] = useAtom(organisationStateAtom);

  // If these aren't loaded, we'll skip the fetch until they are
  const canFetch = organisationState.hasBeenFetched && organisations.length > 0;

  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({
    getByIDFunc: () => getUserById(organisations[0].organisation.globalId, id as string),
    skip: !canFetch
  });

  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;

  return <UserProfileForm userProfile={currentDataPoint} />;
};

export default EditUserProfilePage;