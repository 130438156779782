import React, { FC } from 'react';
import { LinearProgress } from '@mui/material';
import ReviewGoal from 'components/features/Views/ViewParticipantGoal/GoalDetails/ReviewGoal';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useParams } from 'react-router-dom';
import { getParticipantGoalById } from 'api/organisations/participants/goals';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import { useAtom } from 'jotai';
import { organisationStateAtom } from 'atoms/organisationsAtom';

const ReviewParticipantGoalPage: FC = () => {
  const { id, goalId } = useParams();
  const [organisations] = useUserOrganisations();
  const [organisationState] = useAtom(organisationStateAtom);

  // If these aren't loaded, we'll skip the fetch until they are
  const canFetch = organisationState.hasBeenFetched && organisations.length > 0;

  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({
    getByIDFunc: () => getParticipantGoalById(organisations[0].organisation.globalId, id as string, goalId as string),
    skip: !canFetch,
  });

  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;


  return <ReviewGoal participantGoal={currentDataPoint} />;
};

export default ReviewParticipantGoalPage;