import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { SchedulingSupport } from 'types/dbSchema/schedulingSupports';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import SchedulingSupportsForm from 'components/features/Forms/SchedulingSupportsForm';

export interface EditProps {
  schedulingSupport: SchedulingSupport;
}

// TODO: Implement the Edit component
const Edit: FC<EditProps> = ({ schedulingSupport }) => {
  const [, setModalState] = useAtom(modalStateAtom);

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: SchedulingSupportsForm,
      props: {
        schedulingSupport,
        type: 'edit',
      },
    }));
  }, [setModalState, schedulingSupport]);

  return (
    <StyledBox onClick={handleModalOpen}>
      <EditOutlined sx={{ color: '#9CA3AF' }} />
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export default Edit;