import { Box, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import variables from 'styles/variables';
import { useNavigate } from 'react-router-dom';
import { UserProfileCellProps } from 'types/dbSchema/userProfiles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const UserProfileCell: FC<UserProfileCellProps> = ({ userProfile, displayOnly, nameOnly }) => {
  const navigate = useNavigate();

  const onProfileClick = () => {
    navigate(`/settings/user-management/user-profiles/view/${userProfile.id}`);
  };

  return (
    <Stack flexDirection='row' gap='16px' justifyContent='flex-start' alignItems='center' height='100%' width='fit-content' sx={{ cursor: 'pointer' }} onClick={onProfileClick}>
      {!displayOnly && <EditOutlinedIcon sx={{ color: '#9CA3AF', cursor: 'pointer' }} />}
      {userProfile.profilePicture ? (
        <Box height='100%' width='fit-content' display='flex' alignItems='center'>
          <img src={userProfile.profilePicture} alt="profile" style={{ width: '42px', height: '42px', borderRadius: '32px', objectFit: 'cover' }} />
        </Box>
      ) : (
        <Stack height='100%' width='fit-content' display='flex' alignItems='center' bgcolor={variables.colors.primary.darker} sx={{ width: '32px', height: '32px', borderRadius: '32px' }} >
          <Typography variant='body2' color='white' fontWeight='600' textAlign='center' lineHeight='32px'>
            {userProfile.fullName.firstName.charAt(0).toUpperCase()}
            {userProfile.fullName.lastName.charAt(0).toUpperCase()}
          </Typography>
        </Stack>
      )}
      <Stack>
        <Typography
          variant='subtitle2'
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '200px'
          }}
        >
          {`${userProfile.fullName.firstName} ${userProfile.fullName.lastName}`}
        </Typography>
        {!nameOnly && <Typography
          variant='body2'
          color={variables.colors.text.secondary}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '200px'
          }}
        >
          {userProfile.workEmail}
        </Typography>}
      </Stack>
    </Stack>
  );
};

export default UserProfileCell;