import { GridRowId } from "@mui/x-data-grid";
import axiosInstance from "api/config";
import axios from "axios";
import { SelectedFilter } from "components/common/FilterLookup/types";
import { ExternalContact, ExternalContactList } from "types/dbSchema/externalContacts";
import { createFilterString } from "utils/helpers";

// Returns a list of external contacts for the given organisation ID
export const listExternalContacts = async (
  organisationId: string,
  filters: SelectedFilter[] | null,
  page?: number,
  pageSize?: number,
  searchText?: string
): Promise<ExternalContactList> => {
  try {
    if (!organisationId) throw new Error('Organisation ID is required');


    // Create a formatted filter string
    let formattedFilters = '';
    if (filters && filters.length > 0) {
      formattedFilters = createFilterString(filters);
    }

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/externalcontacts`, {
      params: {
        filters: formattedFilters,
        page,
        pageSize,
        searchText
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing external contacts for the organisation', error);
    throw error;
  }
};

// Returns a external contact by ID
export const getExternalContactByID = async (organisationId: string, externalContactId: string): Promise<ExternalContact> => {
  try {
    if (!externalContactId || !organisationId) throw new Error('Organisation ID and ExternalContact ID are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/externalcontacts/${externalContactId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the external contact', error);
    throw error;
  }
};

export interface CreateExternalContactResponse {
  success: boolean;
  message: string;
  externalContactId: string | null;
}

// Create a external contact
// This is a unique catch block that handles the error response from the server if it is a duplicate error
export const createExternalContact = async (organisationId: string, data: ExternalContact) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const resp = await axiosInstance.post(`/api/organisations/${organisationId}/externalcontacts/newcontact`, data);
    return resp.data;

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      // This will access the actual error response sent by the server
      console.error('There was an error creating the external contact', error.response.data);
      return error.response.data;
    } else {
      // Handle other unexpected errors
      console.error('Unexpected error', error);
      throw error;
    }
  }
};

// Update a external contact
export const updateExternalContact = async (organisationId: string, data: ExternalContact) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.put(`/api/organisations/${organisationId}/externalcontacts/${data.id}`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the external contact', error);
    throw error;
  }
};

// Delete a external contact
export const deleteExternalContact = async (organisationId: string, externalContactId: string) => {
  try {
    if (!organisationId) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.delete(`/api/organisations/${organisationId}/externalcontacts/${externalContactId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error deleting the external contact', error);
    throw error;
  }
};

export const importExternalContacts = async (organisationId: string, createdBy: string, data: any) => {
  try {
    if (!organisationId) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.post(`/api/organisations/${organisationId}/externalcontacts/import?createdBy=${createdBy}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('There was an error importing the external contacts', error);
    throw error;
  }
};

export const exportExternalContacts = async (organisationId: string, selectedRowIds?: GridRowId[]) => {
  try {
    if (!organisationId) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/externalcontacts/export`, {
      responseType: 'blob',
    });
    return response.data;
  } catch (error) {
    console.error('There was an error exporting the external contacts', error);
    throw error;
  }
};