import React, { FC } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { Participant } from 'types/dbSchema/participants';
import ChangeLog from 'components/common/ChangeLog';
import variables from 'styles/variables';
import { camelCaseToSeparatedWords, getCurrentAge } from 'utils/helpers';
import { Link, useNavigate } from 'react-router-dom';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Line from 'components/common/Line';
import LineHeader from 'components/common/LineHeader';
import { aboriginalOrTorresStraitIslanderOptions, pronounOptions } from 'types';
import { useHasPermission } from 'hooks/useHasPermission';
import { NameAndID } from 'types/dbSchema/userProfiles';
import { format } from 'date-fns';
import { permissions } from 'types/dbSchema/permissions';
import { ServiceRegion } from 'types/dbSchema/serviceRegions';

const DetailsOverview: FC<{ participant: Participant }> = ({ participant }) => {
  const navigate = useNavigate();

  return (
    <DetailsOverviewStack>
      <Stack flexDirection={'row'} justifyContent='space-between' width='100%'>
        <Typography variant='h6'>
          Participant Details
        </Typography>
        <Button
          variant='contained'
          color='primary'
          startIcon={<EditOutlinedIcon />}
          disabled={!useHasPermission([permissions.MODIFY_PARTICIPANT_DETAILS])}
          onClick={() => navigate(`/participant-centre/participants/edit/${participant.id}`)}
        >
          Edit
        </Button>
      </Stack>
      <Details participant={participant} arrayLabel='personalDetails' />
      <Details participant={participant} arrayLabel='locationDetails' />
      <Details participant={participant} arrayLabel='fundingDetails' />
      <ChangeLog changeLogData={participant.changeLog} />
    </DetailsOverviewStack>
  );
};

const Details: FC<{ participant: Participant; arrayLabel: string }> = ({ participant, arrayLabel }) => {
  // Retrieve structured data specific to the participant.
  const data = participantArray(participant);

  // Access the list of lines associated with the given category (e.g., personalDetails).
  // This keeps typescript happy
  const lines = data[arrayLabel as keyof typeof data];

  return (
    <StyledStack>
      <LineHeader>
        <Typography variant='subtitle2'>
          {camelCaseToSeparatedWords(arrayLabel)}
        </Typography>
      </LineHeader>
      {lines && lines.map((line: ILine, index) => (
        <Line key={index} noBottomBorder={index === lines.length - 1}>
          <Stack width={line.left.label == '' ? '0%' : line.right.label == '' ? '100%' : '50%'}>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              {line.left.label}
            </Typography>
            {/* Values handle their own styling */}
            {line.left.value}
          </Stack>
          <Stack width={line.right.label == '' ? '0%' : line.left.label == '' ? '100%' : '50%'}>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              {line.right.label}
            </Typography>
            {/* Values handle their own styling */}
            {line.right.value}
          </Stack>
        </Line>
      ))}
    </StyledStack>
  );
};

const DetailsOverviewStack = styled(Stack)`
  display: flex;
  padding: 24px 16px 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  width: 100%;
  min-width: 400px;
  box-sizing: border-box;
  height: fit-content;
  @media (max-width: 1050px) {
    width: 50%;
    min-width: unset;
  }
`;

const StyledStack = styled(Stack)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
`;

// To save on hardcoding the same thing over and over, I've create a function that takes in a participant
// and returns an array of objects with left and right values, and individual styling for each line.
const participantArray = (participant: Participant) => ({
  personalDetails: [
    {
      left: {
        label: 'Name',
        value: (
          <Stack gap='4px' flexDirection='row'>
            {participant?.salutation &&
              <Typography variant='body2' color={variables.colors.text.main} textTransform='capitalize'>
                {participant.salutation}
              </Typography>
            }
            <Typography variant='body2' color={variables.colors.text.main} fontWeight='600'>
              {participant.fullName.firstName} {participant.fullName.lastName}
            </Typography>
          </Stack>
        )
      },
      right: {
        label: 'Preferred Name',
        value: (
          <Typography variant='body2' color={variables.colors.text.main}>
            {participant.preferredName}
          </Typography>
        )
      },
    },
    {
      left: {
        label: 'Gender',
        value: (
          <Typography variant='body2' color={variables.colors.text.main} textTransform='capitalize'>
            {participant.gender}
          </Typography>
        )
      },
      right: {
        label: 'Pronoun',
        value: (
          <Typography variant='body2' color={variables.colors.text.main}>
            {participant.pronoun &&
              pronounOptions.find(option => option.value === participant.pronoun)?.label}
          </Typography>
        )
      },
    },
    {
      left: {
        label: 'Date of Birth',
        value: (
          <Stack flexDirection='column'>
            <Typography variant='body2' color={variables.colors.text.main} textTransform='capitalize'>
              {format(participant.dateOfBirth, 'dd/MM/yyyy')}
            </Typography>
            <Typography variant='body2' color={variables.colors.text.main} fontWeight='600'>
              {`(${getCurrentAge(participant.dateOfBirth)} year${getCurrentAge(participant.dateOfBirth) > 1 ? 's' : ''} old)`}
            </Typography>
          </Stack>
        )
      },
      right: {
        label: 'Aboriginal/Torres Strait Islander',
        value: (
          <Typography variant='body2' color={variables.colors.text.main}>
            {participant.aboriginalOrTorresStraitIslander &&
              aboriginalOrTorresStraitIslanderOptions.find(option => option.value === participant.aboriginalOrTorresStraitIslander)?.label}
          </Typography>
        )
      },
    },
    {
      left: {
        label: 'Email',
        value: (
          <Box width='100%'>
            <Typography variant='body2' color={variables.colors.text.main} sx={{
              overflowWrap: 'anywhere'
            }}>
              {participant.email}
            </Typography>
          </Box>
        )
      },
      right: {
        label: 'Email',
        value: (
          <Typography variant='body2' color={variables.colors.text.main} sx={{
            overflowWrap: 'anywhere'
          }}>
            {participant.additionalPersonalEmail}
          </Typography>
        )
      },
    },
    {
      left: {
        label: 'Referred by',
        value: (
          <Box width='100%'>
            <Typography variant='body2' color={variables.colors.text.main}>
              {participant.referredBy}
            </Typography>
          </Box>
        )
      },
      right: {
        label: 'Assigned to',
        value: (
          <Link
            to={`/settings/user-management/user-profiles/view/${(participant.assignedTo as NameAndID).id}`}
            style={{ textDecorationColor: variables.colors.text.main }}
          >
            <Typography variant='body2' color={variables.colors.text.main}>
              {(participant.assignedTo as NameAndID).fullName}
            </Typography>
          </Link>
        )
      },
    }
  ] as ILine[],
  locationDetails: [
    {
      left: {
        label: 'Service Region',
        value: (
          <Typography variant='body2' color={variables.colors.text.main} textTransform='capitalize'>
            {participant?.serviceRegion && (participant.serviceRegion as ServiceRegion).name}
          </Typography>
        )
      },
      right: {
        label: '',
        value: (<></>)
      },
    },
    {
      left: {
        label: 'Home Address',
        value: (
          <Typography variant='body2' color={variables.colors.text.main} textTransform='capitalize'>
            {`${participant.homeAddress.streetAddress}
            ${participant.homeAddress.suburb},
            ${participant.homeAddress.state} -
            ${participant.homeAddress.postCode}`}
          </Typography>
        )
      },
      right: {
        label: '',
        value: (<></>)
      },
    },
    {
      left: {
        label: 'Other Address',
        value: (
          <Typography variant='body2' color={variables.colors.text.main} textTransform='capitalize'>
            {participant.otherAddress?.streetAddress &&
              `${participant.otherAddress?.streetAddress}
            ${participant.otherAddress?.suburb},
            ${participant.otherAddress?.state} -
            ${participant.otherAddress?.postCode}`}
          </Typography>
        )
      },
      right: {
        label: '',
        value: (<></>)
      },
    },
  ] as ILine[],
  fundingDetails: [
    {
      left: {
        label: 'NDIS Number',
        value: (
          <Typography variant='body2' color={variables.colors.text.main}>
            {participant.ndisNumber}
          </Typography>
        )
      },
      right: {
        label: 'Funding Program',
        value: (
          <Typography variant='body2' color={variables.colors.text.main}>
            {participant.fundingProgram}
          </Typography>
        )
      },
    },
    {
      left: {
        label: 'NDIS System',
        value: (
          <Typography variant='body2' color={variables.colors.text.main}>
            {participant.ndisSystem.toUpperCase()}
          </Typography>
        )
      },
      right: {
        label: '',
        value: (<></>)
      },
    },
  ] as ILine[]
});

export interface ILine {
  left: {
    label: string;
    value: JSX.Element;
  };
  right: {
    label: string;
    value: JSX.Element;
  };
}


export default DetailsOverview;