import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import DataTableControls from './Controls';
import DataTable from 'components/common/DataTable';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Columns } from './Controls/Columns';
import { SelectedFilter } from 'components/common/FilterWithDropdown/types';
import { Data, PaginationModel } from 'components/common/DataTable/types';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import { paginationModelDefault } from 'utils/helpers';
import { listSupportItems } from 'api/pricing';
import useInvalidateData from 'hooks/useInvalidateData';

const ProductsAndServicesDataTable: FC = () => {
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([]);
  const [searchText, setSearchText] = useState('');
  const [paginationModel, setPaginationModel] = useState<PaginationModel>(paginationModelDefault);
  useInvalidateData('products-and-services');

  const { isPending, isError, data, isFetching, } = useQuery({
    queryKey: ['products-and-services', paginationModel, selectedFilters, searchText],
    queryFn: () => listSupportItems(
      selectedFilters,
      paginationModel.page + 1,
      paginationModel.pageSize,
      searchText
    ),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.productsAndServices,
  });

  return (
    <Stack height='100%' width='100%' sx={{ backgroundColor: 'white' }}>
      <DataTableControls
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <DataTable
        data={data as Data}
        columns={Columns}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        isPending={isPending || isFetching}
        isError={isError}
        onNoDataTitle='No support items matching your search criteria were found'
        dataTableName='products-and-services'
        checkboxSelection={false}
        paginationMode='server'
        onNoData={null}
        onNoDataSubTitle='Please refine your search and try again'
      />
    </Stack>
  );
};

export default ProductsAndServicesDataTable;