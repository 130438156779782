import React, { FC } from 'react';
import { LinearProgress } from '@mui/material';
import ViewContact from 'components/features/Views/ViewContact';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import { getExternalContactByID } from 'api/organisations/externalcontacts';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { organisationStateAtom } from 'atoms/organisationsAtom';

const ViewContactPage: FC = () => {
  const { id } = useParams();
  const [organisations] = useUserOrganisations();
  const [organisationState] = useAtom(organisationStateAtom);

  // If these aren't loaded, we'll skip the fetch until they are
  const canFetch = organisationState.hasBeenFetched && organisations.length > 0;

  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({
    getByIDFunc: () => getExternalContactByID(organisations[0].organisation.globalId, id as string),
    skip: !canFetch,
  });

  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;

  return (
    <ViewContact externalContact={currentDataPoint} />
  );
};

export default ViewContactPage;