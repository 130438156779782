import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import StatusDropdown from 'components/common/StatusDropdown';
import variables from 'styles/variables';
import { format } from 'date-fns';
import { deliveredServiceRecordStatuses } from 'types/dbSchema/deliveredServiceRecord';

export const Columns: GridColDef[] = [
  {
    field: 'reference',
    headerName: 'Reference',
    width: 150,
    renderCell: (params) => (
      <Typography variant='body2' fontWeight='600'>
        {params.row.reference}
      </Typography>
    ),
    sortable: false,
  },
  {
    field: 'participant',
    headerName: 'Participant',
    width: 200,
    renderCell: (params) => (
      <Typography variant='body2' fontWeight='600'>
        {`${params.row.participant.fullName.firstName} ${params.row.participant.fullName.lastName}`}
      </Typography>
    ),
    sortable: false,
  },
  {
    field: 'deliveredPeriod',
    headerName: 'Delivered Period',
    width: 250,
    renderCell: (params) => (
      <Typography variant='body2' color={variables.colors.text.secondary}>
        {`${format(params.row?.deliveredPeriod.startDate, 'dd/MM/yyyy')} - ${format(params.row?.deliveredPeriod.endDate, 'dd/MM/yyyy')}`}
      </Typography>
    ),
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    width: 100,
    renderCell: (params) => (
      <Typography variant='body2' fontWeight='600'>
        {params.row.quantity.toFixed(2)}
      </Typography>
    ),
  },
  {
    field: 'rate',
    headerName: 'Rate',
    width: 100,
    renderCell: (params) => (
      <Typography variant='body2' fontWeight='600'>
        {params.row.rate.toFixed(2)}
      </Typography>
    ),
  },
  {
    field: 'unit',
    headerName: 'Unit',
    width: 100,
    renderCell: (params) => (
      <Typography variant='body2' fontWeight='600'>
        {params.row.unit}
      </Typography>
    ),
  },
  {
    field: 'total',
    headerName: 'Total',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => (
      <Typography variant='body2' fontWeight='600'>
        {params.row.total.toFixed(2)}
      </Typography>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (params) => (
      <StatusDropdown
        statuses={deliveredServiceRecordStatuses}
        currentStatus={params.row.status}
        disabled
      />
    ),
  },
];