import React, { FC, useCallback } from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ExternalContactForm from 'components/features/Forms/ExternalContactForm';
import { modalStateAtom } from 'atoms';
import { useAtom } from 'jotai';
import ButtonWithDropdown from 'components/common/ButtonWithDropdown';
import ActionsDropdown from 'components/common/ActionsDropdown';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { exportExternalContacts } from 'api/organisations/externalcontacts';
import { handleExport } from 'utils/helpers';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Action } from 'components/common/ActionsDropdown/types';
import ImportModal from 'components/features/ImportModal';

export interface ActionsProps {
  dataTableName: string;
}

const Actions: FC<ActionsProps> = ({ dataTableName }) => {
  const [, setModalState] = useAtom(modalStateAtom);
  const [organisations] = useUserOrganisations();

  const exportData = async () => {
    try {
      handleExport(
        () => exportExternalContacts(organisations[0].organisation.globalId),
        'external-contacts',
      );
    } catch (error) {
      console.error('There was an error exporting external-contacts', error);
      throw error;
    }
  };

  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: ExternalContactForm,
      props: {
        type: 'create',
      }
    }));
  }, [setModalState]);

  const handleImportModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'right',
      component: ImportModal,
      props: {
        dataTableName,
      },
    }));
  }, [setModalState, dataTableName]);

  const actions: Action[] = [
    {
      icon: AddOutlinedIcon,
      label: 'Add Contact',
      function: () => handleModalOpen(),
    },
    {
      icon: PostAddOutlinedIcon,
      label: 'Import from CSV',
      function: () => {
        handleImportModalOpen();
      },
    },
    {
      icon: FileUploadOutlinedIcon,
      label: "Export All",
      function: () => {
        exportData();
      },
    }
  ];

  return (
    <ButtonWithDropdown
      color='primary'
      label='Actions'
      isDisabled={false}
      dropdownContents={
        <ActionsDropdown selectedRowIds={[]} dataTableName={dataTableName} actions={actions} />
      }
    />
  );
};

export default Actions;