import { Box, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import variables from 'styles/variables';
import { useNavigate } from 'react-router-dom';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { ParticipantProfileCellProps } from 'types/dbSchema/participants';

const ParticipantProfileCell: FC<ParticipantProfileCellProps> = ({ participant, isEditable = true }) => {
  const navigate = useNavigate();

  const handleEditClick = (event: React.MouseEvent) => {
    if (!isEditable) return;
    event.stopPropagation();
    navigate(`/participant-centre/participants/view/${participant.id}`);
  };

  return (
    <Stack flexDirection='row' gap='16px' justifyContent='flex-start' alignItems='center' height='100%' width='100%' sx={{ cursor: isEditable ? 'pointer' : 'initial' }} onClick={handleEditClick}>
      {isEditable && <EditOutlinedIcon sx={{ color: '#9CA3AF', cursor: 'pointer' }} />}
      {participant.profilePicture ? (
        <Box height='100%' width='fit-content' display='flex' alignItems='center'>
          <img src={participant.profilePicture} alt="profile" style={{ width: 'auto', height: '32px', borderRadius: '32px' }} />
        </Box>
      ) : (
        <Stack height='100%' width='fit-content' display='flex' alignItems='center' bgcolor={variables.colors.primary.darker} sx={{ width: '32px', height: '32px', borderRadius: '32px' }} >
          <Typography variant='body2' color='white' fontWeight='600' textAlign='center' lineHeight='32px'>
            {participant.fullName.firstName.charAt(0).toUpperCase()}
            {participant.fullName.lastName.charAt(0).toUpperCase()}
          </Typography>
        </Stack>
      )}
      <Stack>
        <Typography variant='subtitle2'>{`${participant.fullName.firstName} ${participant.fullName.lastName}`}</Typography>
        <Typography variant='body2' color={variables.colors.text.secondary}>{participant.email}</Typography>
      </Stack>
    </Stack>
  );
};

export default ParticipantProfileCell;