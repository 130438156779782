import React, { FC, useEffect, useState } from 'react';
import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import Tabs from 'components/common/Tabs';
import ArrowBackOutlined from '@mui/icons-material/ArrowBackOutlined';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import variables from 'styles/variables';
import { ProductAndServiceByRegion, TPriceGuideRegion } from 'types/dbSchema/productAndServices';
import Label from 'components/common/Label';
import Tooltip from 'components/common/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { PaginationModel } from 'components/common/DataTable/types';
import { paginationModelDefault } from 'utils/helpers';
import { getSupportItemByID } from 'api/pricing';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import useInvalidateData from 'hooks/useInvalidateData';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import { useAtom } from 'jotai';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import ProductAndServiceByRegionDataTable from 'components/features/DataTables/ProductAndServiceByRegionDataTable';

const ViewProductAndService: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { hash } = useLocation();
  const [region, setRegion] = useState<TPriceGuideRegion>('QLDNSWVIC');
  const [paginationModel, setPaginationModel] = useState<PaginationModel>(paginationModelDefault);
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  useInvalidateData('products-and-services');

  useEffect(() => {
    setInvalidateData((prev) => ({ ...prev, 'products-and-services': true }));
    if (hash === '#nt-sa-wa-tas') {
      setRegion('NTSAWATAS');
    } else if (hash === '#remote') {
      setRegion('Remote');
    } else if (hash === '#very-remote') {
      setRegion('VeryRemote');
    }
  }, [hash]);

  const { isPending, data, isFetching, } = useQuery({
    queryKey: ['products-and-services', paginationModel, region, id],
    queryFn: () => getSupportItemByID(
      id as string,
      region,
      paginationModel.page + 1,
      paginationModel.pageSize,
    ),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.productsAndServices,
  });

  const handleBack = () => navigate('/resources/products-services');

  return (
    <Stack sx={{ backgroundColor: 'white', height: '100%' }}>
      <Stack padding='24px 24px 16px 24px' gap='8px'>
        <Stack flexDirection='row' gap='4px' alignItems='center' padding='8px 0' onClick={handleBack} sx={{ cursor: 'pointer' }}>
          <ArrowBackOutlined sx={{ height: '20px', width: '20px', color: 'rgba(0,0,0,0.6)' }} />
          <Typography variant='body2' color="text.primary" sx={{ color: 'rgba(0,0,0,0.6)' }}>
            Products and Services
          </Typography>
        </Stack>
        <Stack flexDirection='row' gap='16px' alignItems='center'>
          <Typography variant='h6' minWidth='fit-content'>
            {data?.supportItemNumber}
          </Typography>
          <Typography variant='body1' minWidth='fit-content' color={variables.colors.text.secondary}>
            {data?.supportItemName}
          </Typography>
          <IsActiveDropdown isActive={data?.isActive || true} disabled />
        </Stack>
        <Stack gap='16px' flexDirection='row' alignItems='center'>
          <Label text={
            <Stack gap='4px' flexDirection='row' alignItems='center'>
              <Typography variant='body2' color={variables.colors.text.secondary} fontSize='12px'>
                PACE:
              </Typography>
              <Typography variant='body1' fontSize='12px' fontWeight='600'>
                {data?.supportCategoryNamePACE}
              </Typography>
            </Stack>
          }
          />
          <Label text={
            <Stack gap='4px' flexDirection='row' alignItems='center'>
              <Typography variant='body2' color={variables.colors.text.secondary} fontSize='12px'>
                PRODA:
              </Typography>
              <Typography variant='body1' fontSize='12px' fontWeight='600'>
                {data?.supportCategoryName}
              </Typography>
            </Stack>
          }
          />
          {data && <StyledTooltip dataPoint={data} />}
        </Stack>
      </Stack>
      {data ? (
        <Tabs tabs={[
          {
            label: 'QLD/NSW/VIC',
            content: <ProductAndServiceByRegionDataTable data={data.pagination} paginationModel={paginationModel} setPaginationModel={setPaginationModel} />,
            requiredPermissions: [],
          },
          {
            label: 'NT/SA/WA/TAS',
            content: <ProductAndServiceByRegionDataTable data={data.pagination} paginationModel={paginationModel} setPaginationModel={setPaginationModel} />,
            requiredPermissions: [],
          },
          {
            label: 'Remote',
            content: <ProductAndServiceByRegionDataTable data={data.pagination} paginationModel={paginationModel} setPaginationModel={setPaginationModel} />,
            requiredPermissions: [],
          },
          {
            label: 'Very Remote',
            content: <ProductAndServiceByRegionDataTable data={data.pagination} paginationModel={paginationModel} setPaginationModel={setPaginationModel} />,
            requiredPermissions: [],
          },
        ]}
        />
      ) : isPending || isFetching ? (
        <Stack justifyContent='start' alignItems='center' style={{ height: '100%', width: '100%' }}>
          <LinearProgress sx={{ width: '100%' }} />
        </Stack>
      ) : (
        <Typography variant='body1' color='text.primary'>
          No data found
        </Typography>
      )}
    </Stack>
  );
};

export interface StyledTooltipProps {
  dataPoint: ProductAndServiceByRegion;
}

const StyledTooltip: FC<StyledTooltipProps> = ({ dataPoint }) => {
  const detailsArray = [
    {
      label: 'Quote',
      value: dataPoint.quote,
    },
    {
      label: 'Non-F2F Support Provision',
      value: dataPoint.nonFaceToFaceSupportProvision,
    },
    {
      label: 'Provider Travel',
      value: dataPoint.providerTravel,
    },
    {
      label: 'NDIA Requested Reports',
      value: dataPoint.ndiaRequestedReports,
    },
    {
      label: 'Irregular SIL Supports',
      value: dataPoint.irregularSILSupports,
    }
  ];

  return (
    <Tooltip
      toolTipText={
        <Stack gap='8px'>
          {detailsArray.map((detail, index) => (
            <Stack gap='8px' flexDirection='row' alignItems='center' key={index}>
              <Typography variant='body2' fontSize='12px' fontWeight='500' color={variables.colors.text.secondary}>
                {detail.label}:
              </Typography>
              <Typography variant='body2' fontSize='12px' fontWeight='600' color={variables.colors.text.primary}>
                {detail.value}
              </Typography>
            </Stack>
          ))}
        </Stack>
      }
      toolTipTitle=''
      toolTipTriggerComponent={
        <Box height='16px' width='16px' position='relative' minWidth='16px'>
          <InfoOutlinedIcon sx={{ height: '100%', width: '100%' }} />
        </Box>
      }
    />
  );
};

export default ViewProductAndService;