import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import styled from 'styled-components';
import variables from 'styles/variables';
import ProfileImageUpload from 'components/common/InputFields/ProfileImageFileUpload';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import UserDetails from './UserDetails';
import AccessDetails from './AccessDetails';
import { User } from 'types/dbSchema/userProfiles';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { closeModalAtom } from 'atoms/modalAtom';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { UserPosition } from 'types/dbSchema/userPositions';
import { deleteUserProfile } from 'api/organisations/users';

export interface ViewUserProfileProps {
  userProfile: User;
}

const ViewUserProfile: FC<ViewUserProfileProps> = ({ userProfile }) => {

  return (
    <Stack width='100%' gap={2} justifyContent='flex-start' alignItems='center' sx={{
      backgroundColor: 'white',
      minHeight: `calc(100vh - ${variables.heights.topBar})`,
      height: '100%',
      overflowY: 'auto',
    }}>
      <PageHeader userProfile={userProfile} />
      <Stack flexDirection='row' gap='24px' width='100%' padding=' 0 24px 32px 24px' boxSizing='border-box'>
        <UserDetails userProfile={userProfile} />
        <AccessDetails userProfile={userProfile} />
      </Stack>
    </Stack>
  );
};

const PageHeader: FC<ViewUserProfileProps> = ({ userProfile }) => {
  const navigate = useNavigate();

  return (
    <PageHeaderStack>
      <Button variant='text'
        sx={{
          color: variables.colors.text.secondary,
          textTransform: 'none',
          fontWeight: 400,
          padding: 0
        }}
        onClick={() => navigate('/settings/user-management/user-profiles')}
      >
        <ArrowBackOutlinedIcon sx={{
          padding: '4px',
          boxSizing: 'border-box',
          height: '24px',
          width: '24px',
        }}
        />
        User Profiles
      </Button>
      <Stack flexDirection='row' gap={1} justifyContent='space-between' alignItems='center' width='100%' position='relative'>
        <Stack flexDirection='row' gap='16px' alignItems='center'>
          <ProfileImageUpload variant='icon' value={userProfile.profilePicture} />
          <Typography variant='h5'>
            {userProfile.fullName.firstName} {userProfile.fullName.lastName}
          </Typography>
          <Box sx={{
            padding: '4px 8px',
            borderRadius: '4px',
            backgroundColor: variables.colors.primary.subtle,
            color: variables.colors.primary.darker
          }}>
            <Typography variant='subtitle2'>
              {userProfile.position && (userProfile.position as UserPosition).name}
            </Typography>
          </Box>
        </Stack>
        <Stack flexDirection='row' gap='16px' alignItems='center'>
          <Button
            variant='contained'
            color='primary'
            onClick={
              () => navigate(`/settings/user-management/user-profiles/edit/${userProfile.id}`)
            }
            startIcon={
              <EditOutlinedIcon sx={{
                color: 'white',
                marginRight: '8px'
              }} />
            }
          >
            Edit
          </Button>
          <MoreActions userProfile={userProfile} />
        </Stack>
      </Stack>
    </PageHeaderStack>
  );
};

const MoreActions: FC<ViewUserProfileProps> = ({ userProfile }) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = React.useRef<HTMLDivElement>(null);
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const [modalState, setModalState] = useAtom(modalStateAtom);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>, action: 'deactivate' | 'delete') => {
    let unableToDeactivate;
    if (action === 'deactivate') {
      // Check if user has shift allocations
      // unableToDeactivate = userProfile.shiftAllocations.length > 0;
      unableToDeactivate = true;
    }
    e.preventDefault();
    setModalState({
      ...modalState,
      status: 'open',
      position: 'center',
      component: ModalContent,
      props: {
        type: unableToDeactivate ? 'unable-to-deactivate' : action,
        userProfile,
      },
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target as Node) &&
        dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <MoreStack onClick={() => setIsOpen(!isOpen)} ref={buttonRef}>
      <MoreHorizOutlinedIcon />
      {isOpen && (
        <DropdownStack ref={dropdownRef}>
          <StyledFilterDropdown sx={{ borderBottom: '1px solid #E0E0E0' }} onClick={(e) => handleClick(e, 'deactivate')}>
            <Stack flexDirection='row' gap='8px' alignItems='center' padding='6px 8px'>
              <CancelOutlinedIcon sx={{ color: variables.colors.text.secondary }} />
              <Typography variant='body2' color={variables.colors.text.secondary} fontWeight='600'>
                Deactivate User
              </Typography>
            </Stack>
          </StyledFilterDropdown>
          <StyledFilterDropdown onClick={(e) => handleClick(e, 'delete')}>
            <Stack flexDirection='row' gap='8px' alignItems='center' padding='6px 8px'>
              <DeleteOutlinedIcon sx={{ color: variables.colors.text.secondary }} />
              <Typography variant='body2' color={variables.colors.text.secondary} fontWeight='600'>
                Delete User
              </Typography>
            </Stack>
          </StyledFilterDropdown>
        </DropdownStack>
      )}
    </MoreStack>
  );
};

const ModalContent: FC<ViewUserProfileProps> = ({ userProfile }) => {
  const navigate = useNavigate();
  const [modalState] = useAtom(modalStateAtom);
  const [, closeModal] = useAtom(closeModalAtom);
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const [organisations] = useUserOrganisations();

  const handleDeleteConfirmation = async () => {
    await deleteUserProfile(organisations[0].organisation.globalId, userProfile.id);
    setInvalidateData((prev) => ({ ...prev, 'user-profiles': true }));
    closeModal();
    navigate('/settings/user-management/user-profiles');
  };

  const handleDeactivateConfirmation = async () => {
    // TODO: Handle deactivate user here
    console.log('🚀 ~ Deactivate', userProfile);
    setInvalidateData((prev) => ({ ...prev, 'user-profiles': true }));
    closeModal();
    navigate('/settings/user-management/user-profiles');
  };

  const handleViewStaffRoster = () => {
    navigate('/scheduling/staff-roster');
  };

  return (
    <Stack width='500px' boxSizing='border-box'>
      <Stack flexDirection='row' gap='24px' width='100%' padding='32px' boxSizing='border-box'>
        <IconStack sx={{
          backgroundColor: (modalState.props.type === 'delete' || modalState.props.type === 'deactivate') ? variables.colors.error.subtle : '#FFECB3'
        }} >
          {modalState.props.type === 'delete'
            ? <DeleteOutlinedIcon sx={{ color: variables.colors.error.darker, width: '24px' }} />
            : modalState.props.type === 'deactivate'
              ? <PersonOffOutlinedIcon sx={{ color: variables.colors.error.darker, width: '24px' }} />
              : <WarningAmberOutlinedIcon sx={{ color: variables.colors.warning.darker, width: '24px' }} />
          }
        </IconStack>
        <Stack gap='12px'>
          <Typography variant='h6'>
            {modalState.props.type === 'delete'
              ? 'Are you sure you want to delete this user?'
              : modalState.props.type === 'deactivate'
                ? 'Are you sure you want to deactivate this user?'
                : 'Unable to deactivate user'
            }
          </Typography>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            {modalState.props.type === 'delete'
              ? 'The action cannot be reverted.'
              : modalState.props.type === 'deactivate'
                ? 'This will remove the user’s access to the system.'
                : 'This user is a Service Team member and currently has shift allocations. Please ensure they are unallocated before deactivating the user account.'
            }
          </Typography>
        </Stack>
      </Stack>
      <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' maxWidth='960px' padding='16px 32px' boxSizing='border-box' sx={{ backgroundColor: variables.colors.lightNeutral.subtle, borderRadius: '0 0 12px 12px' }} >
        {modalState.props.type === 'delete' ? (
          <>
            <Button variant='text' onClick={closeModal} sx={{ border: '1px solid #E5E7EB' }}>Cancel</Button>
            <Button variant='contained' color='error' onClick={handleDeleteConfirmation}>Delete User</Button>
          </>
        ) : modalState.props.type === 'deactivate' ? (
          <>
            <Button variant='text' onClick={closeModal} sx={{ border: '1px solid #E5E7EB' }}>Cancel</Button>
            <Button variant='contained' color='error' onClick={handleDeactivateConfirmation}>Confirm</Button>
          </>
        ) : (
          <>
            <Button variant='text' onClick={closeModal}>Cancel</Button>
            <Button variant='contained' onClick={handleViewStaffRoster}>View Staff Roster</Button>
          </>
        )}
      </Stack>
    </Stack>
  );
};

const DropdownStack = styled(Stack)`
  position: absolute;
  top: 45px;
  right: 0;
  width: fit-content;
  height: fit-content;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid #E0E0E0;
  box-shadow: 0px 3px 8px -1px #E0E0E0;
  border-radius: 4px;
  overflow: hidden;
  z-index: 1;
`;

const IconStack = styled(Stack)`
  border-radius: 100%;
  min-width: 40px;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
`;

const StyledFilterDropdown = styled(Stack)`
  box-sizing: border-box;
  width: 240px;
  padding: 8px;
  position: relative;
  background-color: white;
`;

const PageHeaderStack = styled(Stack)`
  display: flex;
  width: 100%;
  padding: 32px 24px;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  gap: 8px;
`;

const MoreStack = styled(Stack)`
  background-color: #F3F4F6;
  border-radius: 4px;
  height: 42px;
  width: 42px;
  padding: 9px;
  box-sizing: border-box;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: background-color 200ms;
  position: relative;
  &:hover {
    background-color: #E0E0E0;
  }
`;

export default ViewUserProfile;