import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import DataTableControls from './Controls';
import DataTable from 'components/common/DataTable';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Columns } from './Controls/Columns';
import useUserOrganisations from 'hooks/useUserOrganisations';
import useInvalidateData from 'hooks/useInvalidateData';
import { PaginationModel } from 'components/common/DataTable/types';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import UserPositionForm from 'components/features/Forms/UserPositionForm';
import { placeholderUnallocatedServiceData } from 'utils/helpers/getDBData';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import { paginationModelDefault } from 'utils/helpers';

const UnallocatedServicesDataTable: FC = () => {
  const [searchText, setSearchText] = useState('');
  const [paginationModel, setPaginationModel] = useState<PaginationModel>(paginationModelDefault);
  const [organisations] = useUserOrganisations();
  const [modalState, setModalState] = useAtom(modalStateAtom);
  useInvalidateData('unallocated-services');

  const { isPending, isError, data, isFetching, isLoading } = useQuery({
    queryKey: ['unallocated-services', paginationModel, searchText],
    // queryFn: () => listPositions(organisations[0].organisation.globalId, paginationModel.page + 1, paginationModel.pageSize),
    queryFn: () => placeholderUnallocatedServiceData,
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.unallocatedServices,
  });

  const handleModalOpen = () => {
    setModalState({
      ...modalState,
      status: 'open',
      position: 'center',
      component: null,
      props: {
        type: 'create',
      }
    });
  };

  return (
    <Stack height='100%' width='100%' sx={{ backgroundColor: 'white' }}>
      <DataTableControls
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <DataTable
        data={{ items: data || [], totalCount: 4, pageNumber: paginationModel.page, pageSize: paginationModel.pageSize }}
        // data={data as Data}
        columns={Columns}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        isPending={isPending || isFetching || isLoading}
        isError={isError}
        onNoDataTitle='No Unallocated Services found'
        dataTableName='unallocated-services'
        checkboxSelection={false}
        paginationMode='server'
        onNoData={handleModalOpen}
      />
    </Stack>
  );
};

export default UnallocatedServicesDataTable;