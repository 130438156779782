import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import ViewIcon from './ViewIcon';
import { Typography } from '@mui/material';
import variables from 'styles/variables';
import { ProductAndService } from 'types/dbSchema/productAndServices';

export const Columns: GridColDef[] = [
  {
    field: 'view',
    headerName: '',
    width: 50,
    renderCell: (params: { row: ProductAndService }) => (
      <ViewIcon productAndService={params.row} />
    ),
    sortable: false,
  },
  {
    field: 'productCode',
    headerName: 'Support Item Number',
    flex: 1,
    minWidth: 180,
    renderCell: (params: { row: ProductAndService }) => (
      <Typography variant='subtitle2'>{params.row.supportItemNumber}</Typography>
    ),
    sortable: false,
  },
  {
    field: 'name',
    headerName: 'Support Item',
    flex: 2,
    minWidth: 240,
    renderCell: (params: { row: ProductAndService }) => (
      <Typography
        variant="body2"
        color={variables.colors.text.secondary}
        sx={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
          overflow: 'hidden',
          whiteSpace: 'normal',
        }}
      >
        {params.row.supportItemName}
      </Typography>
    ),
    sortable: false,
  },
  {
    field: 'supportCategoryNamePRODA',
    headerName: 'PRODA Support Category',
    flex: 1,
    minWidth: 240,
    renderCell: (params: { row: ProductAndService }) => (
      <Typography variant='body2' color={variables.colors.text.secondary} sx={{ whiteSpace: 'wrap' }}>
        {params.row.supportCategoryName}
      </Typography>
    ),
    sortable: false,
  },
  {
    field: 'supportCategoryNamePACE',
    headerName: 'PACE Support Category',
    flex: 1,
    minWidth: 240,
    renderCell: (params: { row: ProductAndService }) => (
      <Typography variant='body2' color={variables.colors.text.secondary} sx={{ whiteSpace: 'wrap' }}>
        {params.row.supportCategoryNamePACE}
      </Typography>
    ),
    sortable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (params: { row: ProductAndService }) => (
      <IsActiveDropdown
        isActive={params.row.isActive}
        disabled
      />
    ),
  },
];