import React, { FC, useEffect, useRef, useState } from 'react';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { ParticipantStatus } from 'types/dbSchema/participantSettings';
import { listParticipantStatus } from 'api/organisations/settings/participants/statuses';
import { useInfiniteQuery } from '@tanstack/react-query';
import { Box, Stack, Typography } from '@mui/material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import styled from 'styled-components';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import { ParticipantStatusDropdownProps, ParticipantStatusOption } from './types';

const ParticipantStatusDropdown: FC<ParticipantStatusDropdownProps> = ({ participant, disabled, setStatus, isStateBased }) => {
  const [organisations] = useUserOrganisations();
  const [options, setOptions] = useState<ParticipantStatusOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<ParticipantStatusOption | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const filterDropdownRef = useRef<HTMLDivElement>(null);
  const filterButtonRef = useRef<HTMLDivElement>(null);

  // This function handles the status change for the participant
  // It sets the selected option and calls the setStatus function if it is provided
  // If it is state based, it will set the selected option to reflect the changes immediately in the UI
  const handleStatusChange = (status: string) => {
    const optionFound = options.find((option) => option.value === status) || null;
    isStateBased && setSelectedOption(optionFound);
    setStatus && setStatus(optionFound?.object);
    setIsOpen(false);
  };

  // This function handles the closing of the dropdown when clicking outside of the dropdown
  const handleClickOutside = (event: MouseEvent) => {
    if (
      filterButtonRef.current &&
      !filterButtonRef.current.contains(event.target as Node) &&
      filterDropdownRef.current &&
      !filterDropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // useInfiniteQuery is a hook that allows you to fetch data on scroll
  // This function handles the fetching of participant statuses in the organisation
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ['participant-statuses-infinite', organisations[0]?.organisation.globalId, 100],
    queryFn: ({ pageParam = 0 }) => listParticipantStatus(organisations[0].organisation.globalId, pageParam + 1, 100),
    initialPageParam: 0,
    staleTime: cachingInvalidation.participantStatuses,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage, allPages) => {
      const totalLoadedItems = allPages.flatMap(page => page.items).length;
      if (totalLoadedItems >= lastPage.totalCount) return undefined; // No more pages to load
      return allPages.length;
    },
  });

  // When the data is fetched, map the service types to the options
  // The label is JSX so that we can customise the look of the options
  useEffect(() => {
    if (data) {
      const newOptions = data.pages.flatMap(page =>
        page.items.map((participantStatus: ParticipantStatus) => ({
          value: participantStatus.id,
          object: participantStatus,
          label: (
            <Typography variant='body2' fontWeight='600' color={participantStatus.fontColor}>
              {participantStatus.name}
            </Typography>
          ),
        }))
      );
      setOptions(newOptions);
    }
  }, [data]);


  // This useEffect does a few things depending on the state of the options and the participant - read the comments for more info
  useEffect(() => {
    // If there are no options, return early
    if (options.length === 0) return;
    // If there is a participant, find the selected option based on the participant status
    if (participant) {
      const found = options.find((option) => option.value === (participant.status as ParticipantStatus).id as string) || null;
      // Set the selected option for display in the dropdown
      setSelectedOption(found);
    } else {
      // If there is no participant, find the selected option based on the default status
      const found = options.find((option) => option.object.name === 'Active') || null;
      isStateBased && handleStatusChange(found ? found.value : '');
    }
  }, [participant, options]);

  return (
    <Box sx={{ position: 'relative' }} width='fit-content' ref={filterButtonRef}>
      <Stack sx={{ backgroundColor: selectedOption?.object.color, padding: '4px 8px', borderRadius: '4px', width: 'fit-content', cursor: `${disabled ? 'initial' : 'pointer'}`, position: 'relative' }} direction='row' alignItems='center' onClick={() => setIsOpen(!isOpen)}>
        {selectedOption?.object && (selectedOption?.label)}
        {!disabled &&
          <KeyboardArrowDownOutlinedIcon
            sx={{
              fontSize: '1rem',
              marginLeft: '4px',
              color: selectedOption?.object.fontColor,
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)'
            }}
          />
        }
        {isOpen && !disabled && setStatus && (
          <Dropdown
            ref={filterDropdownRef}
            onScroll={(event: any) => {
              const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
              if (bottom && hasNextPage) {
                fetchNextPage();
              }
            }}
          >
            {options.map((participantStatus: ParticipantStatusOption, key) => (
              <Box padding='8px 16px' key={key} onClick={() => handleStatusChange(participantStatus.value)}>
                <Box sx={{ backgroundColor: participantStatus.object.color, padding: '2px 4px', borderRadius: '4px', width: 'fit-content', cursor: 'pointer', position: 'relative' }}>
                  {participantStatus.label}
                </Box>
              </Box>
            ))}
          </Dropdown>
        )}
      </Stack>
    </Box>
  );
};

const Dropdown = styled(Stack)`
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  width: fit-content;
  min-width: 150px;
  height: fit-content;
  max-height: 200px;
  overflow-y: auto;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid #E0E0E0;
  box-shadow: 0px 3px 8px -1px #E0E0E0;
  border-radius: 4px;
  z-index: 999;
`;

export default ParticipantStatusDropdown;