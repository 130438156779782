import React, { FC } from 'react';
import variables from 'styles/variables';
import { Button, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { DownloadTemplateProps } from '../types';
import { downloadImportTemplate } from 'utils/helpers';


const DownloadTemplate: FC<DownloadTemplateProps> = ({ template }) => {

  const handleDownload = () => downloadImportTemplate(template);

  return (
    <TemplateBox>
      <Stack flexDirection='row' gap='8px' alignItems='center'>
        <PostAddOutlinedIcon color='primary' />
        <Typography variant='subtitle2'>
          Import from CSV
        </Typography>
      </Stack>
      <ol style={{ margin: 0, padding: '0 0 0 20px', color: variables.colors.text.secondary, display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <li>
          <Typography variant='body2'>
            Download the template.
          </Typography>
        </li>
        <li>
          <Typography variant='body2'>
            Enter the required data. Mandatory fields are highlighted in red.
          </Typography>
        </li>
        <li>
          <Typography variant='body2'>
            Save the Excel spreadsheet as a .csv file.
          </Typography>
        </li>
        <li>
          <Typography variant='body2'>
            Upload the file.
          </Typography>
        </li>
      </ol>
      <DownloadTemplateButton variant='outlined' color='primary' onClick={handleDownload}>
        Download Template
        <FileDownloadOutlinedIcon />
      </DownloadTemplateButton>
    </TemplateBox>
  );
};

const TemplateBox = styled(Stack)`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: #FAFAFA;
`;

const DownloadTemplateButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  text-transform: none;
`;

export default DownloadTemplate;