import React from 'react';
import { GridColDef } from "@mui/x-data-grid";
import EditIcon from "./EditIcon";
import { Typography } from "@mui/material";
import { formatISODate } from "utils/helpers";
import EditStatusDropdown from "./EditStatusDropdown";

// Grid column definitions for DataTable
export const Columns: GridColDef[] = [
  {
    field: 'edit',
    headerName: '',
    width: 50,
    renderCell: (params) => (
      <EditIcon payPeriod={params.row} />
    ),
    sortable: false,
  },
  {
    field: 'rosterStartDate',
    headerName: 'Roster Start Date',
    width: 200,
    renderCell: (params) => (
      <Typography variant='subtitle2'>
        {formatISODate(params.value)}
      </Typography>
    ),
  },
  {
    field: 'frequency',
    headerName: 'Frequency',
    flex: 1,
    renderCell: (params) => (
      <Typography variant='body2' textTransform='capitalize'>
        {params.row.frequency}
      </Typography>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (params) => (
      <EditStatusDropdown payPeriod={params.row} />
    ),
  },
];