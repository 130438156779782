import React, { FC } from 'react';
import ViewServiceContracts from 'components/features/Views/ViewParticipant/ServiceContracts/ViewServiceContracts';
import { dummyServiceContracts } from 'utils/helpers/getDBData';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';

const ViewServiceContractsPage: FC = () => {
  // const [organisationState] = useAtom(organisationStateAtom);

  // If these aren't loaded, we'll skip the fetch until they are
  // const canFetch = organisationState.hasBeenFetched && organisations.length > 0;

  // const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({ getByIDFunc: getServiceContractById });
  // if (isLoading) return <LinearProgress />;
  // if (hasError || !currentDataPoint) return null;

  return <ViewServiceContracts serviceContract={dummyServiceContracts[0]} />;
};

export default ViewServiceContractsPage;