import React, { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { closeModalAtom } from 'atoms/modalAtom';
import { useAtom } from 'jotai';
import { Controller, useForm } from 'react-hook-form';
import TextInput from 'components/common/InputFields/TextInput';
import { ServiceRegion } from 'types/dbSchema/serviceRegions';
import SelectInput from 'components/common/InputFields/SelectInput';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';
import { createServiceRegion, updateServiceRegion } from 'api/organisations/serviceregions';
import { priceGuideRegionOptions } from 'types/dbSchema/productAndServices';

export interface ServiceRegionFormProps {
  type: 'create' | 'edit';
  serviceRegion: ServiceRegion | null;
}

const ServiceRegionForm: FC<ServiceRegionFormProps> = ({ type, serviceRegion }) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const showError = useErrorMessage();

  const { control, handleSubmit, formState: { errors, isDirty } } = useForm({
    mode: 'onChange',
    defaultValues: serviceRegion || {
      isActive: true,
    }
  });

  // Function to create a new Service Type
  // Invalidates the query to fetch the latest data, and closes the modal
  const handleCreate = async (data: ServiceRegion) => {
    try {
      await createServiceRegion(organisations[0].organisation.globalId, data);
      setInvalidateData((prev) => ({ ...prev, 'service-regions': true }));
      closeModal();
    } catch (error) {
      showError((error as Error).message);
    }
  };

  // Function to update a Service Type.
  // Invalidates the query to fetch the latest data, and closes the modal
  const handleEdit = async (data: ServiceRegion) => {
    try {
      await updateServiceRegion(organisations[0].organisation.globalId, data);
      setInvalidateData((prev) => ({ ...prev, 'service-regions': true }));
      closeModal();
    } catch (error) {
      showError((error as Error).message);
    }
  };

  // Function to handle form submission
  const handleSubmitForm = async (data: ServiceRegion) => {
    if (type === 'create') {
      await handleCreate(data);
    }
    else {
      await handleEdit(data);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)} data-testid='service-region-form'>
      <Stack height='100%' width='500px' justifyContent='space-between'>
        <Stack padding='32px' gap='16px'>
          <Typography variant='h6'>
            {type === 'create' ? 'Add Service Region' : 'Service Region Details'}
          </Typography>
          <Stack gap='16px' paddingTop='16px'>
            <Controller
              control={control}
              name='name'
              rules={{
                required: 'Service Region Name is required',
              }}
              render={({ field }) => (
                <TextInput
                  {...field}
                  id='name'
                  label='Name'
                  error={errors.name ? true : false}
                  errorText={errors.name?.message}
                  isMandatory
                />
              )}
            />
            <Controller
              control={control}
              name='pricingRegion'
              rules={{
                required: 'Pricing Region is required',
              }}
              render={({ field }) => (
                <SelectInput
                  {...field}
                  id='pricingRegion'
                  label='Pricing Region'
                  error={errors.pricingRegion ? true : false}
                  errorText={errors.pricingRegion?.message}
                  isMandatory
                  options={priceGuideRegionOptions}
                  placeholder='Select'
                />
              )}
            />
            <Stack flexDirection='row' justifyContent='flex-start' alignItems='center' gap='16px'>
              <Typography variant='subtitle2' fontWeight='500'>
                Status
              </Typography>
              <Controller
                name="isActive"
                control={control}
                rules={{
                  validate: value => value !== undefined && value !== null || 'Status is required'
                }}
                render={({ field }) =>
                  <IsActiveDropdown
                    isActive={(field.value === undefined || field.value === null) ? true : field.value}
                    setIsActive={field.onChange}
                  />
                }
              />
            </Stack>
          </Stack>
        </Stack>
        <ButtonStack>
          <Button variant='outlined' color='primary' onClick={closeModal}>Cancel</Button>
          <Button variant='contained' color='primary' type='submit' disabled={!isDirty}>
            {type === 'create' ? 'Create' : 'Update'}
          </Button>
        </ButtonStack>
      </Stack >
    </form>
  );
};

const ButtonStack = styled(Stack)`
  gap: 16px;
  justify-content: flex-end;
  flex-direction: row;
  padding: 24px 32px;
`;

export default ServiceRegionForm;