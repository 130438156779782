import { Box, Typography } from '@mui/material';
import variables from 'styles/variables';
import styled from 'styled-components';
import React from 'react';

const WarningNotice = () => {
  return (
    <WarningNoticeBox>
      <Typography variant='subtitle2' color={variables.colors.warning.darker}>
        Important!
      </Typography>
      <Typography variant='caption' fontWeight={500}>
        After clicking on import, the action cannot be reverted and records imported will need to be manually deleted from the list.
      </Typography>
    </WarningNoticeBox>
  );
};

const WarningNoticeBox = styled(Box)`
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 4px;
  border-left: 4px solid ${variables.colors.warning.main};
  background:  ${variables.colors.warning.subtle};
`;

export default WarningNotice;