import { PaginationBase } from "types";
import { Participant } from "./participants";
import { CancellationCode, DeclinedReason } from "./schedulingSettings";
import { ServiceContract } from "./serviceContracts";
import { NameAndID } from "./userProfiles";

export interface ParticipantSchedule {
  id: string,
  status: ParticipantScheduleStatus,
  participant: Participant | string, // in POST & PATCH will be the Participant ID. In GET it will be the Full Participant.
  ndisNumber: string,
  serviceContract: ServiceContract | string, // in POST & PATCH will be the Service Contract ID. In GET it will be the Full Service Contract.

  ndisPlantStartDate: string, // "2024-07-02T02:52:51.560Z" This format
  ndisPlantEndDate: string, // "2024-07-02T02:52:51.560Z" This format
  ndisPlanBudget: number, // Total NDIS Plan Budget

  scheduleOfSupportStartDate: string, // "2024-07-02T02:52:51.560Z" This format
  scheduleOfSupportEndDate: string, // "2024-07-02T02:52:51.560Z" This format

  coreBudgetTotal: number,
  capitalBudgetTotal: number,
  capacityBudgetTotal: number,
  totalBudget: number,

  declineReason?: DeclinedReason | string, // In POST & PATCH will be the Declined Reason ID. In GET it will be the Full Declined Reason.
  declinedBy?: NameAndID | string, // In POST & PATCH will be the User ID. In GET it will be the Partial User.
  declinedOn?: string, // "2024-07-02T02:52:51.560Z" This format

  cancellationReason?: CancellationCode | string, // In POST & PATCH will be the Cancellation Code ID. In GET it will be the Full Cancellation Code.
  cancelledBy?: NameAndID | string, // In POST & PATCH will be the User ID. In GET it will be the Partial User.
  cancelledOn?: string, // "2024-07-02T02:52:51.560Z" This format
  cancelledFrom?: string, // "2024-07-02T02:52:51.560Z" This format

  scheduleOfSupportTotal: number,
  allocatedTotal: number,
  unallocatedTotal: number,

  approvedBy?: NameAndID | string, // In POST & PATCH will be the User ID. In GET it will be the Partial User.
  approvedOn?: string, // "2024-07-02T02:52:51.560Z" This format

  sentBy?: NameAndID | string, // In POST & PATCH will be the User ID. In GET it will be the Partial User.
  sentOn?: string, // "2024-07-02T02:52:51.560Z" This format
}

export type ParticipantScheduleList = PaginationBase<ParticipantSchedule>;

export type ParticipantScheduleStatus = 'InProgress' | 'PendingApproval' | 'InternallyApproved' | 'ApprovedByParticipant' | 'Active' | 'DeclinedByParticipant' | 'Cancelled';
export const systemParticipantStatuses: ParticipantScheduleStatus[] = ['InProgress', 'PendingApproval', 'InternallyApproved', 'ApprovedByParticipant', 'Active', 'DeclinedByParticipant', 'Cancelled'];

export const participantScheduleStatuses = [
  {
    label: 'In Progress',
    value: 'InProgress',
    fontColor: '#A86800',
    backgroundColor: '#FFD30F80',
  },
  {
    label: 'Pending Approval',
    value: 'PendingApproval',
    fontColor: '#BF360C',
    backgroundColor: '#FA87004D',
  },
  {
    label: 'Internally Approved',
    value: 'InternallyApproved',
    fontColor: '#002280',
    backgroundColor: '#0057B233',
  },
  {
    label: 'Approved By Participant',
    value: 'ApprovedByParticipant',
    fontColor: '#01427F',
    backgroundColor: '#039BE54D',
  },
  {
    label: 'Active',
    value: 'Active',
    fontColor: '#174F1B',
    backgroundColor: '#69C64F66',
  },
  {
    label: 'Declined By Participant',
    value: 'DeclinedByParticipant',
    fontColor: '#8A0000',
    backgroundColor: '#FA000033',
  },
  {
    label: 'Cancelled',
    value: 'Cancelled',
    fontColor: '#18202A',
    backgroundColor: '#37415133',
  },
];