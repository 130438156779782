import React, { useCallback } from 'react';
import { Stack } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { Tag } from 'types/dbSchema/serviceTeamSettings';
import TagsForm from 'components/features/Forms/TagsForm';

// Renders Edit Icon for Grid column definitions - opens add/edit form in modal
const EditIcon = ({ tag }: { tag: Tag }) => {
  const [, setModalState] = useAtom(modalStateAtom);
  if (!tag || !tag.isEditable) return null;

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: TagsForm,
      props: {
        type: 'edit',
        tag,
      }
    }));
  }, [setModalState, tag]);

  return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'pointer' }} onClick={handleModalOpen}>
      <EditOutlinedIcon sx={{ color: '#9CA3AF' }} />
    </Stack>
  );
};

export default EditIcon;