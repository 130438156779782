import { GridColDef } from "@mui/x-data-grid";
import { TUnitOfMeasure } from "types/dbSchema/productAndServices";

export const Columns: GridColDef[] = [
  {
    field: 'fileVersion',
    headerName: 'Name',
    flex: 1
  },
  {
    field: 'effectiveDate',
    headerName: 'Effective From',
    flex: 1,
    renderCell: (params) => new Date(params.value).toLocaleDateString('en-AU')
  },
  {
    field: 'unit',
    headerName: 'Unit of Measure',
    flex: 1,
    renderCell: (params) => convertUnitOfMeasure(params.value)
  },
  {
    field: 'amount',
    headerName: 'Rate',
    flex: 1, renderCell: (params) => `$${params.value.toFixed(2)}`
  },
];

const convertUnitOfMeasure = (unit: TUnitOfMeasure) => {
  const lowerUnit = unit.toLowerCase() as TUnitOfMeasure;
  switch (lowerUnit) {
    case 'a':
      return 'Annual';
    case 'd':
      return 'Daily';
    case 'e':
      return 'Each';
    case 'h':
      return 'Hourly';
    case 'mon':
      return 'Monthly';
    case 'wk':
      return 'Weekly';
    case 'yr':
      return 'Yearly';
    default:
      return unit;
  }
};