import React, { FC } from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ParticipantSchedule } from 'types/dbSchema/participantSchedules';

export interface DeleteIconProps {
  participantSchedule: ParticipantSchedule;
}

const DeleteIcon: FC<DeleteIconProps> = ({ participantSchedule }) => {
  const navigate = useNavigate();
  const hasServicesAdded = true; // TODO: Replace with actual logic to check if services are added

  const handleDelete = () => {
    alert('Delete icon clicked!');
  };

  return (
    <StyledBox onClick={handleDelete}>
      {hasServicesAdded ? <EventBusyOutlinedIcon /> : <DeleteOutlineOutlinedIcon />}
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export default DeleteIcon;