import React from 'react';
import { Stack } from '@mui/material';
import { modalStateAtom } from 'atoms';
import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { ServiceRegion } from 'types/dbSchema/serviceRegions';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ServiceRegionForm from 'components/features/Forms/ServiceRegionForm';

// Renders Edit Icon for Grid column definitions - opens add/edit form in modal
const Edit = ({ serviceRegion }: { serviceRegion: ServiceRegion }) => {
  const [, setModalState] = useAtom(modalStateAtom);

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: ServiceRegionForm,
      props: {
        type: 'edit',
        serviceRegion,
      }
    }));
  }, [setModalState]);

  return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'pointer' }} onClick={handleModalOpen}>
      <EditOutlinedIcon sx={{ color: '#9CA3AF' }} />
    </Stack>
  );
};

export default Edit;