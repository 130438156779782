import React from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Stack } from "@mui/material";
import { modalStateAtom } from "atoms";
import { useAtom } from "jotai";
import { useCallback } from "react";
import { TimeSheetDeclinedReason } from 'types/dbSchema/serviceTeamSettings';
import TimeSheetDeclinedReasonsForm from 'components/features/Forms/TimeSheetDeclinedReasonForm';

const EditIcon = ({ timeSheetDeclinedReason }: { timeSheetDeclinedReason: TimeSheetDeclinedReason }) => {
  const [, setModalState] = useAtom(modalStateAtom);
  if (!timeSheetDeclinedReason || !timeSheetDeclinedReason.isEditable) return null;

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: TimeSheetDeclinedReasonsForm,
      props: {
        type: 'edit',
        timeSheetDeclinedReason,
      }
    }));
  }, [setModalState, timeSheetDeclinedReason]);

  return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'pointer' }} onClick={handleModalOpen}>
      <EditOutlinedIcon sx={{ color: '#9CA3AF' }} />
    </Stack>
  );
};

export default EditIcon;