import React, { forwardRef, useRef } from 'react';
import { Typography } from '@mui/material';
import variables from 'styles/variables';
import { DatePicker } from '@mui/x-date-pickers';
import { DatePickerInputProps } from './types';
import styled from 'styled-components';
import { format, isValid, parse } from 'date-fns';

const DatePickerInput = forwardRef<HTMLDivElement, DatePickerInputProps>(({
  id,
  label,
  error,
  errorText,
  helperText,
  onChange,
  value,
  isMandatory,
  clearable = true,
  ...props
}, ref) => {

  // This refers target the input field inside the DatePicker component
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (newValue: Date | null) => {
    if (newValue === null) {
      onChange('');

      // This is a fix for a MUI bug that focuses on the field but keystrokes are not registered after clearing the field
      // Solution: Focus on the field and then blur it, allowing the next time the user clicks on it, keystrokes are registered
      setTimeout(() => {
        inputRef.current?.focus();
        inputRef.current?.blur();
      }, 0);

      return;
    }

    if (newValue && isValid(newValue)) {
      onChange(format(newValue, 'yyyy-MM-dd'));
    } else {
      onChange('');
    }
  };

  return (
    <div style={{ margin: '0', position: 'relative', width: '100%' }}>
      {label && (
        <label htmlFor={id}>
          <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary} sx={{ marginBottom: '4px' }}>
            {label} {isMandatory && <span style={{ color: 'red' }}>*</span>}
          </Typography>
        </label>
      )}
      <StyledDatePicker
        ref={ref}
        format='dd/MM/yyyy'
        // This sets the form value to the correct date format, and clears the field if the value is null or invalid
        onChange={handleChange}
        // This allows the field to be cleared & disables the portal for the popper
        slotProps={{
          field: { clearable, inputRef },
          popper: { disablePortal: true },
        }}
        {...props}
      />
      {(error && errorText) ? <Typography variant='subtitle2' color='red' fontWeight='400'>{errorText}</Typography>
        : helperText && <Typography variant='subtitle2' color={variables.colors.text.secondary} fontWeight='400'>{helperText}</Typography>
      }
    </div>
  );
});

const StyledDatePicker = styled(DatePicker)`
  .MuiInputBase-root {
    height: 42px;
  }
  width: 100%;
  font-family: ${variables.fonts.family.primary};
  * {
    font-size: 14px;
  }
`;

DatePickerInput.displayName = 'DatePickerInput';

export default DatePickerInput;