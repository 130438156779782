import axiosInstance from 'api/config';
import { SelectedFilter } from 'components/common/FilterLookup/types';
import { UserRole, UserRoleCountList, UserRoleList } from 'types/dbSchema/userRoles';
import { createFilterString } from 'utils/helpers';

// Get a list of role templates for the given organisation ID
export const listUserRoles = async (organisationId: string, filters: SelectedFilter[] | null): Promise<UserRoleList> => {
  try {
    if (!organisationId) throw new Error('Organisation ID is required');
    // Create a formatted filter string
    let formattedFilters = '';
    if (filters && filters.length > 0) {
      formattedFilters = createFilterString(filters);
    }

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/user-roles`, {
      params: {
        filters: formattedFilters
      }
    });

    return response.data;

  } catch (error) {
    console.error('There was an error retrieving the user roles for the organisation', error);
    throw error;
  }
};

// TODO: Not used in MVP. When we have custom roles this will be used
// Creates a new User role for the given organisation ID
// Partial type because we don't need to send an ID
export const createUserRole = async (organisationId: string, data: Partial<UserRole>) => {
  try {
    if (!organisationId) throw new Error('Organisation ID is required');

    const response = await axiosInstance.post(`/api/organisations/${organisationId}/user-roles`,
      data
    );
    return response.data;
  } catch (error) {
    console.error('There was an error creating a new User role for the organisation', error);
    throw error;
  }
};

// TODO: Not used in MVP. When we have custom roles this will be used
// Updates User role for the given organisation ID and User role ID
export const updateUserRole = async (organisationId: string, data: UserRole) => {
  try {
    if (!organisationId) throw new Error('Organisation ID and UserRole ID are required');

    const response = await axiosInstance.put(`/api/organisations/${organisationId}/user-roles/${data.id}`,
      data
    );

    return response.data;
  } catch (error) {
    console.error('There was an error updating the User role for the organisation', error);
    throw error;
  }
};

// TODO: Not used in MVP. When we have custom roles this will be used
// Returns a User role by the ID params for the given organisation ID
export const getUserRoleById = async (organisationId: string, userRoleId: string): Promise<UserRole> => {
  try {
    if (!organisationId) throw new Error('Organisation ID and UserRole ID are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/user-roles/${userRoleId}`);

    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the User role for the organisation', error);
    throw error;
  }
};

// TODO: Not used in MVP. When we have custom roles this will be used
// Deletes a User role by the ID params for the given organisation ID
export const deleteUserRoleById = async (organisationId: string, userRoleId: string): Promise<UserRole> => {
  try {
    if (!organisationId) throw new Error('Organisation ID and UserRole ID are required');

    const response = await axiosInstance.delete(`/api/organisations/${organisationId}/user-roles/${userRoleId}`);

    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the User role for the organisation', error);
    throw error;
  }
};

// Get a list of user roles for the given organisation ID with user count
export const getUserRoleUserCount = async (organisationId: string): Promise<UserRoleCountList> => {
  try {
    if (!organisationId) throw new Error('Organisation ID is required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/user-roles/count`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the user role count for the organisation', error);
    throw error;
  }
};