import axiosInstance from 'api/config';
import axios from 'axios';
import { SelectedFilter } from 'components/common/FilterLookup/types';
import { SchedulingSupport, SchedulingSupportList } from 'types/dbSchema/schedulingSupports';
import { createFilterString } from 'utils/helpers';

// Returns a list of scheduling-support for the given organisation ID
export const listSchedulingSupports = async (
  organisationId: string,
  filters: SelectedFilter[] | null,
  page?: number,
  pageSize?: number
): Promise<SchedulingSupportList> => {
  try {
    if (!organisationId) throw new Error('Organisation ID, page, and page size are required');

    // Create a formatted filter string
    let formattedFilters = '';
    if (filters && filters.length > 0) {
      formattedFilters = createFilterString(filters);
    }

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/scheduling-supports`, {
      params: {
        filters: formattedFilters,
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing scheduling-support for the organisation', error);
    throw error;
  }
};

// Creates a new scheduling Support for the given organisation ID
// Partial type because we don't need to send an ID
export const createSchedulingSupport = async (organisationId: string, data: Partial<SchedulingSupport>) => {
  try {
    if (!organisationId) throw new Error('Organisation ID and SchedulingSupport ID are required');

    const response = await axiosInstance.post(`/api/organisations/${organisationId}/scheduling-supports`,
      data
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.log(error.response.data.message);
      // This will access the actual error response sent by the server
      console.error('There was an error creating a new scheduling Support for the organisation', error.response.data);
      return error.response.data;
    } else {
      // Handle other unexpected errors
      console.error('There was an error creating a new scheduling Support for the organisation', error);
      throw error;
    }
  }
};

// Updates scheduling Support for the given organisation ID and scheduling Support ID
export const updateSchedulingSupport = async (organisationId: string, data: SchedulingSupport) => {
  try {
    if (!organisationId) throw new Error('Organisation ID and SchedulingSupport ID are required');

    const response = await axiosInstance.put(`/api/organisations/${organisationId}/scheduling-supports/${data.id}`,
      data
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.log(error.response.data.message);
      // This will access the actual error response sent by the server
      console.error('There was an error creating a new scheduling Support for the organisation', error.response.data);
      return error.response.data;
    } else {
      // Handle other unexpected errors
      console.error('There was an error creating a new scheduling Support for the organisation', error);
      throw error;
    }
  }
};

// Returns a scheduling Support by the ID params for the given organisation ID
export const getSchedulingSupportById = async (organisationId: string, schedulingSupportID: string): Promise<SchedulingSupport> => {
  try {
    if (!organisationId) throw new Error('Organisation ID and SchedulingSupport ID are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/scheduling-supports/${schedulingSupportID}`);

    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the scheduling Support for the organisation', error);
    throw error;
  }
};

// Deletes a scheduling Support by the ID params for the given organisation ID
export const deleteSchedulingSupportById = async (organisationId: string, schedulingSupportID: string): Promise<SchedulingSupport> => {
  try {
    if (!organisationId) throw new Error('Organisation ID and SchedulingSupport ID are required');

    const response = await axiosInstance.delete(`/api/organisations/${organisationId}/scheduling-supports/${schedulingSupportID}`);

    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the scheduling Support for the organisation', error);
    throw error;
  }
};