import React, { FC, useCallback, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import styled from 'styled-components';
import DataTable from 'components/common/DataTable';
import SearchInput from 'components/common/InputFields/SearchInput';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { closeModalAtom } from 'atoms/modalAtom';
import { Controller, useForm } from 'react-hook-form';
import TextInput from 'components/common/InputFields/TextInput';
import variables from 'styles/variables';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import { ShiftCancellationReason } from 'types/dbSchema/serviceTeamSettings';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Data, PaginationModel } from 'components/common/DataTable/types';
import useUserOrganisations from 'hooks/useUserOrganisations';
import useInvalidateData from 'hooks/useInvalidateData';
import { createShiftCancellationReason, listShiftCancellationReasons, updateShiftCancellationReason } from 'api/organisations/settings/service-team/shift-cancellation-reasons';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import { paginationModelDefault } from 'utils/helpers';

export interface ShiftCancellationReasonsFormProps {
  type: 'create' | 'edit';
  shiftCancellationReason: ShiftCancellationReason;
}


const ShiftCancellationReasonsForm: FC<ShiftCancellationReasonsFormProps> = ({ type, shiftCancellationReason }) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const showError = useErrorMessage();
  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: 'onChange',
    defaultValues: shiftCancellationReason || {
      isActive: true,
    }
  });

  // Function to create a new shift cancellation reason.
  // Invalidates the query to fetch the latest data, and closes the modal
  const handleCreate = async (data: ShiftCancellationReason) => {
    try {
      await createShiftCancellationReason(organisations[0].organisation.globalId, data);
      setInvalidateData((prev) => ({ ...prev, 'shift-cancellation-reason': true }));
      closeModal();
    } catch (error) {
      showError((error as Error).message);
    }
  };

  // Function to update a shift cancellation reason.
  // Invalidates the query to fetch the latest data, and closes the modal
  const handleEdit = async (data: ShiftCancellationReason) => {
    try {
      await updateShiftCancellationReason(organisations[0].organisation.globalId, data);
      setInvalidateData((prev) => ({ ...prev, 'shift-cancellation-reason': true }));
      closeModal();
    } catch (error) {
      showError((error as Error).message);
    }
  };

  // Function to handle form submission
  const handleSubmitForm = async (data: ShiftCancellationReason) => {
    if (type === 'create') {
      await handleCreate(data);
    }
    else {
      await handleEdit(data);
    }
  };

  return (
    <form style={{ width: '500px' }} onSubmit={handleSubmit(handleSubmitForm)}>
      <Stack height='fit-content' padding='32px' gap='24px'>
        <Typography variant='h6' fontWeight='600'>
          {type === 'create' ? 'Add Shift Cancellation Reason' : 'Edit Shift Cancellation Reason'}
        </Typography>
        <Controller
          name="name"
          control={control}
          rules={{
            maxLength: { value: 40, message: 'Max length of 40 characters' },
            required: 'Shift Cancellation Reason is required',
            pattern: {
              value: /^[a-zA-Z0-9!@#$%^&*()_+-=[\]{};':"|,.<>/? ]*$/,
              message: 'Shift Cancellation must be alphanumeric'
            },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='name'
              label='Name'
              placeholder='Enter Shift Cancellation Reason'
              error={errors.name ? true : false}
              errorText={errors?.name?.message}
              isMandatory
            />
          }
        />
        <Stack flexDirection='row' justifyContent='flex-start' alignItems='center' gap='16px'>
          <Typography variant='subtitle2' fontWeight='500'>
            Status
          </Typography>
          <Controller
            name="isActive"
            control={control}
            rules={{
              validate: value => value !== undefined && value !== null || 'Status is required'
            }}
            render={({ field }) =>
              <IsActiveDropdown
                isActive={(field.value === undefined || field.value === null) ? true : field.value}
                setIsActive={field.onChange}
              />
            }
          />
        </Stack>
      </Stack>
      <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' maxWidth='960px' padding='16px 32px' boxSizing='border-box' sx={{ backgroundColor: variables.colors.lightNeutral.subtle, borderRadius: '0 0 12px 12px' }} >
        <Button variant='outlined' color='primary' onClick={closeModal}>
          Cancel
        </Button>
        <Button type='submit' variant='contained' color='primary'>
          {type === 'create' ? 'Create' : 'Update'}
        </Button>
      </Stack>
    </form>
  );
};

export default ShiftCancellationReasonsForm;