import React from 'react';
import { updateServiceRegion } from 'api/organisations/serviceregions';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import useErrorMessage from 'hooks/useErrorMessage';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useAtom } from 'jotai';
import { ServiceRegion } from 'types/dbSchema/serviceRegions';

const EditStatusDropdown = ({ serviceRegion }: { serviceRegion: ServiceRegion }) => {
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const showError = useErrorMessage();

  const handleEdit = async (status: boolean) => {
    try {
      await updateServiceRegion(organisations[0].organisation.globalId, { ...serviceRegion, isActive: status });
      setInvalidateData((prev) => ({ ...prev, 'service-regions': true }));
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <IsActiveDropdown
      isActive={serviceRegion.isActive}
      setIsActive={handleEdit}
    />
  );
};
export default EditStatusDropdown;