import React, { FC } from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

const Actions: FC = () => {
  const navigate = useNavigate();
  const handleClick = () => navigate('/scheduling/participant-schedules/create');

  return (
    <Button
      color='primary'
      variant='contained'
      onClick={handleClick}
      startIcon={<AddOutlinedIcon />}
      sx={{ minWidth: 'fit-content', height: '100%' }}
    >
      Add
    </Button>
  );
};

export default Actions;