import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button } from '@mui/material';
import styled from 'styled-components';
import DataTable from 'components/common/DataTable';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { LeaveType } from 'types/dbSchema/availabilitySettings';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { PaginationModel } from 'components/common/DataTable/types';
import useInvalidateData from 'hooks/useInvalidateData';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { dummyLeaveTypes } from 'utils/helpers/getDBData';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import { paginationModelDefault } from 'utils/helpers';
import { Columns } from './Columns';
import LeaveTypeForm from 'components/features/Forms/LeaveTypeForm';

const LeaveTypesDataTable = () => {
  const [, setModalState] = useAtom(modalStateAtom);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>(paginationModelDefault);
  const [organisations] = useUserOrganisations();
  useInvalidateData('leave-type');

  const { isError, data, isPending, isFetching, isLoading } = useQuery({
    queryKey: ['leave-type', paginationModel],
    // queryFn: () => listLeaveTypes(organisations[0].organisation.globalId, paginationModel.page + 1, paginationModel.pageSize),
    queryFn: () => dummyLeaveTypes,
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.leaveTypes,
  });

  // Filter out the colors that are already in use
  // const filteredColorList = useMemo(() => data ? (data?.items as LeaveType[]).map(row => row.color) : [], [data]);
  const filteredColorList = useMemo(() => data ? (data as LeaveType[]).map(row => row.color) : [], [data]);

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: LeaveTypeForm,
      props: {
        filterOut: filteredColorList,
        type: 'create',
      }
    }));
  }, [setModalState, filteredColorList]);

  return (
    <StyledBox>
      <SearchBox>
        <Button variant='contained' sx={{ padding: '8px 16px' }} onClick={handleModalOpen}>
          <AddOutlinedIcon sx={{ marginRight: '8px' }} />
          Add
        </Button>
      </SearchBox>
      <Box height='calc(100vh - 295px)'>
        <DataTable
          // data={data as Data}
          data={{ items: dummyLeaveTypes, totalCount: 1, pageNumber: paginationModel.page, pageSize: paginationModel.pageSize }}
          columns={Columns(filteredColorList)}
          checkboxSelection={false}
          dataTableName='leave-type'
          isPending={isFetching || isPending || isLoading}
          isError={isError}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          onNoDataTitle='No Leave Types found'
          paginationMode='server'
          onNoData={handleModalOpen}
        />
      </Box>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  height: 100%;
  width: 100%;
  background-color: white;
`;

const SearchBox = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 24px;
  gap: 16px;
`;

export default LeaveTypesDataTable;