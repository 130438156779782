import React, { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { closeModalAtom } from 'atoms/modalAtom';
import { Controller, useForm } from 'react-hook-form';
import TextInput from 'components/common/InputFields/TextInput';
import variables from 'styles/variables';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import { ShiftLocation } from 'types/dbSchema/schedulingSettings';
import AddressLookupField from 'components/common/InputFields/AddressLookupField';
import { Address } from 'types';
import { createShiftLocation, updateShiftLocation } from 'api/organisations/settings/scheduling/shift-locations';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';

export interface ShiftLocationFormProps {
  type: 'create' | 'edit';
  shiftLocation: ShiftLocation;
}

const ShiftLocationForm: FC<ShiftLocationFormProps> = ({ type, shiftLocation }) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const showError = useErrorMessage();
  const { control, handleSubmit, formState: { errors, isDirty }, setValue, resetField } = useForm({
    mode: 'onChange',
    defaultValues: shiftLocation || {
      isActive: true
    }
  });

  // Function to create a new Shift Location
  // Invalidates the query to fetch the latest data, and closes the modal
  const handleCreate = async (data: ShiftLocation) => {
    try {
      await createShiftLocation(organisations[0].organisation.globalId, data);
      setInvalidateData((prev) => ({ ...prev, 'shift-locations': true }));
      closeModal();
    } catch (error) {
      showError((error as Error).message);
    }
  };

  // Function to update a Shift Location.
  // Invalidates the query to fetch the latest data, and closes the modal
  const handleEdit = async (data: ShiftLocation) => {
    try {
      await updateShiftLocation(organisations[0].organisation.globalId, data);
      setInvalidateData((prev) => ({ ...prev, 'shift-locations': true }));
      closeModal();
    } catch (error) {
      showError((error as Error).message);
    }
  };

  // Function to handle form submission
  const handleSubmitForm = async (data: ShiftLocation) => {
    if (type === 'create') {
      await handleCreate(data);
    }
    else {
      await handleEdit(data);
    }
  };

  const handleSetAddress = (values: Address) => {
    setValue('address', values, { shouldDirty: true, shouldValidate: true });
  };

  return (
    <form style={{ width: '500px' }} onSubmit={handleSubmit(handleSubmitForm)}>
      <Stack height='fit-content' padding='32px' gap='24px'>
        <Typography variant='h6' fontWeight='600'>
          {type === 'create' ? 'Add Shift Location' : 'Edit Shift Location'}
        </Typography>
        <Controller
          name="name"
          control={control}
          rules={{
            required: 'Name is required',
            maxLength: { value: 40, message: 'Name should not exceed 40 characters' },
            pattern: {
              value: /^[a-zA-Z0-9!@#$%^&*()_+-=[\]{};':"|,.<>/? ]*$/,
              message: 'Name must be alphanumeric'
            },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='name'
              label='Name'
              placeholder='Enter Shift Location Name'
              error={errors.name ? true : false}
              errorText={errors?.name?.message}
              isMandatory
            />
          }
        />
        <Controller
          name="address"
          control={control}
          rules={{ required: 'Dddress is required' }}
          render={({ field }) =>
            <AddressLookupField
              {...field}
              id='address'
              placeholder='Search for your address'
              isMandatory
              setValue={handleSetAddress}
              label='Address'
              error={errors.address ? true : false}
              errorText='Address is required'
              resetField={() => resetField('address')}
            />
          }
        />
        <Stack flexDirection='row' justifyContent='flex-start' alignItems='center' gap='16px'>
          <Typography variant='subtitle2' fontWeight='500'>
            Status
          </Typography>
          <Controller
            name="isActive"
            control={control}
            rules={{
              validate: value => value !== undefined && value !== null || 'Status is required'
            }}
            render={({ field }) =>
              <IsActiveDropdown
                isActive={(field.value === undefined || field.value === null) ? true : field.value}
                setIsActive={field.onChange}
              />
            }
          />
        </Stack>
      </Stack>
      <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' maxWidth='960px' padding='16px 32px' boxSizing='border-box' sx={{ backgroundColor: variables.colors.lightNeutral.subtle, borderRadius: '0 0 12px 12px' }} >
        <Button variant='outlined' color='primary' onClick={closeModal}>
          Cancel
        </Button>
        <Button type='submit' variant='contained' color='primary' disabled={!isDirty}>
          {type === 'create' ? 'Create' : 'Update'}
        </Button>
      </Stack>
    </form>
  );
};

export default ShiftLocationForm;