import React from 'react';
import { useAtom } from 'jotai';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import { Tag } from 'types/dbSchema/serviceTeamSettings';
import { updateTag } from 'api/organisations/settings/service-team/tags';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';

const EditStatusDropdown = ({ tag }: { tag: Tag }) => {
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const showError = useErrorMessage();

  const handleEdit = async (status: boolean) => {
    try {
      await updateTag(organisations[0].organisation.globalId, { ...tag, isActive: status });
      setInvalidateData((prev) => ({ ...prev, 'service-team-settings-tags': true }));
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <IsActiveDropdown
      isActive={tag.isActive}
      setIsActive={handleEdit}
      disabled={!tag.isEditable}
    />
  );
};

export default EditStatusDropdown;