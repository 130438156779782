import { LinearProgress } from '@mui/material';
import ViewBatchFile from 'components/features/Views/ViewBatchFile';
import ViewNDISClaimFile from 'components/features/Views/ViewNDISClaimFile';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import useUserOrganisations from 'hooks/useUserOrganisations';
import React from 'react';
import { useParams } from 'react-router-dom';
import { dummyNDISClaims } from 'utils/helpers/getDBData';

const ViewNDISClaimPage = () => {
  // TODO: implement the below with the correct API call
  // const { id } = useParams();
  // const [organisations] = useUserOrganisations();
  // const [organisationState] = useAtom(organisationStateAtom);

  //  // If these aren't loaded, we'll skip the fetch until they are
  // const canFetch = organisationState.hasBeenFetched && organisations.length > 0;

  // const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({
  //   getByIDFunc: () => getUserById(organisations[0].organisation.globalId, id as string),
  //   skip: !canFetch,
  // });

  // if (isLoading) return <LinearProgress />;
  // if (hasError || !currentDataPoint) return null;

  // return <ViewBatchFile invoiceBatchFile={currentDataPoint} />;
  return <ViewNDISClaimFile ndisClaimFile={dummyNDISClaims[0]} />;
};

export default ViewNDISClaimPage;